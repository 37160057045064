.request-history-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.loan-request-container {
    padding: 16px;
    align-items: center;
    background: white;
    border-top: 1px solid #ccd6dd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
}

.loan-request-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px 16px 16px;
    gap: 12px;
    width: 100%;
    color: #f6f9fc;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &.borrower {
        background: rgba(var(--borrowerPrimaryColor), 1);
    }
    &.cosigner {
        background: rgba(var(--cosignerPrimaryColor), 1);
    }
}

.flex-gap-8 {
    display: flex;
    gap: 8px;
    align-items: center;
}


.tooltip-content {
    position: absolute;
    right: 12px;
    top: 0px;
    width: 400px;
    border-radius: 12px;
    background: white;
    padding: 16px;
    z-index: 1;
    display: none;
    transition: all 1000ms;
    border: 0.4px solid #ccd6dd;

    &::before {
        width: 20px;
        height: 20px;
        background: #fff;
        content: "";
        position: absolute;
        bottom: -7px;
        background: url(../../../../assets/images/tooltip-arrow.svg) center center no-repeat;
        background-size: 100% 100%;
        right: 7px;
    }
    
    &.active {
        display: block;
        top: -80px;

        @media (max-width: 400px) {
            width: 200px;
            top: -140px;
        }
    }
    
}

@mixin tooltipDate {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background: #FFFFFF;
    border-radius: 24px;
    justify-content: center;
}



.date {
    
    &.expired{
        color: #D90D14;
       @include tooltipDate;
    }


}

.overflow-allowed {
    overflow: visible !important;
}