.flex-container-0 {
    display: flex;
    justify-content: space-between;
}

.flex-container-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-col-0 {
    display: flex;
    flex-direction: column;
}

@mixin flex-col  {
        display: flex;
        flex-direction: column;
}


@mixin flex-col-reverse  {
        display: flex;
        flex-direction: column-reverse;
}

.flex-col-990 {
    @media (max-width: 990px) {
        @include flex-col;
    }
}

.flex-col-reverse-990 {
    @media (max-width: 990px) {
        @include flex-col-reverse;
    }
}