.searchWrapper {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 28px; /* 28px */
    background: rgba(var(--borrowerPrimaryColor), 1);

    &.hoverable {
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
            background: rgba(var(--borrowerPrimaryColor), 0.7);
        }
    }
    @media (max-width: 1440px) {
        border-radius: 28px;
        padding: 16px;
       }
}

.searchTxt {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    @media (max-width: 1440px) {
        display: none;
       }

       &.alwaysShowTxt{
        display: block;
       }
}

.loanAmountBtn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    padding: 8px 8px 8px 32px;
    align-items: center;
    border-radius: 36px;
    background: #fff;
    box-shadow: 0px 24px 40px 0px rgba(21, 26, 37, 0.1);
    margin-bottom: 32px;

    @media (max-width: 1440px) {
        gap: 8px;
        padding-left: 30px;
       }
}

.howMuchTxt {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    opacity: 0.5;

    @media (max-width: 1440px) {
        font-size: 14px;
    }
}

.searchModalWrapper {
    padding: 24px 32px;
    background: var(--whiteColor);
    position: absolute;
    top: 0px;
    width: 100%;
    box-shadow: 0px 24px 40px 0px rgba(21, 26, 37, 0.1);
    border-radius: 36px;
}

.closeIconPos {
    position: absolute;
    top: 24px;
    right: 32px;
}

.howMuchModalTxt {
    font-size: 14px;
    line-height: 18px;
    color: var(--neutralColor500);
    margin-bottom: 24px;
}

.valueContainer {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    padding: 16px;
    background: #f2f4f5;
    border-radius: 16px;
    border: none;
    transition: all 0.4s;
    width: 100%;
    text-align: center;
}
