p {
    margin: 0px;
}

.dc-container {
    padding: 24px;
    background: #ffefc6;
    border: 2px solid #f19048;
    border-radius: 16px;

    @media (max-width: 767px) {
        padding: 16px;
    }

    .title-section {
        display: flex;
        align-items: center;
        gap: 12px;
        @media (max-width: 767px) {
            align-items: flex-start;
        }
        .circle-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;
            min-height: 24px;
            min-width: 24px;
            background: #f19048;
            border-radius: 50%;

            p {
                font-size: 14px;
                font-weight: 600px;
                color: white;
                line-height: 20px;
            }
        }

        p {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }
    }
    .accordion {
        margin-top: 24px;
        .accordion-item {
            margin-top: 16px;
            background: #ffefc6;
            border-bottom: 1px solid rgba(21, 27, 38, 0.1);
            padding-bottom: 16px;

            .accordion-button {
                display: flex;
                align-items: flex-start;
                background: #ffefc6;
                font-size: 16px;
                color: black;
                text-align: left;
                font-weight: 700;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
                cursor: pointer;
                border: none;
                padding: 0;

                p {
                    font-size: 18px;
                    font-weight: 500px;
                    line-height: 24px;

                    @media (max-width: 767px) {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                .arrow-icon {
                    width: 20px;
                    height: 20px;
                    fill: #fff;
                    transition: transform ease 0.3s;

                    @media (max-width: 767px) {
                        width: 16px;
                        height: 16px;
                        margin-top: 5px;
                    }

                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }

            .control {
                font-size: 20px;
            }

            /* activate toggle */
            .accordion_item.active .button {
                background-color: #105057;
            }

            .answer_wrapper {
                max-height: 0;
                overflow: hidden;

                &.active {
                    padding-top: 8px;

                    max-height: 500px;
                    transition: max-height 5s ease-out;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }

    .accordion-item:first-child {
        margin-top: 0px;
    }
}
