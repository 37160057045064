.section-has-custom-bg {
    position: relative;

    .section-inner {
        position: relative;
        z-index: 2;
        padding: 80px 0 160px 0;

        &.graduate {
            padding: 80px 0 100px 0;

            @media (max-width: 767px) {
                padding: 80px 0 40px;
            }
        }

        @media (max-width: 990px) {
            padding: 100px 0;
        }
        @media (max-width: 767px) {
            padding: 80px 0;
        }
    }
}

.section {
    &.posts-section-custom {
        overflow: hidden;
        .section-inner {
            position: relative;
            padding: 160px 0;
            @media (max-width: 990px) {
                padding: 100px 0;
            }
            @media (max-width: 767px) {
                padding: 80px 0;
            }
            .container {
                position: relative;
            }
        }
    }
}

.section {
    &.section-white-bg {
        background: var(--whiteColor);
    }
}

.blog-wrapper {
    background: var(--neutralColor100);
    overflow: hidden;
}

.wrapper {
    background: var(--whiteColor);
    min-height: 100%;
    overflow: hidden;
    position: relative;
    &.no-overflow {
        overflow: unset;
    }
    &-blog-single {
        overflow: visible;

        .single-content__block {
            ul {
                li {
                    list-style: disc;
                    margin-left: 20px;
                }
            }

            .rate-table-lenders {
                position: relative;
                z-index: 2;
                padding: 8px 24px 24px 24px;
                margin: 24px 0;
                background: #edf2f6;
                border-radius: 15px;

                @media (max-width: 768px) {
                    padding-top: 56px;
                }

                &__header {
                    &-title {
                        margin-bottom: 8px;

                        @media (max-width: 767px) {
                            h3 {
                                font-size: 24px !important;
                                line-height: 32px !important;
                            }
                        }
                    }

                    &-subtitle {
                        p {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            color: var(--neutralColor500);

                            span {
                                font-weight: 700;
                            }
                        }
                    }
                }

                &__title {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    margin-top: 40px;

                    .title {
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 600;
                        color: var(--primaryColor2);
                    }

                    .subtitle {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        color: var(--neutralColor500);
                    }
                }

                &__info-block {
                    display: flex;
                    padding: 16px 24px 16px 26px;
                    border-radius: 28px;
                    background: linear-gradient(254.6deg, #8cce56 0%, #57a051 100%);
                    margin-top: 16px;
                    justify-content: space-between;
                    align-items: center;
                    gap: 24px;

                    @media (max-width: 767px) {
                        flex-direction: column;
                        gap: 16px;
                        padding: 16px;
                        border-radius: 16px;
                    }

                    &_left {
                        display: flex;
                        align-items: center;
                        gap: 18px;

                        .block-logo {
                            width: 28px;
                            height: 22px;
                            min-width: 28px;
                            min-height: 22px;
                            background-image: url("../../assets/images/icons/logo.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                        }

                        @media (max-width: 767px) {
                            align-items: flex-start;

                            .block-logo {
                                margin-top: 8px;
                            }
                        }

                        &-text {
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--neutralColor100);

                            @media (max-width: 767px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }

                    &_button {
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 12px;
                        background-color: var(--whiteColor);
                        border-radius: 28px;
                        color: var(--greenColor);

                        p {
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 500;
                            color: var(--greenColor);
                            white-space: nowrap;
                        }

                        span {
                            font-size: 12px;
                        }

                        @media (max-width: 767px) {
                            width: 100%;
                        }

                        @media (min-width: 767px) {
                            width: 168px;
                            padding: 0 24px;
                        }
                    }
                }

                &__table {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    margin-top: 24px;

                    &-row {
                        width: 100%;
                        display: flex;
                        padding: 16px 34px 16px 24px;
                        justify-content: space-between;
                        background: var(--whiteColor);
                        border-radius: 16px;

                        @media (min-width: 767px) and (max-width: 1272px) {
                            display: grid;
                            grid-template-areas:
                                "logo logo button"
                                "description1 description2 description3";
                            row-gap: 16px;
                        }

                        @media (max-width: 767px) {
                            flex-direction: column;
                            gap: 20px;
                        }

                        &_logo {
                            grid-area: logo;
                            display: flex;
                            gap: 12px;
                            align-items: center;
                            max-width: 198px;
                            width: 100%;

                            img {
                                width: 30px;
                                height: auto;
                                max-width: 30px;
                                max-height: 30px;
                                object-fit: cover;
                            }

                            p {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 500;
                                color: var(--primaryColor2);
                            }
                        }

                        &_item {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            max-width: 226px;
                            width: 100%;

                            &:nth-child(3) {
                                @media (max-width: 1273px) and (min-width: 768px) {
                                    .rate-table-lenders__table-row_item-tooltip {
                                        .tooltip__wrapper {
                                            &:before {
                                                left: 57% !important;
                                            }
                                        }
                                    }
                                }

                                @media (max-width: 767px) {
                                    .rate-table-lenders__table-row_item-tooltip {
                                        left: 104px !important;

                                        .tooltip__wrapper {
                                            &:before {
                                                left: 44% !important;
                                            }
                                        }
                                    }
                                }
                            }

                            &:nth-child(4) {
                                @media (max-width: 1273px) and (min-width: 1270px) {
                                    .rate-table-lenders__table-row_item-tooltip {
                                        left: -28% !important;

                                        .tooltip__wrapper {
                                            &:before {
                                                left: 76% !important;
                                            }
                                        }
                                    }
                                }

                                @media (max-width: 1271px) and (min-width: 1208px) {
                                    .rate-table-lenders__table-row_item-tooltip {
                                        left: 58% !important;

                                        .tooltip__wrapper {
                                            &:before {
                                                left: 56% !important;
                                            }
                                        }
                                    }
                                }

                                @media (max-width: 1207px) and (min-width: 768px) {
                                    .rate-table-lenders__table-row_item-tooltip {
                                        left: -50% !important;

                                        .tooltip__wrapper {
                                            &:before {
                                                left: 81% !important;
                                            }
                                        }
                                    }
                                }

                                @media (max-width: 767px) {
                                    .rate-table-lenders__table-row_item-tooltip {
                                        left: 104px !important;

                                        .tooltip__wrapper {
                                            &:before {
                                                left: 45% !important;
                                            }
                                        }
                                    }
                                }

                                .tooltip__wrapper {
                                    &:before {
                                        margin-left: 3px !important;
                                    }
                                }
                            }

                            @media (max-width: 767px) {
                                flex-direction: row;
                                max-width: 100%;
                                gap: 8px;
                                justify-content: space-between;
                            }

                            &.th1 {
                                grid-area: description1;
                            }

                            &.th2 {
                                grid-area: description2;
                            }

                            &.th3 {
                                grid-area: description3;
                            }

                            &-separate {
                                @media (min-width: 768px) {
                                    display: none;
                                }
                                @media (max-width: 767px) {
                                    display: block;
                                    width: 100%;
                                    border-bottom: 1px dashed var(--neutralColor200);
                                }
                            }

                            &-title {
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                position: relative;

                                p {
                                    font-size: 14px;
                                    line-height: 20px;
                                    font-weight: 400;
                                    color: var(--neutralColor500);

                                    @media (max-width: 767px) {
                                        white-space: nowrap;
                                    }
                                }

                                .description-icon {
                                    width: 12px;
                                    height: 12px;
                                    border-radius: 50%;
                                    background-image: url("../../assets/images/icons/description.svg");
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                    color: transparent;
                                    cursor: pointer;

                                    &:hover + .rate-table-lenders__table-row_item-tooltip {
                                        display: block;
                                    }
                                }

                                .rate-table-lenders__table-row_item-tooltip {
                                    border-radius: 12px;
                                    min-width: 446px;
                                    max-height: calc(300px + 22px);
                                    height: auto;
                                    width: 100%;
                                    opacity: 1;
                                    z-index: 20;
                                    position: absolute;
                                    display: none;
                                    bottom: 15px;
                                    left: 50%;
                                    transform: translate(-50%, 0%);

                                    &:hover {
                                        display: block;
                                    }

                                    @media (max-width: 767px) {
                                        width: 300px;
                                        min-width: 300px;
                                    }

                                    .tooltip__wrapper {
                                        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
                                        background: var(--whiteColor);
                                        padding: 5px 16px 5px;
                                        border-radius: 12px;
                                        position: relative;
                                        bottom: 15px;

                                        &:before {
                                            content: "";
                                            position: absolute;
                                            width: 12px;
                                            height: 12px;
                                            background: var(--whiteColor);
                                            transform: rotate(45deg);
                                            bottom: -6px;
                                            left: 50%;
                                            margin-left: -13px;
                                        }

                                        & > div,
                                        p,
                                        span {
                                            font-size: 12px;
                                            line-height: 16px;
                                            font-weight: 400;
                                            color: var(--primaryColor2);
                                            white-space: initial !important;
                                        }

                                        .text-description__wrapper {
                                            max-height: 262px;
                                            height: 100%;
                                            width: 100%;
                                            overflow-y: auto;
                                            margin: 16px 0;

                                            ::-webkit-scrollbar {
                                                width: 12px;
                                            }

                                            ::-webkit-scrollbar-track {
                                                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                                border-radius: 10px;
                                            }

                                            ::-webkit-scrollbar-thumb {
                                                border-radius: 10px;
                                                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                                            }

                                            .text-description {
                                                h3 {
                                                    font-size: 16px;
                                                }

                                                a {
                                                    text-decoration: none;
                                                    transition: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &-value {
                                font-size: 18px;
                                line-height: 24px;
                                font-weight: 500;
                                color: var(--primaryColor2);

                                @media (max-width: 767px) {
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 24px;
                                    white-space: nowrap;
                                }
                            }
                        }

                        a {
                            grid-area: button;
                            display: flex;
                            gap: 12px;
                            align-items: center;
                            color: var(--greenColor);

                            &.peach {
                                color: var(--peachColor);

                                p {
                                    color: var(--peachColor);
                                }
                            }

                            span {
                                font-size: 12px;
                            }

                            p {
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 500;
                                color: var(--greenColor);
                            }
                        }
                    }
                }
            }
        }
    }
    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    h2,
    .h2 {
        font-size: 48px;
        line-height: 56px;

        @media (max-width: 990px) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    h3,
    .h3 {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
    }

    h4,
    .h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        color: var(--primaryColor);
    }
    .justify-end {
        justify-content: flex-end;
    }

    .container {
        //.container {

        //  @media (max-width: 990px) {
        //    max-width: calc(100% - 48px);
        //  }
        //}
        @media (max-width: 1270px) {
            max-width: 990px;
        }
        @media (max-width: 990px) {
            max-width: calc(100% - 18px);
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (max-width: 767px) {
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
        }
    }

    .row {
        @media (max-width: 990px) {
            //margin-left: -12px;
            //margin-right: -12px;
        }
    }

    .section-bg-block {
        position: absolute;
        left: 5px;
        top: 50%;
        width: calc(100% + 40px);
        height: 93%;
        border-radius: 0 80px 0 80px;
        transform: rotate(-3deg) translateY(-50%);
        background: #edf2f6;

        &-dark {
            background: rgba(var(--borrowerPrimaryColor), 1);
        }

        &-white {
            background: var(--whiteColor);
        }

        @media (max-width: 990px) {
            height: 96%;
        }

        @media (max-width: 767px) {
            border-radius: 0 60px 0 60px;
        }
    }

    .swiper-wrapper {
        .swiper-slide {
            &:nth-child(1) {
                .category-label {
                    &.peach {
                        color: var(--peachBlackColor);
                        .bg {
                            background: var(--peachColorBG);
                            opacity: 1;
                        }
                    }

                    color: var(--greenSecondaryColor);
                    .bg {
                        background: rgba(34, 172, 131, 1);
                        opacity: 0.1;
                    }
                }
            }

            &:nth-child(2) {
                .category-label {
                    &.peach {
                        color: var(--peachBlackColor);
                        .bg {
                            background: var(--peachColorBG);
                            opacity: 1;
                        }
                    }

                    color: var(--secondaryGreen);
                    .bg {
                        background: rgba(24, 81, 85, 1);
                        opacity: 0.1;
                    }
                }
            }

            &:nth-child(3) {
                .category-label {
                    &.peach {
                        color: var(--peachBlackColor);
                        .bg {
                            background: var(--peachColorBG);
                            opacity: 1;
                        }
                    }

                    color: var(--greenColor01);
                    .bg {
                        background: var(--greenColor01);
                        opacity: 0.1;
                    }
                }
            }

            &:nth-child(4) {
                .category-label {
                    &.peach {
                        color: var(--peachBlackColor);
                        .bg {
                            background: var(--peachColorBG);
                            opacity: 1;
                        }
                    }

                    color: var(--greenSecondary01Color);
                    .bg {
                        background: var(--greenSecondary01Color);
                        opacity: 0.1;
                    }
                }
            }
        }
    }

    .category-label {
        padding: 8px 12px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 6px;
        color: var(--greenSecondaryColor);
        display: inline-flex;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        transition: color 0.3s;

        .bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: var(--greenSecondaryColor);
            opacity: 0.1;
            transition: all 0.3s;
        }

        &:hover {
            .bg {
                opacity: 0.4;
            }
        }

        span {
            position: relative;
            z-index: 1;
            white-space: nowrap;
        }

        &.active {
            color: var(--whiteColor) !important;

            .bg {
                background: var(--greenColor) !important;
                opacity: 1;
            }
        }
    }

    &--auth-flow {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;

        .custom-bg {
            top: -525px;
            z-index: 0;
            height: 900px;
        }
        .container {
            position: relative;
            z-index: 2;
        }
        .header-wrapper {
            position: static;
        }
        .footer-sparrow-copyrights {
            padding: 40px 0;
            font-size: 14px;
            color: #9ea9b0;
        }
    }
}

#gradient-canvas {
    width: 105%;
    height: 105%;
    transform: translate(-2.5%, -2.5%);
    --gradient-color-1: #dce87b;
    --gradient-color-2: #89d074;
    //--gradient-color-2: #151A25;
    //--gradient-color-3: #D7E57A;
    --gradient-color-3: #38875f;
    --gradient-color-4: #3fae6d;
    z-index: 0;
    transition: opacity 1s ease-in;
    opacity: 0;
    //filter: blur(26px);
}

#gradient-canvas-dark {
    width: 105%;
    height: 105%;
    --gradient-color-1: #151a25;
    --gradient-color-2: #d7e57a;
    --gradient-color-3: #38875f;
    --gradient-color-4: #3fae6d;
    z-index: 0;
    transform: translate(-2.5%, -2.5%);
    //filter: blur(26px);
}

#gradient-canvas-blue {
    width: 105%;
    height: 105%;
    --gradient-color-1: #405cab;
    --gradient-color-2: #567edd;
    --gradient-color-3: #7ac5e7;
    --gradient-color-4: #98f8ee;
    transform: translate(-2.5%, -2.5%);
    z-index: 0;
    transition: opacity 1s ease-in;
    opacity: 0;
    filter: blur(26px);
}

#gradient-canvas-peach {
    width: 105%;
    height: 105%;
    --gradient-color-1: #a0584a;
    --gradient-color-2: #dc7962;
    --gradient-color-3: #eebcb0;
    --gradient-color-4: #f9e1d5;
    transform: translate(-2.5%, -2.5%);
    z-index: 0;
    transition: opacity 1s ease-in;
    opacity: 0;
    filter: blur(26px);
}

.section-hero {
    position: relative;
    padding: 196px 0 40px 0;
    z-index: 2;

    &--index {
        padding-bottom: 100px;

        @media (max-width: 768px) {
            padding-bottom: 50px;
        }
    }
    @media (max-width: 1200px) {
        padding-top: 156px;
    }

    .h1 {
        position: relative;

        //mix-blend-mode: difference;
        @media (max-width: 1200px) {
            font-size: 48px;
            line-height: 56px;
            margin-bottom: 24px;

            br {
                display: none;
            }
        }

        @media (max-width: 990px) {
            max-width: 380px;
        }

        @media (max-width: 767px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 32px;
        }
    }

    p {
        @media (max-width: 990px) {
            max-width: 380px;
        }

        //mix-blend-mode: difference;
    }

    .hero-bg {
        position: absolute;
        left: 0;
        top: -140px;
        width: 110%;
        height: 670px;
        z-index: 0;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        overflow: hidden;
        border-radius: 0 0 0 80px;
        transform: rotate(-3deg);

        &.hero-home-bg {
            background-color: hsla(144, 46%, 46%, 1);
            background-image: radial-gradient(
                    at 19% 34%,
                    hsla(66, 70%, 69%, 1) 0px,
                    transparent 50%
                ),
                radial-gradient(at 12% 84%, hsla(149, 41%, 37%, 1) 0px, transparent 50%),
                radial-gradient(at 71% 77%, hsla(106, 49%, 63%, 1) 0px, transparent 50%),
                radial-gradient(at 51% 42%, hsla(144, 46%, 46%, 1) 0px, transparent 50%);
        }

        &.hero-crest-bg {
            background-color: hsla(222, 66%, 60%, 1);
            background-image: radial-gradient(
                    at 27% 18%,
                    hsla(224, 45%, 46%, 1) 0px,
                    transparent 50%
                ),
                radial-gradient(at 74% 77%, hsla(222, 66%, 60%, 1) 0px, transparent 50%),
                radial-gradient(at 13% 59%, hsla(198, 69%, 69%, 1) 0px, transparent 50%),
                radial-gradient(at 91% 35%, hsla(173, 87%, 78%, 1) 0px, transparent 50%);
        }

        &.hero-peach-bg {
            background-color: hsla(11, 64%, 62%, 1);
            background-image: radial-gradient(
                    at 27% 18%,
                    hsla(10, 37%, 46%, 1) 0px,
                    transparent 50%
                ),
                radial-gradient(at 74% 77%, hsla(11, 64%, 62%, 1) 0px, transparent 50%),
                radial-gradient(at 13% 59%, hsla(12, 65%, 81%, 1) 0px, transparent 50%),
                radial-gradient(at 91% 35%, hsla(20, 75%, 91%, 1) 0px, transparent 50%);
        }

        @media (max-width: 1200px) {
            top: -100px;
            width: 110%;
            height: 570px;
        }

        @media (max-width: 990px) {
            height: 640px;
            left: -6px;
            transform: rotate(-2deg);
        }

        @media (max-width: 767px) {
            transform: rotate(0deg);
            width: 105%;
            left: -2.5%;
            top: -20px;
            clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
            border-radius: 0;
            height: 75%;
        }
    }

    &--crest {
        &.section-hero .hero-image {
            top: -48px;
            left: 0;
        }
        .hero-bg {
            @media (max-width: 990px) {
                height: 585px;
            }
            @media (max-width: 767px) {
                height: 495px;
            }
        }
        .hero-buttons {
            .btn {
                padding: 16px;
            }
        }
    }

    .col-6 {
        position: relative;

        @media (max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .hero-image-col {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .hero-image {
        position: absolute;
        left: 60px;
        top: -24px;

        &--grad {
            position: relative;
            padding-top: 45px;
            padding-left: 100px;
            left: unset;
            top: unset;

            &.hero-image {
                @media (max-width: 990px) {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            // &.hero-image {
            //   @media (max-width: 990px) {
            //     .student-loan-hero-img-row {
            //       img {
            //         flex-basis: 300px;
            //         max-width: 300px;
            //         height: auto;
            //       }
            //     }

            //     .student-loan-sub-hero-img {
            //       bottom: -530px;
            //       left: -30px;
            //     }

            //   }
            // }
        }

        @media (max-width: 1200px) {
            max-height: 600px;
            width: auto;
        }
        @media (max-width: 990px) {
            left: 0;

            &.landing-hero-image {
                left: 40px;

                img:first-child {
                    width: auto;
                    height: 430px;
                }
                img:last-child {
                    width: auto;
                    height: 170px;
                }
            }

            img {
                width: auto;
                &:not(.hero-image_card-secondary, .hero-crest-block-image) {
                    height: 660px;
                }
            }
        }
    }

    .hero-benefits {
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;

        @media (max-width: 990px) {
            width: 120%;
        }

        @media (max-width: 767px) {
            margin-top: 40px;
            width: 100%;
        }

        .hero-benefit {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 9px;
            padding-left: 26px;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            &:nth-child(even) {
                margin-left: 15px;
                max-width: calc(50% - 15px);

                @media (max-width: 585px) {
                    margin-left: 8px;
                }
            }
            @media (max-width: 990px) {
                font-size: 14px;
                line-height: 18px;
            }

            @media (max-width: 768px) {
                padding-left: 19px;
                max-width: calc(50% - 15px);
            }

            &::before {
                content: "";
                width: 11px;
                height: 11px;
                background: url(../images/static/check.svg) center center no-repeat;
                position: absolute;
                left: 0;
                top: 6px;
                // transform: translateY(-50%);
            }

            &--blue {
                &::before {
                    background: url(../images/static/check-blue.svg) center center no-repeat;
                }
            }
            &--dark-green {
                &::before {
                    background: url(../images/static/check-dark-green.svg) center center no-repeat;
                }
            }
            &--secondary-green {
                &::before {
                    background: url(../images/static/check-secondary-green.svg) center center
                        no-repeat;
                }
            }
            &--peach {
                &::before {
                    background: url(../images/static/check-peach.svg) center center no-repeat;
                }
            }
        }
    }

    &-loan {
        .overhead-title {
            display: flex;
            gap: 12px;
            align-items: center;
            color: var(--greenColor);
            margin-bottom: 12px;

            @media (max-width: 767px) {
                margin-bottom: 8px;
            }
            p {
                color: var(--greenColor);
                font-weight: 500;
            }
        }
        .h2 {
            @media (max-width: 990px) {
                font-size: 32px;
                line-height: 48px;
            }
        }
        padding-top: 129px;
        padding-bottom: 0;

        .hero-bg {
            position: absolute;
            right: -15px;
            left: unset;
            top: -140px;
            width: 50%;
            height: 670px;
            z-index: 0;
            overflow: hidden;
            border-radius: 0 0 0 80px;
            transform: rotate(-4deg);

            @media (max-width: 767px) {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            }
        }

        .hero-search {
            max-width: 90%;
            @media (max-width: 990px) {
                max-width: 100%;
            }
        }

        .section-benefits .section-inner {
            padding: 0;
        }
    }
    .hero-buttons {
        margin-top: 41px;
        display: flex;
        gap: 16px;
    }
}

.hero-search {
    margin-top: 32px;
}

.more-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--greenColor);
    display: inline-flex;
    align-items: center;
    transition: all 400ms;
    padding: 0;
    border: none;

    &.peach {
        color: var(--peachColor);
    }

    .icon-arrow-right {
        margin-left: 12px;
        font-size: 12px;
        transition: all 400ms;
    }

    &:hover {
        .icon-arrow-right {
            transform: translateX(5px);
        }
    }
}
// .title-block {
//   .h2 {
//     br {
//       @media (max-width: 990px) {
//         display: none;
//       }
//     }
//   }
// }
.block-with-cta {
    .title-block {
        margin-bottom: 24px;
    }

    p {
        color: var(--greyColor);
        font-size: 16px;
        line-height: 24px;

        & + p {
            margin-top: 24px;
        }

        @media (min-width: 990px) {
            padding-right: 70px;
        }
    }

    .btn {
        margin-top: 24px;
    }
}

.underline {
    text-decoration: underline;
}

.row-page-title {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.student-loan-page,
.parent-loan-page {
    .section-hero-loan {
        @media (max-width: 767px) {
            .hero-bg {
                display: none;
            }
        }

        &.parent-loan-page {
            .overhead-title {
                p {
                    color: var(--peachColor);
                }
            }

            .search-module-header {
                .btn {
                    background-color: var(--peachColor);
                    color: var(--whiteColor);
                    border: none;

                    &:hover {
                        background-color: var(--peachColor);
                    }
                }
            }
        }

        &--under {
            .row {
                .col-6 {
                    &:first-child {
                        p {
                            @media (min-width: 990px) {
                                padding-right: 70px;
                            }
                        }
                    }
                }
            }

            .hero-bg {
                height: 720px;
            }

            .hero-image {
                position: relative;
                top: unset;
                left: unset;
                width: 120%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 15px;
                height: 100%;

                @media (max-width: 990px) {
                    width: 100%;
                }
            }
        }

        &--graduate {
            padding-bottom: 160px;
            @media (max-width: 990px) {
                padding-bottom: 80px;
            }
            @media (max-width: 767px) {
                padding-bottom: 40px;
            }
            .hero-bg {
                height: 720px;
            }
            .hero-benefits {
                @media (max-width: 990px) {
                    width: 110%;
                }
            }
            .col-6 {
                &:first-child {
                    p {
                        @media (min-width: 990px) {
                            padding-right: 70px;
                        }
                    }
                }
            }
        }

        .row {
            position: relative;
            z-index: 2;
        }

        &--international {
            .btn-green {
                background-color: var(--secondaryGreen);
            }
        }

        &--refi {
            .btn-green {
                background-color: var(--greenSecondaryColor);
            }
            .hero-benefits {
                @media (max-width: 990px) {
                    width: 110%;
                }
            }
        }
    }

    .section-testimonials {
        .section-inner {
            padding-top: 0;
        }
    }
}

.col-12 {
    flex: 0 0 100%;
    width: 100%;
}

.nested-navigation-wrapper {
    margin-top: 134px;
    position: relative;
    z-index: 4;

    .nested-navigation {
        display: flex;
        color: var(--neutralColor600);
        gap: 40px;
        font-size: 16px;
        margin: 0 15px;
        overflow-x: auto;
    }

    .nested-navigation-link {
        white-space: nowrap;
        transition: color 0.4 cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
            color: rgba(var(--borrowerPrimaryColor), 1);
        }

        &--active {
            color: var(--greenColor);
        }
    }
}

.newsroom-wrapper {
    .section-hero-blog .hero-corner-bg {
        width: 34%;
    }

    &--overview {
        margin-top: -160px;
        background-color: var(--neutralColor100);
        margin-bottom: 55px;

        .section-statistic {
            bottom: -85px;

            @media (max-width: 1270px) {
                bottom: -55px;
            }

            .block-with-cta,
            .col-static-image {
                @media (max-width: 990px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                    order: 1;
                }
                @media (max-width: 767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    order: 1;
                }
            }

            .section-inner {
                padding: 248px 0;

                @media (max-width: 990px) {
                    padding: 110px 0 40px;
                }
            }

            .static-image {
                top: auto;
                bottom: -150px;
                @media (max-width: 1200px) {
                    img {
                        height: auto;
                        width: 90%;
                    }
                }
                @media (max-width: 990px) {
                    margin-bottom: 0;
                    bottom: 0;
                }
                @media (max-width: 767px) {
                    margin-top: 24px;
                }
            }
        }
    }

    &--stories {
        .section-overview-news {
            .custom-bg {
                height: calc(100% + 100px);
            }

            @media (max-width: 767px) {
                .section-inner {
                    padding-bottom: 60px;
                }
            }
        }
    }

    &--news {
        .section-inner {
            padding: 80px 0;

            @media (max-width: 990px) {
                padding-top: 50px;
            }
            @media (max-width: 768px) {
                padding-top: 36px;
            }
        }
    }
}

.about-page-wrapper {
    &-header {
        .header-wrapper {
            .col-btn {
                .btn {
                    @media (max-width: 990px) {
                        background-color: rgba(var(--borrowerPrimaryColor), 1);
                        color: var(--whiteColor);
                    }
                }
            }
            .main-menu-item.main-menu-item-header-link.main-menu-item-1 {
                .main-menu-item__primary {
                    color: var(--whiteColor);

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    @media (max-width: 990px) {
        .hero-image-bar {
            display: none;
        }
        .section-hero-about {
            .section-inner {
                padding-bottom: 135px;
            }
        }
    }
}
.mobile-cta {
    padding-top: 32px;
    @media (min-width: 768px) {
        display: none;
    }
}
.table-cta {
    padding-top: 16px;
    @media (min-width: 991px) {
        display: none;
    }
}

.desktop-cta {
    text-align: center;

    @media (max-width: 767px) {
        text-align: left;
    }
}
.slider-pagination {
    display: flex;
    justify-content: center;
    .slider-pagination-item {
        width: 32px;
        flex: 0 0 32px;
        height: 4px;
        border-radius: 4px;
        background: var(--whiteColor);
        opacity: 0.3;
        cursor: pointer;
        transition: all 400ms;
        & + .slider-pagination-item {
            margin-left: 4px;
        }
        &.active {
            opacity: 1;
        }
    }
}

.ScrollbarsCustom-Scroller {
    margin-right: 0px !important;
    margin-bottom: -11px !important;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    &::-webkit-scrollbar {
        display: none !important;
    }
}
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
    height: 4px !important;
    width: 100% !important;
    left: 0 !important;
    background: rgba(var(--greenColorRGB), 0.3) !important;
}
.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
    background: var(--greenColor) !important;
}
.ScrollbarsCustom-Content {
    display: flex;
    align-items: center;
    padding-bottom: 10px !important;
}
