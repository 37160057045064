@import "../../../assets/styles/colors";

.like-animation {
    width: 100%;
    max-width: 110px;

    img {
        max-width: 100%;
        height: auto;
    }
}
.uppercase {
    text-transform: uppercase !important;
}

.onboard-home {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .loading-offers {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0 !important;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.1;
        }
        div[aria-label="animation"] {
            width: 100%;
            max-width: 150px;
        }
        &-content {
            width: 100%;
            max-width: 450px;
            padding: 100px 20px;
            @media (max-width: 575px) {
                br {
                    display: none;
                }
            }
        }
        &-text {
            text-align: center;
            margin-top: 30px;
            position: relative;
            min-height: 85px;
            &-item {
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                transition: all 400ms;
                transform: translateY(40px);
                opacity: 0;
                &.active {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
    &.completed {
        animation: 500ms hideLoader forwards;
        animation-delay: 0.5s;
        .loading-offers {
            animation: 1s hideAnimation forwards;
        }
    }

    &--loading-offers {
        background-color: $lightColor;
        min-height: 100vh;
        min-width: 100vw;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 901;

        .loading-offers {
            height: 100%;
        }
    }
}
@keyframes hideAnimation {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-50px);
        opacity: 0;
    }
}
@keyframes hideLoader {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        pointer-events: none;
    }
}

.tooltip-offer-module {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px !important;
    padding: 0 16px !important;
    max-width: 446px !important;
    max-height: 300px !important;
    width: 100% !important;
    background: var(--whiteColor) !important;
    opacity: 1 !important;
    z-index: 20 !important;
    pointer-events: auto !important;

    @media (max-width: 767px) {
        left: 30px !important;
        max-width: 300px !important;
    }

    & > div,
    p,
    span {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: var(--primaryColor2);
    }

    .text-description__wrapper {
        max-height: 262px !important;
        height: 100%;
        width: 100%;
        overflow-y: auto !important;
        margin: 16px 0 !important;

        ::-webkit-scrollbar {
            width: 12px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }

        .text-description {
            h3 {
                font-size: 16px;
            }

            a {
                text-decoration: none;
                transition: none;
            }
        }
    }
}

.offers {
    .direct-apply-container {
        padding-top: 40px;
        padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        .direct-apply-title-section {
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .direct-apply-title-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 11px;
                @media (max-width: 767px) {
                    align-items: flex-start;
                }
                .direct-apply-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: #151b26;
                }
            }
            .direct-apply-subtitle {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #878f99;
            }
        }
        
        .direct-apply-row {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .direct-apply-empty-row {
            margin-top: 0px;
            margin-bottom: 0px
        }
    }
    .disclosures-description {
        margin-top: 10px;
    }
    .disclosures {
        text-align: left;
    }
    .card-big {
        margin-top: 24px;
        margin-bottom: 32px;

        border-radius: 16px;
        position: relative;
        z-index: 0;
        width: 100%;
        color: $whiteColor;
        display: flex;
        flex-direction: column;
        background: linear-gradient(254.6deg, #f4a645 0%, #f06e2d 100%);
        overflow: hidden;
        .edit-loan {
            position: relative;
            flex: 0 0 calc(100% - 200px);
            .form-col {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
            &.show-input {
                h3 {
                    opacity: 0;
                }
                .form-col {
                    opacity: 1;
                }
            }
        }
        .card-big-inner {
            padding: 20px;
        }
        .form-col {
            padding-left: 0;
            padding-right: 0;
            .form-group {
                margin-bottom: 0;
                input {
                    border: none !important;
                    color: #fff;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 40px;
                    padding: 0;
                    &::placeholder {
                        color: rgba($whiteColor, 0.5);
                    }
                }
            }
        }
        @media (max-width: 991px) {
            margin-bottom: 16px;
        }
        @media (max-width: 767px) {
            margin-bottom: 24px;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background-image: url(../../../assets/images/card-back.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .name {
            color: #fff;
            font-size: 13px;
            line-height: 16px;
        }

        &.card-green {
            background-image: linear-gradient(254.6deg, #8cce56 0, #57a051 100%);
        }
        &.card-yellow {
            background: linear-gradient(254.6deg, #f4a645 0%, #f06e2d 100%);
        }

        .card {
            &__top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;

                h3 {
                    color: #fff;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 40px;
                    display: flex;
                    align-items: center;
                }
                .status {
                    background: rgba(30, 37, 52, 0.15);
                    border-radius: 12px;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    padding: 8px 16px;
                }
            }
            &__content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .offers-tab {
        margin-bottom: 34px;

        li {
            font-size: 16px;
            line-height: 20px;
            position: relative;
            margin-right: 32px;
            cursor: pointer;
            @media (max-width: 767px) {
                margin-right: 15px;
            }
            &:before {
                content: "";
                height: 2px;
                width: 0;
                background-color: #6fb554;
                position: absolute;
                bottom: -10px;
                left: 0;
                -webkit-transition: all 0.4s ease;
                -o-transition: all 0.4s ease;
                transition: all 0.4s ease;
            }

            &.active::before {
                width: 100%;
            }
        }
        &__badge {
            background-color: $greenColor;
            font-size: 10px;
            color: white;
            padding: 2px 8px;
            border-radius: 15px;
            vertical-align: bottom;
        }
    }

    .offers__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        .filter-wrapper {
            width: auto;
        }
        @media (max-width: 767px) {
            flex-direction: column;
            //align-items:;
            justify-content: space-between;
            align-items: flex-start;
            h3 {
                margin-bottom: 16px;
            }
            .filter-wrapper {
                width: 100%;
                .select-custom {
                    width: 100%;
                    margin-top: 0;
                }
            }
        }
    }
    .offers-table {
        margin-bottom: 40px;
        & + h3 {
            margin-bottom: 24px;
            & + .h4 {
                margin-bottom: 24px;
            }
        }
        .cl-value {
            position: relative;
            .tooltip-offer {
                position: absolute;
                right: -20px;
                top: -4px;
                width: auto;
                height: auto;
                border: none;
                box-shadow: none;

                &-hover {
                    color: #ccd6dd;
                    transition: all 400ms;
                    cursor: pointer;
                    font-size: 16px;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    box-shadow: none;
                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
        &__row.offers-table__ibo {
            .cl {
                &-apr {
                    max-width: unset;
                }
                &-term {
                    max-width: unset;
                }
                &-payment {
                    max-width: unset;
                }
            }
        }
    }
    .offers-table__row {
        text-align: center;
        background-color: transparent;
        transition: all 0.4s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 1px solid rgba(30, 37, 52, 0.1);
        border-radius: 16px;
        margin-bottom: 8px;
        position: relative;
        //&.offers-table__

        &.active,
        &:not(.head):hover {
            background-color: #fff;
            border-color: #fff;
        }

        .cl {
            padding: 10px;
            font-size: 14px;
            line-height: 18px;
            flex: 1 1 auto;
            width: 100%;

            &-logo {
                max-width: 65px;
            }
            &-loan-size {
                max-width: 120px;
                white-space: nowrap;
            }
            &-apr {
                max-width: 100px;
            }
            &-term {
                max-width: 60px;
                //width: 60px;
            }
            &-payment {
                //max-width: 130px;
                //width: 130px;
                //flex: 0 0 130px;
                max-width: 150px;
            }
            div {
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.4s ease;
                //background: #fff;
                img {
                    width: 24px;
                }
                &.discount {
                    position: relative;
                    &:before {
                        content: "";
                        background-image: url(../../../assets/images/discount.svg);
                        background-repeat: no-repeat;
                        position: absolute;
                        top: -5px;
                        right: -5px;
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }

        &.head {
            cursor: auto;
            border: none;
            padding-right: 150px;
            justify-content: space-between;
            @media (max-width: 575px) {
                padding-right: 150px;
            }
            div {
                padding: 0 10px !important;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: rgba(30, 37, 52, 0.4);
            }
        }

        //.cl{
        //  width: 10%;
        //}
        //.cl:nth-child(2),
        //.cl:nth-child(3) {
        //  width: 40%;
        //}

        &.declined {
            position: relative;
            overflow: hidden;
            .offer-info {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(237, 28, 36, 0.02);
                    -webkit-backdrop-filter: blur(2px);
                    backdrop-filter: blur(2px);
                    z-index: 1;
                    border-radius: 16px;
                }
            }
            &:hover {
                .offer-info {
                    background: #ed1c24;
                    border-color: #ed1c24;
                    &:before {
                        -webkit-backdrop-filter: none;
                        backdrop-filter: none;
                        border-radius: 16px;
                    }
                    .cl {
                        color: white;
                        sup {
                            color: $whiteColor;
                        }
                        div {
                            background-color: white;
                        }
                    }
                }
                .offer-toggle {
                    color: $whiteColor;
                    z-index: 4;
                }
                .pin-toggle {
                    z-index: 4;
                }
            }

            .declined {
                position: absolute;
                z-index: 1;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 10px;
                line-height: 12px;
                padding: 2px 8px;
                background: #ed1c24;
                border-radius: 6px;
                color: #fff;
                transition: all 0.4s ease;
            }
        }
        &.offers-table__PSL {
            justify-content: space-between;
            .cl {
                //flex: 0 0 calc(100% / 6);
            }
            //.cl {
            //  &:not(:first-child) {
            //    //flex: 0 0 calc(calc(100% - 60px) / 5);
            //    //max-width: calc(calc(100% - 60px) / 5);
            //    flex: 1 0 auto;
            //    width: auto;
            //    max-width: 120px;
            //    //max-width: calc(calc(100% - 60px) / 5);
            //  }
            //  &:nth-child(1) {}
            //  &:nth-child(2) {
            //
            //  }
            //  &:nth-child(3) {}
            //  &:nth-child(4) {}
            //  &:nth-child(5) {}
            //}
        }
        &.offers-table__SLR {
            clear: both;
            //.cl {
            //  &-logo {
            //    width: 65px;
            //    max-width: 65px;
            //  }
            //  &-loan-size {
            //    width: calc(calc(100% - 65px) / 4);
            //    max-width: calc(calc(100% - 65px) / 4);
            //    white-space: nowrap;
            //  }
            //  &-apr {
            //    width: calc(calc(100% - 65px) / 4);
            //    max-width: calc(calc(100% - 65px) / 4);
            //  }
            //  &-term {
            //    width: calc(calc(100% - 65px) / 4);
            //    max-width: calc(calc(100% - 65px) / 4);
            //  }
            //  &-payment {
            //    width: calc(calc(100% - 65px) / 4);
            //    max-width: calc(calc(100% - 65px) / 4);
            //  }
            //
            //}
        }
        &.pining {
            overflow: hidden;
            .pin-toggle {
                pointer-events: none;
            }
            .offer-toggle {
                color: $whiteColor;
            }
        }
    }

    .notification-small-card {
        background: #fff;
        -webkit-border-radius: 16px;
        border-radius: 16px;
        padding: 24px 50px 24px 24px;
        position: relative;
        width: 350px;
        margin: 0 auto;
        .close {
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            img {
                width: 16px;
            }
        }
    }

    .connect-card {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 64px;
    }

    .no-offer {
        margin: auto;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        margin-top: 120px;

        img {
            max-width: 295px;
            margin-bottom: 65px;
        }

        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 16px;
        }

        p {
            max-width: 335px;
        }
    }

    .ScrollbarsCustom {
        margin-top: 40px;
        p + h4,
        h3 + h4 {
            margin-top: 24px;
        }
        p {
            margin-top: 8px;
            color: #8f929a;
        }

        .ScrollbarsCustom-Content {
            padding-right: 10px !important;
        }
    }
    .offer-info {
        display: flex;
        width: 100%;
        padding-right: 150px;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 575px) {
            padding-right: 150px;
        }
    }
    .apply-btn {
        position: absolute;
        right: 51px;
        top: -1px;
        background: #78bd54;
        height: calc(100% + 2px);
        padding: 5px 18px;
        font-weight: 600;
        color: #fff;
        transition: all 0.4s;
        &:hover {
            background-color: #6eb14a;
        }

        &::before {
            content: "";
            width: 1px;
            height: 48px;
            background: rgba(30, 37, 52, 0.1);
            position: absolute;
            right: -1px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 2;
        }
    }
}
.pin-toggle {
    position: absolute;
    right: -1px;
    top: -1px;
    height: calc(100% + 2px);
    width: 52px;
    background: url(../../../assets/images/star-pin.svg) $greenColor center center no-repeat;
    border-radius: 0 16px 16px 0;
    &.pined {
        background-image: url(../../../assets/images/star-pined.svg);
    }
}
.offer-toggle {
    position: absolute;
    right: 123px;
    padding: 0 16px;
    border: none;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        transition: all 400ms;
    }
    &.active {
        span {
            transform: rotate(-180deg);
        }
    }
}
.offer-collapse {
    margin-bottom: 8px;
}
.offer-info {
    position: relative;
    overflow: hidden;
    .pining {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        div {
            max-width: 60px;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(#1e2534, 0.5);
            backdrop-filter: blur(5px);
            opacity: 1;
            pointer-events: none;
            content: "";
        }
    }
}
.offer-info-head {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    &-ico {
        flex: 0 0 40px;
        height: 40px;
        width: 40px;
        border-radius: 12px;
        background: $mainColor;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 90px;
    }
    &-title {
        font-size: 16px;
        line-height: 18px;
        padding-left: 16px;
        font-weight: 600;
    }
}
.offer-collapse__content-inner {
    background: $whiteColor;
    padding: 24px;
    border-radius: 16px;
    .offer-info-row {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        position: relative;
        border-radius: 8px;
        transition: background-color 400ms;
        .tooltip-content {
            &::before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 16px 13px 0 13px;
                border-color: #ffffff transparent transparent transparent;
                position: absolute;
                left: 40px;
                bottom: -8px;
            }
        }
        &-hover {
            cursor: pointer;
        }
        // &:last-child {
        //   &::before {
        //     display: none;
        //   }
        // }
        .title {
            //flex: 0 0 30%;
            //max-width: 150%;
            font-size: 14px;
            line-height: 18px;
            transition: all 400ms;
            //text-transform: lowercase;
            //&:first-letter {
            //  text-transform: uppercase;
            //}
        }
        .value {
            //flex: 0 0 70%;
            max-width: 430px;
            text-align: right;
            font-size: 14px;
            line-height: 18px;
            color: rgba(#000, 0.5);
            padding-left: 20px;
            transition: all 400ms;
            sup {
                right: 8px;
                top: 9px;
                position: absolute;
            }
            &.value-special {
                span {
                    color: #6fb554;
                }
                .old-price {
                    text-decoration-line: line-through;
                    color: #ed1c24;
                    margin-right: 8px;
                }
            }
        }

        & + .offer-info-row {
            &::before {
                content: "";
                position: absolute;
                top: -1px;
                left: 16px;
                width: calc(100% - 32px);
                height: 1px;
                background-color: rgba($mainColor, 0.1);
            }
        }
        &-hover {
            .tooltip-content {
                left: 95px;
                transition: all 0ms;
                bottom: 87%;
                @media (max-width: 767px) {
                    left: 0;
                }
                .tooltip-title {
                    // text-transform: lowercase;
                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
                a {
                    color: $greenColor;
                    transform: rotate(180deg);
                    position: absolute;
                    bottom: 7px;
                    right: 14px;
                    z-index: 2;
                    display: block;
                    padding-right: 10px;
                    &::before {
                        content: "";
                        width: 120%;
                        height: 110%;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        left: auto;
                        transform: translateY(-50%);
                        box-shadow: -25px 0px 16px 0px rgba(255, 255, 255, 0.9);
                        background: rgba($whiteColor, 0.9);
                        box-sizing: border-box;
                    }
                    &:hover {
                        color: $blackColor;
                    }
                    .icon-arrow-back {
                        font-size: 18px;
                        display: block;
                        position: relative;
                        z-index: 1;
                    }
                }
            }
            &:hover {
                &::before {
                    opacity: 0;
                }
                //sup {
                //  color: rgba($whiteColor, .5);
                //}
                .title,
                .value {
                    color: #000;
                }
                .title {
                    text-decoration: underline;
                }
                background-color: #f2f4f5;

                .tooltip-content {
                    opacity: 1;
                    transform: translateY(0px);
                    pointer-events: auto;
                }
            }
        }
    }
}
.filter-wrapper {
    display: flex;
    align-items: center;
    .clear-pin {
        color: #6fb554;
        padding: 0;
        border: none;
        margin-right: 24px;
        font-size: 14px;
        line-height: 18px;
        transition: color 400ms;
        @media (max-width: 767px) {
            margin-top: 20px;
        }
        &:hover {
            color: $mainColor;
        }
    }
}

.onboarding-tutorial__offers {
    &.onboarding-tutorial {
        display: block;
        overflow-y: auto;
        min-height: calc(100vh - 150px);
        @media (max-width: 767px) {
            overflow-y: hidden;
            position: static;
        }
    }
    @media (max-width: 1024px) {
        z-index: 99;
    }
    .tutorial__offers-nav {
        position: absolute;
        top: 40px;
        left: 280px;
        display: flex;
        align-items: center;
        @media (max-width: 1024px) {
            left: 0px;
            padding-left: 40px;
            top: 100px;
            position: fixed;
        }
        @media (max-width: 767px) {
            padding-left: 0px;
            flex-wrap: wrap;
            position: static;
            margin-bottom: 40px;
        }
        .h2-app {
            margin-bottom: 0;
            @media (max-width: 575px) {
                margin-top: 0;
            }
        }
    }
    .tutorial__offers {
        padding: 20px;
        width: 100%;
        min-height: 100%;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 1024px) {
            padding-top: 130px;
        }
        @media (max-width: 767px) {
            padding-top: 112px;
            padding-left: 20px;
            padding-right: 20px;
        }
        &-content {
            margin-top: 36px;
            max-width: 940px;
            margin-left: auto;
            margin-right: auto;
            @media (max-width: 767px) {
                margin-top: 0;
            }
        }
        .back-dashboard {
        }
    }
    .tutorial__offers-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 767px) {
            background: $whiteColor;
            border-radius: 16px;
            padding: 32px 20px;
        }
        img {
            margin-bottom: 20px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;
        }
        h2 {
            margin-bottom: 20px;
        }
    }
    .button-group {
        margin-top: 30px;
    }
}
.tutorial__offers-benefits {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 30px;
    @media (max-width: 767px) {
        justify-content: center;
    }
    .tutorial__offers-benefit {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding-left: 10px;
        padding-right: 10px;
        align-self: stretch;
        @media (max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
            & + .tutorial__offers-benefit {
                margin-top: 20px;
            }
        }
        @media (max-width: 575px) {
            max-width: 100%;
        }
    }
}
.benefit-border {
    border: 1px $greyColor solid;
    border-radius: 16px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    img {
        margin-bottom: 20px;
    }
}
.editable-quiz {
    border-radius: 16px;
    margin-bottom: 32px;
    line-height: 30px;
    overflow: hidden;
    &-content {
        padding: 28px 40px;
        background: $whiteColor;
        .text-green {
            font-weight: 600;
        }
        @media (max-width: 767px) {
            padding: 24px;
        }
    }
    .btn-secondary {
        margin-top: 24px;
        @media (min-width: 767px) {
            min-width: 285px;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.popup-wrapper.single-ibr-popup {
    .popup {
        max-width: 1200px;

        .disclosure {
            width: 100% !important;
            padding: 0 !important;
            border: none !important;
        }
    }
}
