.illustrativeText {
    display: flex;
    flex-direction: column;
    color: var(--neutral-400, #9ea9b0);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    margin-bottom: 56px;
    align-items: flex-end;
    margin-right: 5px;

    @media(max-width: 1024px){
        position: absolute;
        right: 40px;
        // corner hero height + 85px gap;
        top: calc(440px + 85px);
    }

    @media(max-width: 868px){
        display: none;
    }

    &.left{
        text-align: left;
        margin-top: 31px;
        align-items: flex-start;
    }
}

.tooltip:hover{
    cursor: pointer;
}
