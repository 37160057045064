.linksContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 15px;
    padding-right: 35px;
    width: 100%;
}

.linkTitle {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 8px;
}

.link {
    font-size: 14px;
    line-height: 18px;
    transition: all 0.4s;
    text-decoration: none;
    color: var(--darkGreyColor);
    &:hover {
        color: rgba(var(--borrowerLinkColor), 0.7);
    }
}
