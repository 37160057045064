@import "src/assets/styles/colors";
.confirm-checkbox {
    .checkbox-group {
        justify-content: flex-start;
        width: 100%;
        margin-top: 24px;
    }
}
.apply-disclosure {
    a {
        color: $greenColor;
    }
}

.scroll-block {
    height: 150px;
    > div {
        height: 100%;
        overflow: hidden scroll;
        padding-right: 20px;

        p {
            color: #8f929a;
            font-size: 14px;
            line-height: 18px;

            + p {
                margin-top: 18px;
            }
        }
    }
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 4px !important;
}

.ScrollbarsCustom-Wrapper {
    p {
        & + p {
            margin-top: 24px;
        }
    }
}
.modal-title {
    margin-bottom: 24px;
    .subtitle {
        margin-bottom: 16px;
        font-size: 16px;
        color: #6fb554;
        text-transform: uppercase;
        line-height: 24px;
        font-weight: 600;
    }
    .h3 {
        margin-bottom: 16px;
    }
    .description {
        margin-top: 0;
    }
}
.submit-step {
    overflow: hidden;
    border-radius: 16px;
    h5 {
        text-align: left;
        flex: 0 0 calc(100% - 210px);
        padding-right: 30px;
    }
    & + .submit-step {
        margin-top: 8px;
    }
    &-inner {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f2f4f5;
    }
}
