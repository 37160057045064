.profile-wrapper {
  position: relative;
}

.profile-head {
  margin-bottom: 24px;
  .row {
    justify-content: space-between;
  }
  &__menu,
  &__back {
    flex: 0 0 48px;
    max-width: 48px;
    height: 40px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
      order: -1;
    }
  }
  &__menu {
    padding-left: 0;
    justify-content: flex-end;
  }
  &__back {
    padding-right: 9px;
  }
  &__title {
    flex: 0 0 calc(100% - 96px);
    max-width: calc(100% - 96px);
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 16px;
    }
    .h2 {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .back-btn {
    width: 24px;
    height: 24px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    color: var(--primaryColor2);
    cursor: pointer;
    transition: all 400ms ;
    &:hover {
      color: rgba(var(--borrowerPrimaryColor), 1);
    }
  }
}
.profile-info {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    @media (max-width: 990px) {
      flex-direction: column-reverse;
    }
    .col {
      padding-left: 8px;
      padding-right: 8px;
    }
    .col-8 {
      flex: 0 0 calc(100% - 375px);
      max-width: calc(100% - 375px);
      @media (max-width: 990px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .col-4 {
      flex: 0 0 375px;
      max-width: 375px;
      @media (max-width: 990px) {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        margin-bottom: 32px;
      }
      .profile-card-info {
        padding: 24px;
      }
    }
  }
}
.profile-card-info {
  padding: 40px;
  background-color: var(--whiteColor);
  border-radius: 16px;
  @media (max-width: 767px) {
    padding: 24px;
  }
  .btn {
    width: 100%;
  }
  & + .profile-card-info {
    margin-top: 16px;
  }
  .cancel-btn {
    width: 100%;
    margin-top: 24px;
    @media (max-width: 990px) {
      @media (min-width: 768px) {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    &-title {
      max-width: calc(100% - 30px);
      p {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--neutralColor500);
      }
    }
    .edit-btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: var(--primaryColor2);
      cursor: pointer;
      transition: all 400ms;
      &:hover {
        color: rgba(var(--borrowerPrimaryColor), 1);
      }
    }
  }
  &__content {
    &-info {
      &-row {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        border-top: 1px solid rgba(#151B26, .1);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        &:last-child {
          border-bottom: 1px solid rgba(#151B26, .1);
        }
      }
      &-value {
        color: var(--neutralColor500);
      }
    }
    &-child {
      @media (max-width: 990px) {
        @media (min-width: 768px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .mailing-settings {
    margin-bottom: 24px;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 400ms;
    @media (max-width: 990px) {
      display: flex;
      @media (min-width: 768px) {
        flex: 0 0 calc(100% - 310px);
        max-width: calc(100% - 310px);
        margin-bottom: 0;
      }
    }
    &.mailing-settings-disable {
      pointer-events: none;
      opacity: .5;
    }
    .sparrow-simple-checkbox {
      cursor: pointer;
      & + .sparrow-simple-checkbox {
        margin-top: 12px;
        @media (max-width: 990px) {
          margin-top: 0;
          margin-left: 40px;
        }
      }
    }
  }
}
.cancel-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primaryColor2);
  cursor: pointer;
  transition: all 400ms;
  padding: 0;
  text-align: center;
  &:hover {
    color: var(--greenColor);
  }
}
.profile-modal {
  .form-row {
    & + .form-row {
      margin-top: 24px;
    }
  }
  .h3 {
    margin-bottom: 0 !important;
    & + .muted {
      margin-top: 12px;
    }
  }
  .profile-modal-content__head {
    margin-bottom: 32px;
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .muted {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutralColor500);
    margin-bottom: 0;
    & + .muted {
      margin-top: 24px;
    }
    span {
      color: var(--primaryColor2);
    }
  }
  label {
    text-align: left;
  }
  .button-group {
    margin-top: 32px;
    .btn {
      min-width: 160px;
      & + .btn {
        margin-left: 24px;

      }
    }
    @media (max-width: 575px) {
      .btn {
        width: 100%;
        & + .btn {
          margin-left: 0;
          margin-top: 16px;
        }
      }
    }
  }
  &-edit {
    .popup-content {
      padding: 40px;
    }

  }
}

.profile-modal-content-edit {
  .form-row {
    flex-wrap: wrap;
    position: relative;
    &.form-row-hasLink {
      grid-gap: 0;
      padding-bottom: 26px;
      .error-text {
        position: absolute;
        right: 0;
        top: 100%;
        max-width: calc(100% - 140px);
        text-align: right !important;
      }
    }
    .link {
      color: var(--greenColor);
      display: block;
      text-align: left;
      position: absolute;
      bottom: 0;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        opacity: .8;
      }
    }
    .form-group .error-text, .form-group label.error:not(input) {
      text-align: left;
    }
  }
  .h3 {
    text-align: left;
  }
  .react-international-phone-input-container .react-international-phone-country-selector{
    width: 28px;
  }
  .react-international-phone-input-container .react-international-phone-country-selector-button {
    width: auto;
    margin-right: 0;
    .react-international-phone-country-selector-button__button-content {
      margin-left: 0;
    }
  }
  .react-international-phone-input-container .react-international-phone-input {
    padding-left: 28px !important;
  }
  .react-international-phone-input-container .react-international-phone-country-selector-button,
  .react-international-phone-input-container .react-international-phone-country-selector{
    border-bottom: none;
  }
  .react-international-phone-input-container .react-international-phone-country-selector{
    bottom: 2px;
    padding-bottom: 2px;
  }
}
.profile-modal-content-confirm {
  .profile-modal-content__head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    .h3 {
      & + p {
        margin-top: 8px;
      }
    }
    .confirm-modal-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--neutralColor600);
      span {
        color: var(--primaryColor2);
        font-weight: 500;
      }
      & + .confirm-modal-text {
        margin-top: 8px;
      }
    }
    .resend-request {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--primaryColor2);
      text-decoration: underline;
      transition: all 400ms;
      padding: 0;
      cursor: pointer;
      margin-left: 8px;
      &:hover {
        color: var(--greenColor);
      }
    }
  }
  .back {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 400ms;
    padding: 0;
    border: none;
    margin-bottom: 16px;
    &:hover {
      color: var(--greenColor);
    }
    span {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }

  }
  .step-form-body {
    margin-bottom: 0;
    & + .profile-modal-content__head {
      margin-top: 40px;
      @media (max-width: 767px) {
        margin-top: 24px;
      }
    }
    .step-form-block {
      padding: 0;
    }
  }
  .code-input-email {
    margin-right: 0;
  }
  .button-group {
    margin-top: 40px;
    .btn {
      width: 100%;
    }
  }
  .code-input-email .input input {
    &.hasError {
      border-color: #F32B32;
    }
  }
}
.profile-modal-edit  {
  .popup {
    overflow: visible;
  }
}
.modalSnackError {
  position: absolute;
  bottom: calc(100% + 16px);
  width: 100%;
  padding: 12px;
  background-color: #FFE4E5;
  border-radius: 8px;
  color: #F32B32;
  left: 0;
  .modalSnackError-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
    max-width: calc(100% - 32px);
    left: 16px;
  }
  svg {
    margin-right: 8px;
  }
}
.popup-wrapper.popup-wrapper-mobile-view.profile-modal-edit .popup .popup-content {
  @media (max-width: 767px) {
    padding-top: 24px;
  }
}