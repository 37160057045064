@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/screen-size-variables.scss";

$anchorHeight: 24px;

.paaas-left-navigation-custom-menu-buttons {
    display: none;
    @media (max-width: $desktop-width) {
        display: block;
    }

    ul {
        padding: 0;
        border-bottom: 1px solid $lightColor;

        @media (max-width: $tablet-width) {
            padding-bottom: 0;
        }
        &:last-of-type {
            border-bottom: none;
            @media (max-width: $tablet-width) {
                padding-top: 0;
            }
        }

        li {
            margin: 34px 0px;
            position: relative;
            @media (max-width: $tablet-width) {
                margin: 32px 0px;
            }
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }

            button,
            div,
            a {
                height: $anchorHeight;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;

                color: $mainColor;
                display: flex;
                align-items: center;
                transition: none;
                background: transparent;
                padding: 0;
                border: none;
                transition: all 400ms;
                gap: 12px;

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    width: 20px;
                    transition: all 0.4s ease;
                }
                &:hover {
                    color: $greenColor;
                }
            }

            &.active {
                &:before {
                    background-color: $greenColor;
                    content: "";
                    height: 40px;
                    left: -40px;
                    position: absolute;
                    top: -(calc($anchorHeight / 2));
                    transition: height 0.4s ease-in;
                    width: 3px;
                }
                a {
                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.request-progress-and-divider {
    display: flex;
    flex-direction: column;
    @media (max-width: $tablet-width) {
        display: none;
    }
}
