.topSection{
    display: flex;
    position: relative;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

}
.leftContent{
  position: relative;
  z-index: 3;
  width: 50%;
  max-width: 560px;

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
}
}


.card-row {
    display: flex;
    width: 680px;
}

.contentGridContainer {
    position: absolute;
    left: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
    word-wrap: normal;

    @media (max-width: 1024px) {
        position: static;
    }

    @media (max-width: 560px) {
        position: static;
        display: flex;
        flex-direction: column;
    }

}

.ratesPageContainer{
    @media(max-width: 860px){
        display: none;
    }
}
