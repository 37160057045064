.RadioLabel {
  text-align: left;
  padding: 16px;
  position: relative;
}

.RadioLabel.Selected.Line {
  border-bottom: 1px solid var(--neutral-100);
}

.RadioItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  opacity: 0.6;
  background: var(--primary-white);
  position: relative;
  transition: all 0.5ms;
}

.RadioItem.borrower:hover,
.RadioItem.Selected.borrower {
  opacity: 1;
  border: 2px solid rgba(var(--borrowerPrimaryColor), 1);
}

.RadioItem.cosigner:hover,
.RadioItem.Selected.cosigner {
  opacity: 1;
  border: 2px solid rgba(var(--cosignerPrimaryColor), 1);
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  transition: linear 0.8s;
  height: 0;
  width: 0;
  -webkit-tap-highlight-color: transparent;
}

input[type="radio"]:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  top: 50%; /* Vertically align the pseudo-element */
  transform: translateY(-50%); /* Adjust for half of the pseudo-element's height */
  right: 16px;
  border-radius: 20px;
  border: 1px solid var(--neutral-300);
  cursor: pointer;
}

.RadioItem.Selected.borrower input[type="radio"]:checked:after {
  background: rgba(var(--borrowerPrimaryColor), 1);
  border: 1px solid rgba(var(--borrowerPrimaryColor), 1); 
}


.RadioItem.Selected.cosigner input[type="radio"]:checked:after {
  background: rgba(var(--cosignerPrimaryColor), 1);
  border: 1px solid rgba(var(--cosignerPrimaryColor), 1); 
}

input[type="radio"]:checked:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background: var(--primary-white);
  top: 50%; /* Vertically align the pseudo-element */
  transform: translateY(-50%); /* Adjust for half of the pseudo-element's height */
  right: 21px;
  z-index: 1;
  cursor: pointer;
}

.ExpandedContent {
    padding: 16px;
}






