@import "../../../../assets/styles/screen-size-variables.scss";

.request-progress-container {
    width: 184px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .request-progress-step {
        padding: 1px;
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 9px;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        &.incomplete {
            color: #9ea9b0;
        }
        &.completed {
            color: rgba(var(--borrowerPrimaryColor), 1);
        }
        &.current {
            color: #151b26;
        }

        .current-progress-step {
            width: 22px;
            height: 22px;
            border: 4px solid #6db353;
            opacity: 0.3;
            border-radius: 50%;
        }
        .incomplete-progress-step {
            width: 22px;
            height: 22px;
            border: 4px solid#EDF2F6;
            border-radius: 50%;
        }
    }
    .request-progress-line {
        height: 16px;

        margin-left: 12px;
        border-left: 1px solid #edf2f6;

        &.active {
            border-left: 1px solid #6db353;
        }
    }
}

.request-progress-divider {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 0px;
    width: 100%;
    border-bottom: 0.5px solid #edf2f6;

    &.bottom {
        display: none;

        @media (max-width: $desktop-width) {
            display: block;
        }
    }
}

.request-progress-popup {
    position: fixed;
    z-index: 1001;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    left: 240px;

    background: #ffffff;
    border-radius: 21px 12px 12px 12px;
    filter: drop-shadow(0px 20px 60px rgba(30, 37, 52, 0.1));
    &.prequalify {
        width: 1073px;
    }
}

.generic-request-progress-popup-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    border-radius: 21px 12px 12px 12px;
    gap: 16px;

    .request-popup-step-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .request-popup-icon-container {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
        .request-popup-title-container {
            display: flex;
            flex-direction: row;
            gap: 5px;
            .request-popup-step-title {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #6db353;
            }
            .request-popup-step-subtitle {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #2b323f;
            }
        }
    }
    .request-popup-text-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .request-popup-title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #151b26;
        }
        .request-popup-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #878f99;
        }
    }
    .request-popup-request-fields-container {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding-top: 8px;
        .request-popup-request-fields {
            .request-line {
                width: 198px;
                height: 4px;
                background: #6db353;
                border-radius: 100px;
                padding: 0px;
                margin: 0px;
            }

            width: 198px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .request-popup-request-field-title {
                padding-top: 16px;

                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: left;
                color: #abb6bd;
            }
            .request-popup-request-field-value {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                color: #151b26;
            }
            .request-popup-request-info-title {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                height: 48px;
                color: #6b717b;
            }
            .request-popup-request-info-value {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #151b26;
            }
        }
    }
    .included-user-section {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;

        .add-cosigner-section {
            display: flex;
            flex-direction: row;
            gap: 24px;
            .add-cosigner-text {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #878f99;
            }
            .add-cosigner-button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 24px;
                gap: 8px;

                width: 146px;
                height: 40px;
                border: 2px solid #6db353;
                border-radius: 32px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #6db353;
                white-space: nowrap;
            }
        }

        .included-user-title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #9ea9b0;
        }
        .included-user-name-section {
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 100%;
            .included-user-name {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #151b26;
            }
        }
    }
}
