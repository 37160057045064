.roadmap-container {

    .loan-selection-type {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        margin-bottom: 8px;
    }

    .loan-amount {
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        color: #fff;

        @media (max-width: 767px) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .dates {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #fff;

        span {
            opacity: 0.7;
        }
    }

    p.request-id {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        margin-bottom: 8px;
        opacity: 0.7;
        margin: 0px;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    p.user-type {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        margin-bottom: 8px;
        background-color: blue;
        padding: 8px 16px;
        border-radius: 12px;
        background: rgba(30, 37, 52, 0.15);
        margin: 0px;
    }

    .information-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;

        .wrap-information {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
            gap: 8px;

            .wrap-value {
                display: flex;
                gap: 8px;

                .wrap-icon {
                    position: relative;
                    &.active {
                        &::after {
                            content: '';
                            position: absolute;
                            border-bottom: 1px solid var(--whiteColor);
                            width: 100%;
                            top: 42%;
                            left: 0;
                            transform: rotate(45deg);
                        }
                    }
                }

                .eye-icon {
                    width: 16px;
                    height: 16px;
                }
            }
            
            .title-info {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: var(--whiteColor);
            }

            .value-info {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: var(--whiteColor);
                opacity: 0.7;
            }

            .separate {
                border-bottom: 1px dashed #FFFFFF;
                opacity: 0.3;
                flex: 1;
            }
        }
    }
}
