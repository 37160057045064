.card-container{
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    width: 100%;
    padding-right: 35px;
    @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          .noIcon {
            color: rgba(var(--borrowerPrimaryColor), 0.6 + 0.1 * $i);
          }
        }
      }
}

.img {
    margin-bottom: 16px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin-bottom: 8px;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.noIcon{
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    // color: rgba(var(--borrowerPrimaryColor),0.7);

}