.ButtonStyle{
    text-align: center;
    transition: all 0.3s;
}

.PrimaryButtonType {
    color: var(--primary-white);
    background: var(--primary-color);
}

.PrimaryButtonType:hover:not(:disabled) {
    opacity: 0.8;
    cursor: pointer;
}

.CDButtonStyle {
    padding: 13px;
    border-radius: 16px; 
}

