@import "colors";

.degree-blocks {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  .degree-block {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 16px;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  +.step-form-footer{
    margin-top: 32px;
  }
}

.degree-cart {
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;

  .degree-cart-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 16px;
    align-items: center;
  }

  .degree-cart-info-wrapper {
    display: flex;
    align-items: center;
    max-width: -webkit-calc(100% - 60px);
    max-width: calc(100% - 60px);
  }

  .degree-cart-logo {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(30,37,52,.2);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .degree-cart-logo+.degree-cart-info {
    padding-left: 16px;
  }

  .degree-cart-info {
    max-width: calc(100% - 40px);
  }

  .degree-cart-info-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000;
  }

  .degree-cart-info-type {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px;
    line-height: 18px;
    opacity: .5;
  }

  .degree-cart-grad {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    border-top: 1px rgba(30,37,52,.1) solid;
    line-height: 18px;
  }
}

.add-degree, .degree-cart-grad-value {
  color: #8F929A;
}

.add-degree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px dashed rgba($mainColor,.2);
  border-radius: 16px;
  transition: all 400ms;
  color: #8F929A;
  .plus {
    width: 40px;
    height: 40px;
    border: 1px solid rgba($mainColor,.2);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blackColor;
    border-radius: 12px;
    margin-bottom: 16px;
  }
  .text {
    font-size: 14px;
    line-height: 20px;

  }
  &:hover {
    color: $mainColor;
    border-color: rgba($mainColor,1);
  }
}
