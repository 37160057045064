.section {
    position: relative;
}

.innerSection {
    position: relative;
    z-index: 2;
    padding: 160px 0px 180px;
}

.contentSection {
    position: relative;
    z-index: 3;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 40px;

    @media(max-width: 768px){
        padding: 0px 20px;
    }

    @media(max-width: 375px){
        padding: 0px 16px;
    }
}