.question-tt-container {
    margin-right: 20px; /* Adjust this as needed */
    width: 100%;
  }

  /* Define the callout bubble */
  .callout {
    position: absolute;
    display: flex;
    background-color: #fff;
    padding: 16px; 
    border-radius: 12px;     

    bottom: 30px;
    left: 0px;
  /* Start with opacity at 0 (fully transparent) */
    opacity: 0; 
  }

  .callout span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  /* TODO: refactor to align with icon as part of CSS review*/
  /* Add the triangle to the callout */
  .callout::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 13%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }

  .question-tt-container:hover .callout,
  .question-tt-container:active .callout {
    display: flex;
    opacity: 1;
    box-shadow: 0px 20px 60px 0px rgba(30, 37, 52, 0.10); 
  }