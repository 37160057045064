@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/screen-size-variables.scss";

.footer-box-container {
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;

    .footer-box-custom-button {
        display: none;
        @media (max-width: $tablet-width) {
            display: block;
        }
    }
}

.footer-advertiser-disclosure {
    display: none;
    @media (max-width: 510px) {
        display: block;
    }
}

.account-user-container {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    position: relative;
    .tooltip-account {
        position: absolute;
        bottom: calc(100% + 17px);
        background-color: var(--whiteColor);
        padding: 16px;
        border-radius: 12px;
        filter: drop-shadow(0px 20px 60px rgba(30, 37, 52, 0.1));
        transition: all 400ms;
        left: -15px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        opacity: 0;
        pointer-events: none;
        &::before {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: #ffffff transparent transparent transparent;
        }
    }
    + .customer-support {
        margin-bottom: 0;
    }
    &:hover {
        .tooltip-account {
            opacity: 1;
        }
    }
    .muted {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--neutralColor500);
    }
    .name {
        font-weight: 500;
    }
    @media (max-width: $tablet-width) {
        margin: 0;
    }

    .initials {
        border-radius: 12px;
        width: 40px;
        height: 40px;
        background-color: $mainColor;
        color: $whiteColor;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.customer-support {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    .icon-logout {
        width: 24px;
        height: 24px;
        color: var(--neutralColor400);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
    }
    &-logout {
        margin-bottom: 0;
        margin-top: 0;
    }
    @media (max-width: $tablet-width) {
        padding-left: 0;
        & + .customer-support {
            .icon-logout {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
            }
        }
    }
    span {
        white-space: nowrap;
    }
}

.logout {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    transition: all 300ms ease;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 991px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    span {
        margin-right: 17px;
        font-size: 18px;
    }
}
