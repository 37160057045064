@import "font-face-mixin";
@include font-face("Agency FB", "../fonts/WindowsFonts/AgencyFB");
@include font-face("Aharoni", "../fonts/WindowsFonts/Aharoni");
@include font-face("Aldhabi", "../fonts/WindowsFonts/Aldhabi");
@include font-face("Andalus", "../fonts/WindowsFonts/Andalus");
@include font-face("Arial", "../fonts/WindowsFonts/Arial");
@include font-face("Arial", "../fonts/WindowsFonts/ArialBold", bold);
@include font-face("Arial", "../fonts/WindowsFonts/ArialBoldItalic", bold, italic);
@include font-face("Arial", "../fonts/WindowsFonts/ArialItalic", normal, italic);
@include font-face("Arial Black", "../fonts/WindowsFonts/ArialBlackRegular");
@include font-face("Arial Narrow", "../fonts/WindowsFonts/ArialNarrow");
@include font-face("Arial Narrow", "../fonts/WindowsFonts/ArialNarrowBold", bold);
@include font-face("Arial Narrow", "../fonts/WindowsFonts/ArialNarrowBoldItalic", bold, italic);
@include font-face("Arial Narrow", "../fonts/WindowsFonts/ArialNarrowItalic", normal, italic);
@include font-face("Bahnschrift", "../fonts/WindowsFonts/Bahnschrift");
@include font-face("Book Antiqua", "../fonts/WindowsFonts/BookAntiqua");
@include font-face("Book Antiqua", "../fonts/WindowsFonts/BookAntiquaBold", bold);
@include font-face("Book Antiqua", "../fonts/WindowsFonts/BookAntiquaBoldItalic", bold, italic);
@include font-face("Book Antiqua", "../fonts/WindowsFonts/BookAntiquaItalic", normal, italic);
@include font-face("Calibri", "../fonts/WindowsFonts/Calibri");
@include font-face("Calibri", "../fonts/WindowsFonts/CalibriBold", bold);
@include font-face("Calibri", "../fonts/WindowsFonts/CalibriBoldItalic", bold, italic);
@include font-face("Calibri", "../fonts/WindowsFonts/CalibriItalic", normal, italic);
@include font-face("Calibri", "../fonts/WindowsFonts/CalibriLight", 300);
@include font-face("Calibri", "../fonts/WindowsFonts/CalibriLightItalic", 300, italic);
@include font-face("Cambria", "../fonts/WindowsFonts/Calibri");
@include font-face("Cambria", "../fonts/WindowsFonts/CalibriBold", bold);
@include font-face("Cambria", "../fonts/WindowsFonts/CalibriBoldItalic", bold, italic);
@include font-face("Cambria", "../fonts/WindowsFonts/CalibriItalic", normal, italic);
@include font-face("Candara", "../fonts/WindowsFonts/Candara");
@include font-face("Candara", "../fonts/WindowsFonts/CandaraItalic", normal, italic);
@include font-face("Candara", "../fonts/WindowsFonts/CandaraBoldItalic", bold, italic);
@include font-face("Candara", "../fonts/WindowsFonts/CandaraBold", bold);
@include font-face("Candara", "../fonts/WindowsFonts/CandaraLight", 300);
@include font-face("Candara", "../fonts/WindowsFonts/CandaraLightItalic", 300, italic);
@include font-face("Century", "../fonts/WindowsFonts/Century");
@include font-face("Century Gothic", "../fonts/WindowsFonts/CenturyGothic");
@include font-face("Century Gothic", "../fonts/WindowsFonts/CenturyGothicBold", bold);
@include font-face("Century Gothic", "../fonts/WindowsFonts/CenturyGothicBoldItalic", bold, italic);
@include font-face("Century Gothic", "../fonts/WindowsFonts/CenturyGothicItalic", normal, italic);
@include font-face("Comic Sans MS", "../fonts/WindowsFonts/ComicSansMS");
@include font-face("Comic Sans MS", "../fonts/WindowsFonts/ComicSansMSBold", bold);
@include font-face("Comic Sans MS", "../fonts/WindowsFonts/ComicSansMSItalic", normal, italic);
@include font-face("Consola", "../fonts/WindowsFonts/Consola");
@include font-face("Consola", "../fonts/WindowsFonts/ConsolasBold", bold);
@include font-face("Consola", "../fonts/WindowsFonts/ConsolasBoldItalic", bold, italic);
@include font-face("Consola", "../fonts/WindowsFonts/ConsolasItalic", normal, italic);
@include font-face("Constantia", "../fonts/WindowsFonts/Constantia");
@include font-face("Constantia", "../fonts/WindowsFonts/ConstantiaBold", bold);
@include font-face("Constantia", "../fonts/WindowsFonts/ConstantiaBoldItalic", bold, italic);
@include font-face("Constantia", "../fonts/WindowsFonts/ConstantiaItalic", normal, italic);
@include font-face("Corbel", "../fonts/WindowsFonts/Corbel");
@include font-face("Corbel", "../fonts/WindowsFonts/CorbelBold", bold);
@include font-face("Corbel", "../fonts/WindowsFonts/CorbelBoldItalic", bold, italic);
@include font-face("Corbel", "../fonts/WindowsFonts/CorbelItalic", normal, italic);
@include font-face("Corbel", "../fonts/WindowsFonts/CorbelLight", 300);
@include font-face("Corbel", "../fonts/WindowsFonts/CorbelLightItalic", 300, italic);
@include font-face("Courier New", "../fonts/WindowsFonts/CourierNew");
@include font-face("Courier New", "../fonts/WindowsFonts/CourierNewBold", bold);
@include font-face("Courier New", "../fonts/WindowsFonts/CourierNewItalic", normal, italic);
@include font-face("Dubai Bold", "../fonts/WindowsFonts/DubaiRegular");
@include font-face("Dubai Bold", "../fonts/WindowsFonts/DubaiBold", bold);
@include font-face("Dubai Bold", "../fonts/WindowsFonts/DubaiLight", 300);
@include font-face("Dubai Bold", "../fonts/WindowsFonts/DubaiMedium", 500);
@include font-face("Ebrima", "../fonts/WindowsFonts/Ebrima");
@include font-face("Ebrima", "../fonts/WindowsFonts/EbrimaBold", bold);
@include font-face("Franklin Gothic", "../fonts/WindowsFonts/FranklinGothic");
@include font-face("Franklin Gothic", "../fonts/WindowsFonts/FranklinGothicMedium", 500);
@include font-face("Gabriola", "../fonts/WindowsFonts/Gabriola");
@include font-face("Gadugi", "../fonts/WindowsFonts/Gadugi");
@include font-face("Gadugi", "../fonts/WindowsFonts/GadugiBold", bold);
@include font-face("Garamond", "../fonts/WindowsFonts/Garamond");
@include font-face("Garamond", "../fonts/WindowsFonts/GaramondBold", bold);
@include font-face("Georgia", "../fonts/WindowsFonts/Georgia");
@include font-face("Georgia", "../fonts/WindowsFonts/GeorgiaBold", bold);
@include font-face("Georgia", "../fonts/WindowsFonts/GeorgiaItalic", normal, italic);
@include font-face("Haettenschweiler", "../fonts/WindowsFonts/Haettenschweiler");
@include font-face("HoloLens MDL 2 Assets", "../fonts/WindowsFonts/HoloLensMDL2Assets");
@include font-face("Impact", "../fonts/WindowsFonts/Impact");
@include font-face("Ink Free", "../fonts/WindowsFonts/InkFree");
@include font-face("Javanese Text", "../fonts/WindowsFonts/JavaneseText");
@include font-face("Leelawadee", "../fonts/WindowsFonts/Leelawadee");
@include font-face("Leelawadee", "../fonts/WindowsFonts/LeelawadeeBold", bold);
@include font-face("Leelawadee UI", "../fonts/WindowsFonts/LeelawadeeUI");
@include font-face("Leelawadee UI", "../fonts/WindowsFonts/LeelawadeeUIBold", bold);
@include font-face("Lucida Console", "../fonts/WindowsFonts/LucidaConsole");
@include font-face("Lucida Sans Unicode", "../fonts/WindowsFonts/LucidaSansUnicode");
@include font-face("Malgun Gothic", "../fonts/WindowsFonts/MalgunGothic");
@include font-face("Malgun Gothic", "../fonts/WindowsFonts/MalgunGothicBold", bold);
@include font-face("Malgun Gothic", "../fonts/WindowsFonts/MalgunGothicSemilight", normal, light);
@include font-face("Marlett", "../fonts/WindowsFonts/Marlett");
@include font-face("Microsoft Himalaya", "../fonts/WindowsFonts/MicrosoftHimalaya");
@include font-face("Microsoft New TaiLue", "../fonts/WindowsFonts/MicrosoftNewTaiLue");
@include font-face("Microsoft New TaiLue", "../fonts/WindowsFonts/MicrosoftNewTaiLueBold", bold);
@include font-face("Microsoft Phags Pa", "../fonts/WindowsFonts/MicrosoftPhagsPa");
@include font-face("Microsoft Phags Pa", "../fonts/WindowsFonts/MicrosoftPhagsPaBold", bold);
@include font-face("Microsoft Sans Serif", "../fonts/WindowsFonts/MicrosoftSansSerif");
@include font-face("Microsoft TaiLe", "../fonts/WindowsFonts/MicrosoftTaiLe");
@include font-face("Microsoft TaiLe", "../fonts/WindowsFonts/MicrosoftTaiLeBold", bold);
@include font-face("Microsoft Uighur", "../fonts/WindowsFonts/MicrosoftUighur");
@include font-face("Microsoft Yi Baiti", "../fonts/WindowsFonts/MicrosoftYiBaiti");
@include font-face("Mongolian Baiti", "../fonts/WindowsFonts/MongolianBaiti");
@include font-face("Monotype Corsiva", "../fonts/WindowsFonts/MonotypeCorsiva");
@include font-face("MS Outlook", "../fonts/WindowsFonts/MSOutlook");
@include font-face("MS Reference Sans Serif", "../fonts/WindowsFonts/MSReferenceSansSerif");
@include font-face("MS Reference Specialty", "../fonts/WindowsFonts/MSReferenceSpecialty");
@include font-face("MT Extra", "../fonts/WindowsFonts/MTExtra");
@include font-face("MV Boli", "../fonts/WindowsFonts/MVBoli");
@include font-face("Myanmar Text", "../fonts/WindowsFonts/MyanmarText");
@include font-face("Myanmar Text", "../fonts/WindowsFonts/MyanmarTextBold", bold);
@include font-face("Nirmala UI", "../fonts/WindowsFonts/NirmalaUI");
@include font-face("Nirmala UI", "../fonts/WindowsFonts/NirmalaUIBold", bold);
@include font-face("Nirmala UI", "../fonts/WindowsFonts/NirmalaUISemilight", normal, light);
@include font-face("Palatino Linotype", "../fonts/WindowsFonts/PalatinoLinotype");
@include font-face("Palatino Linotype", "../fonts/WindowsFonts/PalatinoLinotypeBold", bold);
@include font-face("Palatino Linotype", "../fonts/WindowsFonts/PalatinoLinotypeBoldItalic", bold, italic);
@include font-face("Palatino Linotype", "../fonts/WindowsFonts/PalatinoLinotypeItalic", normal, italic);
@include font-face("Segoe MDL 2 Assets", "../fonts/WindowsFonts/SegoeMDL2Assets");
@include font-face("Segoe Print", "../fonts/WindowsFonts/SegoePrint");
@include font-face("Segoe Script", "../fonts/WindowsFonts/SegoeScript");
@include font-face("Segoe Script", "../fonts/WindowsFonts/SegoeScriptBold", bold);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUI");
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUISemibold", 500);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUISemiboldItalic", 500, italic);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUILight", 300);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUILightItalic", 300, italic);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUIItalic", normal, italic);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUIBold", bold);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUIItalic", normal, italic);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUIBlack", 900);
@include font-face("Segoe UI", "../fonts/WindowsFonts/SegoeUIBlackItalic", 900, italic);
@include font-face("Segoe UI Historic", "../fonts/WindowsFonts/SegoeUIHistoric");
@include font-face("Segoe UI Symbol", "../fonts/WindowsFonts/SegoeUISymbol");
@include font-face("Sylfaen", "../fonts/WindowsFonts/Sylfaen");
@include font-face("Symbol", "../fonts/WindowsFonts/Symbol");
@include font-face("Tahoma", "../fonts/WindowsFonts/Tahoma");
@include font-face("Tahoma", "../fonts/WindowsFonts/TahomaBold", bold);
@include font-face("Times New Roman", "../fonts/WindowsFonts/TimesNewRoman");
@include font-face("Times New Roman", "../fonts/WindowsFonts/TimesNewRomanBold", bold);
@include font-face("Times New Roman", "../fonts/WindowsFonts/TimesNewRomanBoldItalic", bold, italic);
@include font-face("Times New Roman", "../fonts/WindowsFonts/TimesNewRomanItalic", normal, italic);
@include font-face("Trebuchet MS", "../fonts/WindowsFonts/TrebuchetMS");
@include font-face("Trebuchet MS", "../fonts/WindowsFonts/TrebuchetMSBold", bold);
@include font-face("Trebuchet MS", "../fonts/WindowsFonts/TrebuchetMSItalic", normal, italic);
@include font-face("Verdana", "../fonts/WindowsFonts/Verdana");
@include font-face("Verdana", "../fonts/WindowsFonts/VerdanaBold", bold);
@include font-face("Verdana", "../fonts/WindowsFonts/VerdanaItalic", normal, italic);
@include font-face("Webdings", "../fonts/WindowsFonts/Webdings");
@include font-face("Wingdings", "../fonts/WindowsFonts/Wingdings");
@include font-face("Wingdings 2", "../fonts/WindowsFonts/Wingdings2");
@include font-face("Wingdings 3", "../fonts/WindowsFonts/Wingdings3");
