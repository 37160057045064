.custom-header-button {
    white-space: nowrap;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: var(--primary-dark-blue, #151b26);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px;
}
