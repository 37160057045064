@import "../../../assets/styles//screen-size-variables.scss";

.mobile-navigation-header {
    display: none;
    width: 100%;
    padding: 40px 40px 24px 40px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    border-bottom: 1px solid transparent;
    background: white;

    @media (max-width: $tablet_width) {
        padding: 40px 16px 24px 16px;
    }

    @media (max-width: $desktop-width) {
        display: flex;
        position: sticky;
    }

    &.scrolled {
        border-bottom-color: var(--neutralColor200);
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &-left {
            display: flex;
        }
        &-right {
            display: flex;
        }
    }

    &__col {
        display: flex;
        align-items: center;
        padding-right: 12px;
        &:nth-child(2) {
            padding-left: 12px;
        }
        a {
            display: block;
            font-size: 30px;
            line-height: 30px;
            color: #78bd54;
        }
    }
    &__trigger {
        width: 28px;
        height: 28px;
        border: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
            height: 3px;
            border-radius: 8px;
            background-color: rgba(var(--borrowerPrimaryColor), 1);
            display: block;
            width: 28px;
            & + span {
                margin-top: 8px;
            }
        }
    }
    .button-trigger {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--whiteColor);
        background-color: var(--primaryColor2);
        opacity: 0;
        pointer-events: none;
        transition: all 400ms;
        position: relative;
        & + .button-trigger {
            margin-left: 12px;
        }
        &.active {
            opacity: 1;
            pointer-events: auto;
        }
        &.open {
            background-color: var(--whiteColor);
            color: var(--primaryColor2);
            font-size: 14px;
            &::before {
                opacity: 0;
            }
        }
        &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: var(--orangeColor);
            border: 2px solid var(--whiteColor);
            position: absolute;
            right: -3px;
            top: -3px;
            border-radius: 50%;
        }
    }
}
.sign-top-button-group {
    display: flex;
    gap: 16px;

    .mobile-navigation-header-advertiser-disclosure-button {
        display: block;
        // When the screen size is less than 510px, the advertiser disclosure button is
        // removed to prevent it from colliding with the company logo
        @media (max-width: 510px) {
            display: none;
        }
    }
    .mobile-navigation-header-custom-button {
        display: block;
        // When the screen size is less than 768px, the custom button is removed to prevent
        // the button group from colliding with the company logo
        @media (max-width: $tablet_width) {
            display: none;
        }
    }
}
