.container {
    left: 35%;
    bottom: 12%;
    z-index: 1000;
    width: 283px;
    height: 137px;
    background: blue;
    position: absolute;
    border-radius: 10.781px;
    background: radial-gradient(rgba(255, 255, 255, 0.99), rgba(var(--borrowerPrimaryColor), 1));
    border: 1px solid rgba(242, 244, 245, 0.5);
    padding: 20.21px;
    font-size: 9.433px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.129px;
    color: #fff;
}

.collegeInfo {
    display: flex;
    gap: 10.78px;
    justify-content: space-between;
    margin-bottom: 9.91px;
}

.book {
    padding: 5.39px;
    border-radius: 8.086px;
    background: var(--primary-white, #fff);
    width: 26.952px;
    height: 26.952px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowInfo {
    display: flex;
    justify-content: space-between;
    padding: 10.78px 0px;
    width: 100%;
    border-top: 0.5px solid rgba(#EDF2F6, 0.1);
    border-bottom: 0.5px solid rgba(#EDF2F6, 0.1);
    
}
