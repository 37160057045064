:root {
    font-family: "Basier Circle", sans-serif;
    color: var(--primary-dark-black);
    background: var(--primary-light-grey);
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

* {
    box-sizing: border-box;
}

#root {
    width: 100%;
    min-height: 100vh;
}

p {
    margin: 0;
    padding: 0;
}

a {
    font-weight: 500;
    color: var(--borrowerLinkColor);
    text-decoration: underline;
}

a.borrower {
    color: var(--borrowerLinkColor);
}

a.cosigner  {
    color: var(--cosignerLinkColor);
}

a.borrower:hover,
a.cosigner:hover {
    opacity: 0.5;
}

body {
    margin: 0;
    display: flex;
    place-items: center;
}

h1 {
    font-size: 3.2em;
    line-height: 1.1;
}

input {
    width: 100%;
}

.InputOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
