.font {
    color: var(--neutral-400);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.toggleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}
