.EditAddressSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    border-radius: 8px;
    padding: 16px 24px;
    background: var(--neutral-100);
}

.MailingAddressToggleSwitch{
    margin-top: 24px;
}

.ManualAddressWrapper {
    padding: 16px 24px;
    background: var(--neutral-100);
    border-radius: 8px;
    margin-top: 12px;
}

.ManualAddressBottomSection {
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.ManualAddressCancel{
    all: unset;
    transition: all 0.3s;
}

.ManualAddressCancel:hover {
    cursor: pointer;
    opacity: 0.5;
}
