.faqContainer {
    display: flex;
    gap: 30px;
    padding: 0px 15px;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

}

.sideContent{
    max-width: 270px;

    @media (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.title{
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 125% */ 
    margin-bottom: 16px;
}

.dateUpdated{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    text-transform: capitalize; 
    color: rgba(var(--borrowerPrimaryColor), 1);
    margin-bottom: 24px;
}

.description{
    color: var(--neutral-400, #9EA9B0);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}