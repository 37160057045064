* {
  outline: none; /* Remove default focus outline */
}

.ToggleButton {
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 500ms;
}

.ToggleButton.open {
  transform: rotate(180deg);
}

.DropdownContainer {
    padding-bottom: 16px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: absolute;
  top: 40px;
  z-index: 1;
  width: 100%;
  background: var(--primary-white);
}

.Dropdown {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  text-align: left;
  overflow: hidden;
  max-height: 200px;
  
  overflow-y: auto;
  
  scrollbar-color: #999999 #f5f5f5;
  scroll-behavior: smooth;
}

.DropdownOption {
  padding: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--secondary-grey);
}

.DropdownOption:focus,
.DropdownOption:hover,
.DropdownOption.focused {
  background: var(--neutral-50);
  font-weight: 700;
}

.PoweredByGoogle {
  border-bottom: 0px;
}

.ExtendHeight {
  max-height: 300px;
}


