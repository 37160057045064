@import "../../../assets/styles/variables";
@import "../../../assets/styles/screen-size-variables.scss";

$headerBarWidth: 72px;

.sign-top-header {
    position: sticky;
    top: 0;
    z-index: 998;

    height: $headerBarWidth;
    padding: 20px 24px 20px 40px;
    display: flex;
    align-items: center;
    gap: 32px;
    width: 100%;
    background: var(--whiteColor);
    box-shadow: 0px 20px 12px rgba(28, 37, 53, 0.01), 0px 9px 9px rgba(28, 37, 53, 0.02),
        0px 2px 5px rgba(28, 37, 53, 0.03), 0px 0px 0px rgba(28, 37, 53, 0.03);

    // For screens less than desktop width, we do not display the header bar.
    @media (max-width: $desktop-width) {
        display: none;
    }

    // Tablet width has transparent background and no custom buttons in the center of the header bar.
    @media (max-width: $tablet-width) {
        justify-content: space-between;
        background: transparent;
        box-shadow: none;
    }

    &_menu {
        width: 100%;
    }

    .sign-top-header_menu {
        display: flex;
        justify-content: var(--topMenuAlign);
        gap: 32px;

        @media (max-width: $tablet-width) {
            display: none;
        }

        a,
        div {
            cursor: pointer;
            transition: all 0.3s ease;
            color: var(--borrowerLinkColor);
            text-transform: var(--borrowerLinkTextTransform);
            font-family: var(--borrowerLinkFont);

            &:hover {
                transition: all 0.3s ease;
            }
        }

        .header__links__wrap-dropdown {
            position: relative;
            z-index: 2;

            .wrap {
                display: flex;
                align-items: center;

                .icon-arrow-bottom {
                    padding-left: 14px;
                    font-size: 10px;
                }
            }

            .wrap-dropdown-content {
                padding: 24px;
                background: var(--whiteColor);
                box-shadow: 0px 2px 8px rgba(30, 37, 52, 0.1);
                width: fit-content;
                position: absolute;
                top: 52px;
                border-top: 1px solid rgba(var(--borrowerPrimaryColor), 1);
                display: none;
                flex-direction: column;
                gap: 22px;
                z-index: 12;

                &.active {
                    display: flex;
                    z-index: 12;
                }

                .icon-arrow-right {
                    font-size: 10px;
                    padding-left: 38px;
                }

                li {
                    width: 100%;
                    display: block;
                    white-space: nowrap;
                }

                a {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                }
            }
        }
    }

    .sign-top-button-group {
        display: flex;
        align-items: center;
        gap: 16px;
        width: fit-content;
    }

    &_menu-mobile {
        .toggle-menu-mobile {
            width: 72px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--whiteColor);
            border-radius: 12px;
        }
    }

    &_menu-mobile_content {
        position: absolute;
        top: 86px;
        margin: 0px auto;
        left: 0;
        right: 0;
        width: 94%;
        height: auto;
        background-color: var(--whiteColor);
        z-index: 110;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        max-height: 100vh;
        overflow-y: auto;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .animate-height {
            max-width: 346px;
            width: 100%;
        }

        &--wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: 346px;
            width: 100%;
            padding: 24px;

            button {
                width: 100% !important;
                min-width: 100% !important;
                max-width: 100% !important;
                height: 42px !important;
            }

            .links-item {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                padding-bottom: 16px;
                border-bottom: 1px solid #ccd6dd;

                .header__links__wrap-dropdown {
                    .wrap {
                        width: 100%;
                        display: flex;
                        gap: 8px;
                        justify-content: space-between;
                        align-items: center;
                        color: var(--borrowerLinkColor);
                        text-transform: var(--borrowerLinkTextTransform);
                        font-family: var(--borrowerLinkFont);

                        .icon-arrow-bottom {
                            transform: rotate(0deg);
                            transition: all 0.3s ease;
                        }

                        &.active {
                            color: rgba(var(--borrowerPrimaryColor), 1);

                            .icon-arrow-bottom {
                                transform: rotate(180deg);
                                transition: all 0.3s ease;
                            }
                        }
                    }

                    .wrap-dropdown-content {
                        display: flex;
                        flex-direction: column;

                        li {
                            margin-top: 16px;
                            font-weight: 400;
                            color: var(--borrowerLinkColor);
                            text-transform: var(--borrowerLinkTextTransform);
                            font-family: var(--borrowerLinkFont);
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.footer {
    width: 100%;
    z-index: 9;
    padding: 24px 26px;
    background: #2c2c2d;
    display: flex;
    flex-direction: column;
    gap: 22px;

    @media (max-width: 600px) {
        padding: 16px;
    }

    &__content {
        display: flex;

        .riders-wrapper {
            display: flex;
            flex-direction: column;

            .rider-button {
                padding: 0;
                color: #edf2f6;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-decoration: underline;

                &.left {
                    justify-content: flex-start;
                    text-align: left;
                }

                &.right {
                    justify-content: flex-end;
                    text-align: right;
                }

                &.center {
                    justify-content: center;
                    text-align: center;
                }
            }
        }

        div {
            p,
            a {
                font-size: 12px;
                line-height: 18px;
                font-weight: normal;
                color: #edf2f6;
            }

            a {
                text-decoration: underline;
            }
        }

        &.left {
            justify-content: flex-start;
            text-align: left;
        }

        &.right {
            justify-content: flex-end;
            text-align: right;
        }

        &.center {
            justify-content: center;
            text-align: center;
        }
    }

    &__bottom {
        display: flex;
        width: 100%;

        &-content {
            display: flex;
            align-items: center;
            gap: 8px;

            &-text {
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                color: #9ea9b0;
            }
        }

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }

        &.center {
            justify-content: center;
        }
    }
}
