.header-wrapper {
  padding: 42px 0 0 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;

  @media (max-width: 990px) {
    padding: 32px 0 0 0;
  }

  .row {
    justify-content: space-between;
    align-items: center;
  }

  .col-btn {
    text-align: right;
  }

  .col-btn,
  .col-logo {
    flex: 0 0 180px;

    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .header-logo {
    color: var(--whiteColor);

    @media (max-width: 767px) {
      color: var(--greenColor);
    }
  }

  .col-menu {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .mobile-menu-btn {
    width: 72px;
    height: 44px;
    border-radius: 28px;
    background: url(../../assets/images/static/menu-white.svg) rgba(255,255,255, 0.2) center center no-repeat;
    cursor: pointer;
    transition: all 400ms;
    border: none;
    display: none;

    @media (max-width: 767px) {
      display: inline-flex;
    }

    &:focus {
      outline: none;
    }
  }

  .btn-white {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .main-menu {
    display: flex;
    justify-content: center;
    position: relative;
    .main-menu-item-header-link {
      cursor: pointer;
      transition: opacity 400ms;
      &.active {
        opacity: .6;
      }
    }
    .main-menu-item {
      position: relative;

      &__primary {
          border: none;
          padding: 10px 24px 16px 24px;
          display: block;
          font-family: var(--fontBasier);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--whiteColor);
          cursor: pointer;
          @media (max-width: 990px) {
            padding: 10px 20px 16px 20px;
          }
      }
      &.active {
        .main-menu-item__primary {
          //opacity: .6;
        }
      }
    }
  }

  &.header-wrapper--mixed {
    .mobile-menu-btn {
      background: url(../../assets/images/static/menu-white.svg) var(--borrowerPrimaryColor) center center no-repeat;
    }
    .btn-white {
      background: rgba(var(--borrowerPrimaryColor), 1);
      color: var(--whiteColor);
      &:hover {
        background: var(--neutralColor600);
        color: var(--whiteColor);
      }
    }
  }
  &.header-wrapper--mixed {
    .btn-white {
      background: rgba(var(--borrowerPrimaryColor), 1);
      color: var(--whiteColor);
      &:hover {

      }
    }
  }
  &.header-wrapper--mixed,
  &.header-wrapper--dark {
    .header-logo {
      color: var(--greenColor);
    }
    @media (max-width: 767px) {
      .mobile-menu-btn {
        background: url(../../assets/images/static/menu-white.svg) var(--borrowerPrimaryColor) center center no-repeat;
      }
    }
    .main-menu {
      .main-menu-item {
        .main-menu-item__primary {
          color: rgba(var(--borrowerPrimaryColor), 1);
        }
      }
    }
  }
}

.main-menu-dropdown-wrapper {
  position: absolute;
  left: 50%;
  top: calc(100% - 2px);
  transform: translate(-50%, 20px);
  perspective: 2000px;
  // HERE
  opacity: 0;
  pointer-events: none;
  display: none !important;
  // HERE END
  transition: all 400ms;

  &.active {
    display: block !important;
    opacity: 1;
    transform: translate(-50%, 0);
    pointer-events: auto;
    .main-menu-dropdown-content {
      transform: rotateX(0);
    }
  }
  .main-menu-dropdown-content {
    background: var(--whiteColor);
    border-radius: 8px;
    //overflow: hidden;
    transform: rotateX(-15deg);
    transform-origin: 50% -50px;
    transition: all 400ms;
  }
  .main-menu-dropdown-content__item {
    width: 524px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: all 300ms;
    box-shadow: var(--mainBoxShadow);
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
    &.main-menu-dropdown-content__item-main {
      width: 848px;
      @media (max-width: 990px) {
        width: 524px;
      }
    }

  }
  .main-menu-dropdown-content__item {
    overflow: hidden;
  }
  .main-menu-dropdown-content__item-main {
    .menu-content {
      &-inner {
        display: flex;
        border: 4px var(--neutralColor100) solid;
        background: var(--whiteColor);
        border-radius: 4px;
        .menu-content-left {
          flex: 0 0 272px;
          max-width: 272px;
          background: var(--neutralColor100);
          align-self: stretch;
          position: relative;
          @media (max-width: 990px) {
            flex: 0 0 220px;
            max-width: 220px;
          }
          &::after {
            content: '';
            width: 0;
            height: 100%;
            border-right: 4px var(--neutralColor100);
            position: absolute;
            right: -4px;
            top: 0;
            z-index: 0;
          }
          .active-item-bg {
            width: calc(100% - 4px);
            height: 102px;
            border-radius: 4px;
            position: absolute;
            left: 0;
            top: 0;
            z-index:1;
            transition: all 400ms;
            background: var(--whiteColor);

            &::before {
              content: '';
              width: 20px;
              height: 20px;
              border-radius: 4px;
              background: var(--whiteColor);
              border: 4px var(--neutralColor100) solid;
              position: absolute;
              right: -14px;
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
              z-index: -1;
              clip-path: polygon(100% 0, 0 0, 100% 100%);
            }
          }
          .menu-content-hover-item {
            padding: 20px;
            position: relative;
            z-index: 2;
            cursor: pointer;
            .menu-content-hover-title {
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
              color: rgba(var(--borrowerPrimaryColor), 1);
            }
            .menu-content-hover-description {
              font-size: 14px;
              line-height: 18px;
              color: var(--neutralColor400);
              margin-top: 2px;
            }
          }
          &.active-1 {
            .active-item-bg {
              top: 102px;
            }
          }
        }
        .menu-content-right {
          flex: 0 0 calc(100% - 272px);
          max-width: calc(100% - 272px);
          border-radius: 40px;
          position: relative;
          min-height: 204px;
          @media (max-width: 990px) {
            flex: 0 0 calc(100% - 220px);
            max-width: calc(100% - 220px);
          }
          .menu-content-hover-item {
            padding: 10px 4px 10px 8px;
            opacity: 0;
            transform: translateY(40px);
            pointer-events: none;
            transition: all 200ms;
            position: absolute;
            left: 0;
            top: 0;
            &.active {
              transition: all 300ms;
              opacity: 1;
              transform: translateY(0);
              pointer-events: auto;
            }
            //&:not(.show) {
            //  display: none;
            //}
          }
          .menu-content-hover-col {
            flex: 0 0 100%;
            max-width: 100%;
            &-2 {
              flex: 0 0 50%;
              max-width: 50%;
              &:nth-child(odd) {

                padding-right: 0;
              }
              &:nth-child(even) {

                padding-left: 0;
              }
              @media (max-width: 990px) {
                flex: 0 0 100%;
                max-width: 100%;
                &:nth-child(odd) {
                  padding-right: 15px;
                }
                &:nth-child(even) {
                  padding-left: 15px;
                }

              }
            }
          }
        }
      }
    }
    .menu-content-hover-link {
      display: flex;
      align-items: center;
      padding: 24px 16px;
      .menu-content-hover-icon {
        flex: 0 0 40px;
        height: 40px;
        max-width: 40px;
      }
      .menu-content-hover-info {
        flex: 0 0 calc(100% - 40px);
        max-width: calc(100% - 40px);
        padding-left: 12px;
        &__title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          display: flex;
          align-items: center;
          .icon-arrow-right {
            opacity: 0;
            transition: all 400ms;
            font-size: 8px;
          }
        }
        &__description {
          font-size: 14px;
          line-height: 18px;
          color: var(--neutralColor400);
          transition: color 400ms;
          white-space: nowrap;
          @media (max-width: 990px) {
            white-space: unset;
          }
        }
      }
      &:hover {
        .menu-content-hover-info {
          &__title {
            color: var(--greenColor);
            .icon-arrow-right {
              opacity: 1;
              transform: translateX(12px);
            }
          }
          &__description {
            color: rgba(var(--borrowerPrimaryColor), 1);
          }
        }
      }
    }
  }
  .main-menu-dropdown-content__item-default {
    .menu-content {
      padding: 32px;
      background: var(--whiteColor);
      border-radius: 8px;
    }
    .menu-content-menuBlock {
      & + .menu-content-menuBlock {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid var(--neutralColor100);
        .menu-content-menuBlock__title {
          margin-top: 6px;
        }
      }
      .menu-content-default-col {
        flex: 0 0 100%;
        max-width: 100%;
        &-2 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
      .menu-content-menuBlock__title {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 2px;
        color: var(--neutralColor400);
      }
      .menu-content-hover-link {
        display: flex;
        align-items: center;
        padding: 6px 0;
        .menu-content-hover-icon {
          flex: 0 0 20px;
          max-width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 18px;
            color: var(--neutralColor400);
            transition: color 400ms;
            &.icon-story {
              font-size: 14px;
            }
            &.icon-partner {
              font-size: 16px;
            }
          }
        }
        .menu-content-hover-info {
          flex:  0 0 calc(100% - 20px);
          max-width: calc(100% - 20px);
          padding-left: 10px;
          .menu-content-hover-info__title {
            transition: color 400ms;
            font-size: 16px;
            line-height: 24px;
          }
        }
        &:hover {
          .menu-content-hover-info {
            .menu-content-hover-info__title {
              color: var(--greenColor);
            }
          }
          .menu-content-hover-icon {
            span {
              color: var(--greenColor);
            }
          }
        }
      }
    }
  }
}