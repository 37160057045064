@import "windows-fonts.scss";
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier/BasierCircle-Medium.eot');
  src: local('Basier Circle Medium'), local('BasierCircle-Medium'),
  url('../fonts/Basier/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Basier/BasierCircle-Medium.woff2') format('woff2'),
  url('../fonts/Basier/BasierCircle-Medium.woff') format('woff'),
  url('../fonts/Basier/BasierCircle-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier/BasierCircle-Regular.eot');
  src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
  url('../fonts/Basier/BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Basier/BasierCircle-Regular.woff2') format('woff2'),
  url('../fonts/Basier/BasierCircle-Regular.woff') format('woff'),
  url('../fonts/Basier/BasierCircle-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier/BasierCircle-SemiBold.eot');
  src: local('Basier Circle SemiBold'), local('BasierCircle-SemiBold'),
  url('../fonts/Basier/BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Basier/BasierCircle-SemiBold.woff2') format('woff2'),
  url('../fonts/Basier/BasierCircle-SemiBold.woff') format('woff'),
  url('../fonts/Basier/BasierCircle-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Basier Circle';
  src: url('../fonts/Basier/BasierCircle-Bold.eot');
  src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
  url('../fonts/Basier/BasierCircle-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Basier/BasierCircle-Bold.woff2') format('woff2'),
  url('../fonts/Basier/BasierCircle-Bold.woff') format('woff'),
  url('../fonts/Basier/BasierCircle-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
