$screen_size_marker_1: 1200px;
$screen_size_marker_2: 1250px;
$screen_size_marker_3: 1400px;
// 0-1199: sidebar collapse
// 1199-1250: one header detail
// 1250-1400: two header detail
// 1400+: 3 header detail

.offer-card {
    border: 2px solid transparent;
    &.chart-selected-0 {
        border: 2px solid #e6c414;
    }
    &.chart-selected-1 {
        border: 2px solid #f19048;
    }
    &.chart-selected-2 {
        border: 2px solid #6db353;
    }
    &.chart-selected-3 {
        border: 2px solid #a461e8;
    }
    &.chart-selected-4 {
        border: 2px solid #6177e8;
    }

    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 16px;
    height: 100%;
    .apply-without-cosigner {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        gap: 8px;

        height: auto;

        background: #ffefc6;
        outline: 2.8px solid #ffefc6;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        text-align: center;

        /* Secondary/Orange 700 */

        color: #ec7d2a;
    }
    .offer-card-body-visible {
        @media (max-width: 767px) {
            overflow: visible !important;
        }
    }
    .offer-card-header {
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        gap: 10px;
        height: auto;
        min-height: 80px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $screen_size_marker_1) {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 18px;
        }
        @media (max-width: 767px) {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 18px;
        }

        @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
            flex-direction: row;
        }
        @media (max-width: $screen_size_marker_1) {
            flex-direction: column;
            gap: 16px;
        }
        .lender-and-detail-header-section {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            .lender-container {
                gap: 16px;
                float: left;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                min-width: 350px;

                &.direct-apply {
                    gap: 12.8px;
                }
                @media (max-width: $screen_size_marker_1) {
                    // no sidebar
                    width: 100%;
                }
                @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_2) {
                    // 1 header detail
                    width: 150px;
                }
                @media (min-width: $screen_size_marker_2) and (max-width: $screen_size_marker_3) {
                    // 2 header detail
                    width: 200px;
                }
                @media (max-width: 767px) {
                    gap: 13px;
                    min-width: 250px;
                }
                .lender-icon {
                    border-radius: 50%;
                    @media (max-width: 767px) {
                        width: 45px;
                        height: 45px;
                        flex: 0 0 45px;
                        max-width: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            height: auto;
                            width: auto;
                        }
                    }
                    img {
                        width: 45.6px;
                        height: auto;
                    }
                    &.direct-apply {
                        img {
                            width: 30.4px;
                            height: auto;
                        }
                    }
                }
                .lender-section-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    @media (max-width: 767px) {
                        max-width: calc(100% - 58px);
                    }
                    .lender-name-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        @media (max-width: 767px) {
                            max-width: calc(100% - 40px);
                            padding-right: 10px;
                        }
                        .lender-name {
                            white-space: nowrap;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 24px;
                            color: #151b26;
                            @media (max-width: 767px) {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .offer-label {
                            cursor: pointer;
                            white-space: nowrap;
                            font-weight: 400;
                            line-height: 20px;
                            align-self: stretch;
                            font-style: normal;
                            font-size: 14px;
                            color: rgba(var(--borrowerPrimaryColor), 1);
                            @media (max-width: 767px) {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                            }
                            // TODO: replace the two colors with variable names
                            &.cosigner {
                                color: rgba(var(--cosignerPrimaryColor), 1);
                            }
                            &.auto-rounded {
                                color: #e6c414;
                            }
                        }
                    }
                }
            }
            .offer-information-section-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 70%;
                @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
                    flex-direction: row;
                }
                @media (max-width: $screen_size_marker_1) {
                    flex-direction: column;
                }
                .offer-information-wrapper {
                    width: 100%;
                    .offer-information-container {
                        display: flex;
                        flex-direction: column;
                        @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
                            flex-direction: column;
                        }
                        @media (max-width: $screen_size_marker_1) {
                            flex-direction: row;
                        }
                        padding-left: 20px;
                        padding-right: 20px;
                        .offer-information-title-container {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                            align-items: center;
                            width: fit-content;

                            .offer-information-title {
                                white-space: nowrap;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                align-items: center;
                                color: #878f99;
                            }
                        }

                        .offer-information {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 24px;
                            white-space: nowrap;

                            color: #151b26;
                        }
                    }
                }
            }
        }
        .apply-container {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
            }
            @media (max-width: $screen_size_marker_1) {
                width: 100%;
            }
            &.active {
                width: 75%;
                justify-content: flex-end;
            }
        }
        .expand-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px;
            gap: 8px;
            border-radius: 32px;
            background: #edf2f6;
            height: 40px;
            width: 40px;
            span {
                transition: all 400ms;
            }
            &.active {
                background: #ccd6dd;
                span {
                    transform: rotate(-180deg);
                }
            }
            &:hover {
                background: #ccd6dd;
                transition: all 400ms;
                cursor: pointer;
            }
        }
    }
    .offer-card-body {
        padding-left: 24px;
        padding-right: 24px;
        display: grid;
        grid-gap: 24px 48px;
        grid-template-columns: 1fr 1fr 1fr;
        padding-bottom: 24px;
        padding-top: 28px;
        @media (min-width: $screen_size_marker_3) {
            border-top: 1px solid #edf2f6;
        }
        @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
            border-top: 1px solid #edf2f6;
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: $screen_size_marker_1) {
            grid-template-columns: 1fr;
            padding-top: 16px;
            grid-gap: 16px 48px;
        }

        @media (max-width: $screen_size_marker_1) {
            padding-left: 16px;
            padding-right: 16px;

            padding-bottom: 16px;
        }
        @media (max-width: 767px) {
            padding: 17px;
        }
        @media (max-width: 767px) {
            .offer-card-detail {
                .offer-card-detail-title-container {
                    .tooltip-offer-card {
                        &.tooltip-offer-card-Payments.after.school {
                            .bubble-wrapper-tooltip {
                                left: -140px;
                                &::before {
                                    left: 135px;
                                }
                            }
                        }
                        &.tooltip-offer-card-Payments.in.school {
                            .bubble-wrapper-tooltip {
                                left: -120px;
                                &::before {
                                    left: 115px;
                                }
                            }
                        }
                        &.tooltip-offer-card-Origination.Fee,
                        &.tooltip-offer-card-Repayment.Plan {
                            .bubble-wrapper-tooltip {
                                left: -100px;
                                &::before {
                                    left: 95px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .apply-button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        background: #6db353;
        color: #ffffff;
        border-radius: 32px;
        display: flex;
        flex-direction: row;

        align-items: center;
        padding: 12px 24px;
        gap: 8px;
        height: 40px;

        transition: all 0.4s;

        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        @media (max-width: 767px) {
            min-width: 178px;
            justify-content: center;
            .icon-arrow-right {
                display: none;
            }
        }
        @media (max-width: 374px) {
            min-width: 130px;
        }
        &.cosigner {
            background: #dc7962;
        }
        &:hover {
            cursor: pointer;
            background: #8cce56;
            &.cosigner {
                background: #eebcb0;
            }
        }

        width: 110px;
        min-width: 110px;
        padding-left: 30px;
        justify-content: flex-start;

        &.active {
            padding-left: calc(50% - 22.5px);
            padding-left: -webkit-calc(50% - 22.5px);
            padding-left: -moz-calc(50% - 22.5px);
            width: 100%;
            @media (max-width: 767px) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
    .interaction-button-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-around;
        width: auto;
        @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
        }
        @media (max-width: $screen_size_marker_1) {
            width: 100%;
            justify-content: flex-start;
            gap: 10px;
        }
        @media (max-width: 767px) {
        }
        &.active {
            justify-content: space-between;
            width: auto;
            @media (max-width: 767px) {
                align-items: flex-end;
                .pin-button,
                .bookmark-button {
                    flex: 0 0 calc(50% - 6px);
                    max-width: calc(50% - 6px);
                    width: calc(50% - 6px);
                    justify-content: center !important;
                }
                gap: 0;
            }
        }
        .pin-button {
            border: 1px solid #edf2f6;
            border-radius: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            height: 40px;
            width: 40px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            &.active {
                justify-content: space-around;
                width: auto;
                gap: 10px;
                padding: 0 20px 0 20px;
                border: 1px solid #abb6bd;
                @media (max-width: 767px) {
                    width: 50%;
                    flex: 0 0 50%;
                }
            }
            &:hover {
                border: 1px solid #ccd6dd;
                transition: all 400ms;
                cursor: pointer;
            }
        }
        .bookmark-button {
            border: 1px solid #edf2f6;
            border-radius: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            height: 40px;
            width: 40px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            &.active {
                width: auto;
                gap: 10px;
                padding: 0 20px 0 20px;
                transition: all 400ms;
                border: 1px solid #abb6bd;
            }

            &:hover {
                border: 1px solid #ccd6dd;
                transition: all 400ms;
                cursor: pointer;
            }
        }
    }
    .offer-card-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        &:nth-child(4) {
            @media (max-width: 767px) {
                //display: none;
            }
        }
        .offer-card-detail-title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 6px;
            @media (max-width: 767px) {
                .tooltip-offer-card {
                    position: relative;
                    .box {
                        position: static;
                        -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        filter: none;
                    }
                    .bubble-wrapper-tooltip {
                        left: -30px;
                        top: auto;
                        bottom: calc(100% + 20px);
                        &::before {
                            content: "";
                            bottom: -9px;
                            left: 25px;
                            position: absolute;
                            z-index: 2;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 10px 10px 0 10px;
                            border-color: #ffffff transparent transparent transparent;
                        }
                    }
                }
            }
            .offer-card-detail-title {
                white-space: nowrap;
                font-style: normal;
                font-weight: lighter;
                font-size: 14px;
                line-height: 20px;
                align-items: center;
                color: #6b717b;
            }
            .offer-card-detail-popup {
                cursor: pointer;
                margin: 0 5px 0 5px;
                width: 12px;
                height: 12px;
                @media (max-width: 767px) {
                    margin: -2px 0 0 0;
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .offer-card-detail-dots {
            border-bottom: 1px dashed #edf2f6;
            width: 100%;
            margin: 0 5px 0 5px;
            padding-left: 10px;
            padding-right: 8px;
        }
        .offer-card-detail-value-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .offer-card-detail-extra-text {
                white-space: nowrap;
                font-style: normal;
                font-weight: lighter;
                font-size: 14px;
                line-height: 24px;
                align-items: center;
                color: #6b717b;
                margin: 0 5px 0 5px;
            }
            .offer-card-detail-value {
                white-space: nowrap;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #151b26;
                @media (max-width: 440px) {
                    font-size: 16px;
                }
                @media (max-width: 374px) {
                    font-size: 14px;
                }
            }
        }
    }
}
.mobile-view-offer-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @media (max-width: $screen_size_marker_1) {
        justify-content: space-between;
    }

    gap: 16px;
    &.active {
        flex-direction: row;
        @media (min-width: $screen_size_marker_1) and (max-width: $screen_size_marker_3) {
            flex-direction: row;
        }
        @media (max-width: $screen_size_marker_1) {
            flex-direction: column;
        }
    }

    padding-right: 16px;

    @media (max-width: $screen_size_marker_1) {
        padding: 16px;
        gap: 10px;
    }
    @media (max-width: 767px) {
        padding: 16px;
    }
}
.bubble-wrapper-tooltip {
    margin-top: 27px;
    position: absolute;
    display: inline-block;
    width: fit-content;
    z-index: -1;
    transition: all 400ms;
    transform: translateY(-10px);
    opacity: 0;
    @media (max-width: 767px) {
        //overflow: hidden;
    }
    &.hover {
        z-index: 905;

        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }
}
.best-rate-bubble-wrapper-tooltip {
    position: absolute;
    margin-top: 27px;
    width: fit-content;

    opacity: 0;
    transform: translateY(-10px);
    z-index: -1;

    transition: all 400ms;
}
.offer-label:hover + .best-rate-bubble-wrapper-tooltip {
    pointer-events: auto;

    opacity: 1;
    transform: translateY(0);
    z-index: 905;
}

.smooth-entry-apply-arrow {
    font-size: 12px;
    margin-left: 0px;
    justify-content: center;
    transition: margin-left 0.4s ease-in-out;
    &-hover {
        font-size: 12px;
        margin-left: 5px;
        transition: margin-left 0.4s ease-in-out;
    }
}
.detail-tooltip-module {
    box-shadow: 0px 24px 64px 0px rgba(30, 37, 52, 0.1) !important;
    border-radius: 10px;
    width: auto;
    z-index: 999;
    &.show {
        opacity: 1;
    }
    .detail-tooltip-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 8px;
        padding-left: 2px;
        padding-right: 2px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 16px;
        gap: 4px;

        width: 278px;

        background: #ffffff;
        border-radius: 12px;
    }
}

.popup-container {
    position: fixed;
    top: 75vh;
    z-index: 999;
    width: 100%;
    justify-content: center;
    left: 0;
    @media (max-width: 767px) {
        top: auto;
        bottom: 40px;
    }
    .apply-message-popup-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 24px;
        gap: 16px;
        width: auto;
        height: auto;
        margin: 20px;
        background: #eff7ea;
        border: 2px solid #6db353;
        border-radius: 16px;
        @media (max-width: 767px) {
            padding: 16px;
            flex-direction: column;
            margin: 16px;
            position: relative;
        }
        .apply-message-popup-container-first-section {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 10px;
            @media (max-width: 767px) {
                gap: 8px;
                flex-direction: column;
                img {
                    margin-bottom: 8px;
                    max-width: 32px;
                    height: auto;
                }
            }
            .apply-message-content-container {
                display: flex;
                flex-direction: column;
                .apply-message-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    color: #151b26;
                    @media (max-width: 767px) {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .apply-message-subtitle {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #151b26;
                }
            }
        }
        .close-tip-modal-trigger {
            &:hover {
                cursor: pointer;
            }
            @media (max-width: 767px) {
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }
    }
}
