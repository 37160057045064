.incl-user-status {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9EA9B0;
}

.incl-user-box {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
}
