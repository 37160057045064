.new-request-menu {
    .menu-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 24px;
        margin-bottom: 22px;
        gap: 24px;

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 14px;
        }
    }

    .title {
        font-size: 48px;
        font-weight: 600;
        line-height: 56px;
        color: #ffffff;
        margin-bottom: 8px;

        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .subtitle {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #ffffff;
        margin-bottom: 24px;
        text-transform: none;
    }
}