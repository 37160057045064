.TextboxInput {
  font-size: 16px;
  border: unset;
  background: transparent;
  font-family: var(--mainFont);
}

.TextboxInput::placeholder {
  color: var(
    --neutral-300
  ); /* Set the desired color for the placeholder text */
}
