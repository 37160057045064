.interfaces-dashboard-wrapper {
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    overflow: hidden;
    height: 700px;
    width: 80%;
    // max-width: 1072px;
    max-width: 691px;
    // border: 1px solid rgba(21, 26, 37, 0.1);
    position: absolute;
    right: 0px;
    top: 57px;
    z-index: 999;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent;

    @media (max-width: 1024px) {
        top: 0px;
    }

    &.zero-fees-section {
        position: static;
        width: 100%;
        max-width: 1072px;
        background: transparent;
        margin: 0px auto;
        margin-top: 300px;
        height: 670px;
    }

    &.fixed-width {
        @media (min-width: 991px) {
            min-width: 1072px;
        }
        @media (max-width: 990px) {
            margin-right: unset;
        }
        @media (max-width: 575px) {
            margin-top: -210px;
            margin-bottom: -190px;
        }
    }
    &.interface-home {
        @media (max-width: 990px) {
            min-width: 930px;
            transform: scale(0.54) translate(15%, -42%);
            margin-top: unset;
            margin-bottom: unset;
        }
        @media (max-width: 767px) {
            transform: scale(0.54) translateY(-40%);
        }
    }
    &.crest-interface {
        @media (max-width: 990px) {
            margin-right: -18%;
        }
        @media (max-width: 767px) {
            margin-right: -2%;
        }
    }
}
.interfaces-dashboard-inner {
    display: flex;
    &.zero-fees-section {
        background: var(--neutralColor80);
        border-radius: 20px;
    }
}
.interfaces-dashboard {
    &-sidebar {
        flex: 0 0 178px;
        max-width: 178px;
        background-color: var(--whiteColor);
        padding: 30px 18px 60px 24px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-self: stretch;
        max-height: 670px;
        &__top {
            padding-left: 6px;
        }
        &__logo {
            font-size: 22px;
            line-height: 22px;
            color: #78bd54;
            margin-bottom: 34px;
        }
        &__menu {
            ul {
                list-style: none;
                margin: 0;
                padding-bottom: 18px;
                border-bottom: 1px var(--neutralColor200) solid;
            }
            li {
                display: flex;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: var(--primaryColor2);
                align-items: center;
                & + li {
                    margin-top: 22px;
                }
                span {
                    display: flex;
                    width: 18px;
                    height: 18px;
                    align-items: center;
                    justify-content: center;
                    color: var(--neutralColor400);
                    margin-right: 8px;
                    &.icon-dashboard {
                        font-size: 13.5px;
                    }
                    &.icon-dashboard-faq {
                        font-size: 16px;
                    }
                }
            }
        }
        &__steps {
            padding-top: 18px;
        }
        &__step {
            display: flex;
            align-items: center;
            // padding-bottom: 17px;
            padding-bottom: 14.54px;
            font-weight: 400;
            font-size: 8px;
            line-height: 12px;
            color: var(--neutralColor400);
            position: relative;
            &::before {
                content: "";
                width: 1px;
                height: 8.5px;
                background-color: var(--neutralColor100);
                position: absolute;
                left: 8.25px;
                margin-left: -0.5px;
                bottom: 4px;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }

            &.zero-fees-section {
                padding-bottom: 22.5px;
            }
            .icon-check {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16.5px;
                height: 16.5px;
                border-radius: 50%;
                border: 3px solid var(--neutralColor100);
                background-color: var(--neutralColor100);
                margin-right: 8px;
                color: transparent;
                font-size: 6.5px;
            }
            &-complete {
                color: rgba(var(--borrowerPrimaryColor), 1);
                &::before {
                    background-color: rgba(var(--borrowerPrimaryColor), 1);
                }
                .icon-check {
                    background-color: rgba(var(--borrowerPrimaryColor), 1);
                    border-color: rgba(var(--borrowerPrimaryColor), 1);
                    color: var(--whiteColor);
                }
            }
            &-current {
                color: var(--primaryColor2);
                .icon-check {
                    background-color: var(--whiteColor);
                    border-color: rgba(var(--borrowerPrimaryColor), 1);
                    opacity: 0.3;
                }
            }
        }
        &__user {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            &-ava {
                flex: 0 0 30px;
                width: 30px;
                height: 30px;
                border-radius: 6px;
                background-color: var(--primaryColor2);
                color: var(--whiteColor);
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
            }
            &-name {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: var(--primaryColor2);
                padding-left: 8px;
                flex: 0 0 calc(100% - 30px);
                max-width: calc(100% - 40px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        &__support {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: var(--primaryColor2);
            .icon-dashboard-chat {
                font-size: 14.25px;
                color: var(--neutralColor400);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }
    }
    &-content {
        // flex: 0 0 calc(100% - 178px);
        // max-width: calc(100% - 178px);
        background-color: var(--neutralColor80);
        padding: 30px;
        &__head {
            position: relative;
            padding-left: 35px;
            padding-right: 50px;
            margin-bottom: 18px;
            .icon-dashboard-arrow-left {
                position: absolute;
                left: 0;
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 6px;
                font-size: 14px;
                color: var(--primaryColor2);
            }
            .h2 {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                color: var(--primaryColor2);
                span {
                    color: rgba(var(--borrowerPrimaryColor), 1);
                }
            }
            p {
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: var(--neutralColor500);
            }
            .dots {
                display: flex;
                position: absolute;
                right: 0;
                top: 13.5px;
                span {
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: var(--primaryColor2);
                    & + span {
                        margin-left: 3px;
                    }
                }
            }
        }
        &__cards {
            display: flex;
            margin-left: -3px;
            margin-right: -3px;
            overflow: hidden;
            .col-3 {
                padding-left: 3px;
                padding-right: 3px;
                min-width: 90px;
                // flex: 0 0 25%;
                // max-width: 25%;
            }
            @media (max-width: 990px) {
                .col-3-ovr {
                    // flex: 0 0 50%;
                    max-width: 25%;
                }
            }
        }
        .interface-card {
            background-color: var(--whiteColor);
            border-radius: 6.558px;
            padding: 5.738px 8.197px;
            height: 100%;
            .interface-card-title {
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: var(--neutralColor500);
                margin-bottom: 6px;
                span {
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgba(var(--borrowerPrimaryColor), 1);
                    font-size: 13.5px;
                    margin-right: 6px;
                    &.icon-dashboard-search {
                        font-size: 13.5px;
                    }
                    &.icon-dashboard-anual-income {
                        font-size: 15px;
                    }
                    &.icon-dashboard-education {
                        font-size: 15px;
                    }
                    &.icon-dashboard-add-cosigner {
                        font-size: 13px;
                    }
                }
            }
            .interface-card-value {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: var(--primaryColor2);
                padding-right: 18px;
                position: relative;
                span {
                    width: 12px;
                    height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 9px;
                    color: var(--primaryColor2);
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0;
                    &::before {
                        display: block;
                    }
                }
            }
            &.interface-card__cosigner {
                .interface-card-value {
                    color: rgba(var(--borrowerPrimaryColor), 1);
                    span {
                        color: var(--whiteColor);
                        background-color: rgba(var(--borrowerPrimaryColor), 1);
                        width: 16px;
                        height: 16px;
                        font-size: 7px;
                        &::before {
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
        &__filters {
            display: flex;
            flex-direction: row;
            position: relative;
            border-radius: 6px;
            overflow: hidden;
            align-items: flex-start;
            margin-top: 10px;
            margin-bottom: 30px;
        }
        &__filter {
            padding: 8px;
            border-radius: 22px;
            border: 1px solid var(--neutralColor300);
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: var(--primaryColor2);
            white-space: nowrap;
            margin-right: 6px;
            span {
                height: 12px;
                width: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                font-size: 10px;
                &.icon-dashboard-satisfactory-academic-progress,
                &.icon-dashboard-term,
                &.icon-dashboard-lender,
                &.icon-dashboard-term,
                &.icon-dashboard-bookmark {
                    font-size: 12px;
                }
                &.icon-dashboard-rate-type,
                &.icon-dashboard-search {
                    font-size: 10.5px;
                }
                &.icon-refi,
                &.icon-dashboard-payment-plan {
                    font-size: 11.5px;
                }
            }
            &-scroll {
                width: 30px;
                height: 30px;
                background-color: var(--whiteColor);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: var(--primaryColor2);
                border-radius: 6px;
                border: 1px var(--neutralColor200) solid;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        &__charts {
            display: flex;
            margin-left: -4px;
            margin-right: -4px;
            overflow: hidden;
            .col {
                padding-left: 4px;
                padding-right: 4px;
                flex: 0 0 50%;
                // max-width: 50%;
            }
            &-cards {
                display: flex;
                margin-top: 8px;
            }
            &-card {
                padding: 6px 14px 6px 30px;
                position: relative;
                margin-right: 6px;
                border-radius: 7px;
                border: 1px var(--neutralColor300) solid;
                box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.09);
                span {
                    position: absolute;
                    left: 14px;
                    top: 6px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border-width: 4px;
                    border-style: solid;
                }
                .value {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    color: var(--primaryColor2);
                    margin-bottom: 1px;
                }
                .lender {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 12px;
                    color: var(--neutralColor500);
                }
            }
        }
        &__chart {
            &-head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
            }
            &-title {
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: var(--primaryColor2);
                min-height: 26px;
                display: flex;
                align-items: center;
                .icon-dashboard-tooltip {
                    width: 12px;
                    height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 9px;
                    margin-left: 6px;
                    color: var(--neutralColor300);
                }
            }
            &-tab {
                padding: 3px;
                display: flex;
                align-items: center;
                background-color: var(--whiteColor);
                font-size: 9px;
                line-height: 12px;
                font-weight: 400;
                color: var(--primaryColor2);
                border-radius: 10px;
                span {
                    padding: 4px 7px;
                    border-radius: 10px;
                    &.active {
                        padding: 4px 10px;
                        background-color: var(--neutralColor100);
                    }
                }
            }
            &-content {
                display: flex;
                flex-wrap: wrap;
                padding: 14px;
                border-radius: 8px;
                background-color: var(--whiteColor);
                height: 129px;

                &.zero-fees-section {
                    height: 152px;
                }
                &-left {
                    flex: 0 0 38px;
                    max-width: 38px;
                    span {
                        display: block;
                        font-size: 5.738px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 8.197px;
                        color: var(--neutralColor300);
                        & + span {
                            margin-top: 14px;
                        }
                    }

                    &.zero-fees-section {
                        span {
                            font-weight: 400;
                            font-size: 8px;
                            line-height: 10px;
                        }
                    }
                }
                &-right {
                    flex: 0 0 calc(100% - 38px);
                    max-width: calc(100% - 38px);
                    position: relative;
                    padding: 0 8px 0 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    img {
                        width: 100%;
                        height: 92px;
                        position: relative;
                        border-radius: 0;
                        box-shadow: none;
                    }
                    span {
                        width: 100%;
                        border-bottom: 0.58px var(--neutralColor200) dashed;
                        position: absolute;
                        left: 0;
                        &:nth-child(1) {
                            top: 4px;
                        }
                        &:nth-child(2) {
                            top: 26px;
                        }
                        &:nth-child(3) {
                            top: 48px;
                        }
                        &:nth-child(4) {
                            top: 70px;
                        }
                        &:nth-child(5) {
                            top: 92px;
                        }
                    }

                    &.zero-fees-section {
                        span {
                            &:nth-child(1) {
                                top: 4px;
                            }
                            &:nth-child(2) {
                                top: 29px;
                            }
                            &:nth-child(3) {
                                top: 52px;
                            }
                            &:nth-child(4) {
                                top: 77px;
                            }
                            &:nth-child(5) {
                                top: 100px;
                            }
                        }
                    }

                    .bar {
                        width: 24px;
                        height: 100%;
                        border-radius: 2px;
                        position: relative;
                        z-index: 1;
                        &-1 {
                            background-color: #6db353;
                            height: 61px;
                        }
                        &-2 {
                            background-color: #6177e8;
                            height: 77px;
                        }
                        &-3 {
                            background-color: #e6c414;
                            height: 85px;
                        }
                        &-4 {
                            background-color: #a461e8;
                            height: 90px;
                        }
                        &-5 {
                            background-color: #f19048;
                            height: 92px;
                        }
                    }
                    &.zero-fees-section {
                        .bar {
                            &-1,
                            &-2,
                            &-3,
                            &-4,
                            &-5 {
                                bottom: 23px;
                            }
                        }
                    }
                }
                &-bottom {
                    flex: 0 0 100%;
                    max-width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding-left: 32px;
                    font-size: 5.738px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 8.197px;
                    color: var(--neutralColor300);
                    margin-top: 6.56px;
                }
            }
        }
        &__offers {
            margin-top: 30px;
            &-head {
                position: relative;
                padding-right: 178px;
                .h3 {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--primaryColor2);
                    margin-bottom: 4px;
                    span {
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        line-height: 16px;
                        margin-right: 6px;
                        color: var(--orangeColor);
                    }
                }
                p {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    color: var(--neutralColor500);
                    display: flex;
                    align-items: center;
                    .icon-dashboard-tooltip {
                        margin: 0 6px;
                        font-size: 9px;
                        color: var(--neutralColor300);
                    }
                    .href {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: var(--primaryColor2);
                        text-decoration: underline;
                    }
                }
            }
            &-sort {
                position: absolute;
                right: 0;
                bottom: 0;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: var(--neutralColor500);
                display: flex;
                align-items: center;
                padding-right: 18px;
                &-selected {
                    margin-left: 6px;
                    font-weight: 500;
                    color: rgba(var(--borrowerPrimaryColor), 1);
                }
                .icon-arrow-bottom {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 8px;
                    color: rgba(var(--borrowerPrimaryColor), 1);
                }
            }
            .offer-card-interface {
                margin-top: 12px;
                display: flex;
                justify-content: space-between;
                padding: 10px 18px;
                align-items: center;
                background-color: var(--whiteColor);
                border-radius: 12px;
                .offer-card-interface-col {
                    &-logo {
                        width: 48px;
                        height: 36px;
                        padding-right: 12px;
                        img {
                            max-height: 100%;
                            max-width: 100%;
                            width: auto;
                            height: auto;
                            display: block;
                        }
                    }
                    .value {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: var(--primaryColor2);
                    }
                    .tern-type {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        color: rgba(var(--borrowerPrimaryColor), 1);
                    }
                    &-info {
                        padding-right: 9px;
                        display: flex;
                        align-items: center;
                    }
                    &-apr {
                        padding: 0 9px;
                    }
                    &-btns {
                        display: flex;
                        align-items: center;
                    }
                    .offer-card-interface-round {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        border: 1px var(--neutralColor100) solid;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        &__toggle {
                            background-color: var(--neutralColor100);
                            border-color: var(--neutralColor100);
                        }
                        & + .offer-card-interface-round {
                            margin-left: 12px;
                        }
                    }
                    .offer-card-interface-primary {
                        padding: 8px 18px;
                        border-radius: 22px;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        background-color: rgba(var(--borrowerPrimaryColor), 1);
                        color: var(--whiteColor);
                        margin-left: 12px;
                        margin-right: 12px;
                    }
                    .offer-card-interface-title {
                        display: flex;
                        align-items: flex-start;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 16px;
                        color: var(--neutralColor500);
                        .icon-dashboard-tooltip {
                            margin-left: 3px;
                            margin-top: 1px;
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
}
.interfaces-dashboard-wrapper {
    &-small {
        height: 310px;
        box-shadow: 0px 24px 40px rgba(21, 26, 37, 0.1);
        border-radius: 12px;
        margin-bottom: 12px;
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 60px;
            background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 122.55%);
            bottom: 0;
            left: 0;
            position: absolute;
        }
        .interfaces-dashboard-content__head,
        .interfaces-dashboard-content__cardInfo,
        .interfaces-dashboard-sidebar {
            display: none;
        }
        .interfaces-dashboard-content {
            flex: 0 0 100%;
            height: 310px;
            max-width: 100%;
            background-color: var(--whiteColor);
        }
        .interfaces-dashboard-content__chart-head {
            margin-bottom: 6px;
        }
        .interfaces-dashboard-content__chart-title {
            font-weight: 500;
            font-size: 10px;
            line-height: 10px;
            min-height: 13px;
            .icon-dashboard-tooltip {
                font-size: 8px;
                margin-left: 5px;
                line-height: 10px;
            }
        }
        .interfaces-dashboard-content__chart-tab {
            font-weight: 400;
            font-size: 6px;
            line-height: 8px;
            padding: 0;
            span {
                padding: 2.5px 6.5px;
            }
        }
        .interfaces-dashboard-content__chart-content {
            background-color: var(--neutralColor50);
            border-radius: 6px;
            padding: 9px;
            height: 106px;
        }
        .interfaces-dashboard-content__chart-content-left {
            max-width: 30px;
            span {
                font-weight: 400;
                font-size: 6px;
                line-height: 8px;
                & + span {
                    margin-top: 8.5px;
                }
            }

            &.zero-fees-section {
                font-size: 8px;
                font-style: normal;
                font-weight: 400;
                line-height: 10px;
            }
        }
        .interfaces-dashboard-content__chart-content-right {
            max-width: calc(100% - 30px);
            padding: 0 8px;
            .bar {
                width: 15px;
                &-1 {
                    height: 42px;
                }
                &-2 {
                    height: 53px;
                }
                &-3 {
                    height: 58px;
                }
                &-4 {
                    height: 62px;
                }
                &-5 {
                    height: 63px;
                }
            }
            span {
                &:nth-child(1) {
                    top: 8px;
                }
                &:nth-child(2) {
                    top: 24.5px;
                }
                &:nth-child(3) {
                    top: 41px;
                }
                &:nth-child(4) {
                    top: 57.5px;
                }
                &:nth-child(5) {
                    top: 74px;
                }
            }
            img {
                height: 64px;
            }
        }
        .interfaces-dashboard-content__chart-content-bottom {
            font-size: 6px;
            line-height: 8px;
        }
        .interfaces-dashboard-content__charts-card {
            padding: 4px 8px 4px 19px;
            border-radius: 4.42302px;
            border-width: 0.368585px;
            span {
                top: 4px;
                left: 8px;
                width: 7.5px;
                height: 7.5px;
                border-width: 2px;
            }
            .value {
                font-weight: 500;
                font-size: 6px;
                line-height: 8px;
            }
            .lender {
                font-weight: 400;
                font-size: 5.63309px;
                line-height: 8px;
            }
        }
        .interfaces-dashboard-content__charts-cards {
            margin-top: 6px;
        }
        .interfaces-dashboard-content__offers {
            margin-top: 7px;
            &-head {
                padding-right: 115px;
                .h3 {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 12px;
                    margin-bottom: 4px;
                    span {
                        font-size: 11px;
                        margin-right: 5px;
                    }
                }
                p {
                    font-weight: 400;
                    font-size: 8px;
                    line-height: 10px;
                    .icon-dashboard-tooltip {
                        margin: 0 3.5px;
                        font-size: 8px;
                    }
                    .href {
                        font-size: 8px;
                        line-height: 10px;
                    }
                }
            }
            .offer-card-interface {
                padding: 8px 12px;
                background-color: var(--neutralColor50);
                border-radius: 7.5px;
                margin-top: 6px;
                .offer-card-interface-col-logo {
                    width: 30px;
                    height: 22px;
                    max-width: 30px;
                    padding-right: 8px;
                }
                .offer-card-interface-col {
                    .value {
                        font-weight: 500;
                        font-size: 8px;
                        line-height: 10px;
                        margin-bottom: 0;
                    }
                    .tern-type {
                        font-weight: 400;
                        font-size: 6px;
                        line-height: 8px;
                    }
                    .offer-card-interface-title {
                        font-weight: 400;
                        font-size: 6px;
                        line-height: 10px;
                        .icon-dashboard-tooltip {
                            font-size: 6px;
                        }
                    }
                    .offer-card-interface-round {
                        width: 18px;
                        height: 18px;
                        border-width: 0.5px;
                        font-size: 7px;
                        & + .offer-card-interface-round {
                            margin-left: 8px;
                        }
                    }
                    .offer-card-interface-primary {
                        font-size: 6.25899px;
                        line-height: 8px;
                        padding: 6px 12px;
                        margin-left: 8px;
                        margin-right: 8px;
                    }
                }
            }
        }
        .interfaces-dashboard-content__offers-sort {
            font-size: 8px;
            line-height: 12px;
            padding-right: 11px;
            .icon-arrow-bottom {
                font-size: 5.5px;
            }
        }
    }
    &-medium {
        height: 531px;
        @media (max-width: 1270px) {
            // min-width: 777px;
            // float: right;
            // transform: scale(0.85, 0.85);
            // margin-right: -12%;
        }
        // @media (max-width: 990px) {
        //     transform: scale(0.7, 0.7);
        // }
        // @media (max-width: 840px) {
        //     transform: scale(0.62, 0.62);
        //     margin-right: -33%;
        // }
        // @media (max-width: 767px) {
        //     transform: scale(0.62, 0.62);
        //     margin-right: auto;
        //     margin-left: auto;
        //     float: unset;
        // }
        .interfaces-dashboard-inner {
            height: 100%;
            width: 1000px;
            overflow: hidden;

            &.zero-fees-section {
                width: 100%;
                max-width: 100%;
            }
        }
        .interfaces-dashboard-sidebar {
            padding: 23.5px 14px 23.5px 18px;
            height: 100%;
            flex: 0 0 141px;
            max-width: 141px;

            @media (max-width: 990px) {
                padding-left: 0px;
            }

            &.zero-fees-section {
                width: 172px;
                max-width: 100%;
                padding-left: 23.5px;
            }
        }
        .interfaces-dashboard-sidebar__logo {
            font-size: 18px;
            margin-bottom: 28px;
        }
        .interfaces-dashboard-sidebar__menu {
            padding-bottom: 14px;
            li {
                font-size: 6.558px;
                font-style: normal;
                font-weight: 500;
                line-height: 8.197px;
                & + li {
                    margin-top: 18px;
                }
                span {
                    width: 14px;
                    height: 14px;
                    margin-right: 7px;
                    font-size: 10px;
                }
            }
        }
        .interfaces-dashboard-sidebar__steps {
            padding-top: 14px;
        }

        .interfaces-dashboard-content {
            padding: 24px 24px 24px 16px;
            width: 600px;
            overflow: hidden;

            &.zero-fees-section {
                width: 100%;
                padding: 30px;
            }
        }
        .interfaces-dashboard-content__head {
            padding-left: 28px;
            margin-bottom: 11.48px;

            &.zero-fees-section {
                margin-bottom: 18px;
            }
            p {
                font-size: 6.558px;
                font-style: normal;
                font-weight: 400;
                line-height: 9.836px;

                &.zero-fees-section {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 14px;
                }
            }
            .h2 {
                font-size: 14.754px;
                font-style: normal;
                font-weight: 600;
                line-height: 19.673px;

                &.zero-fees-section {
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px;
                }
            }
        }
        .interfaces-dashboard-content__head .icon-dashboard-arrow-left {
            width: 14px;
            height: 14px;
            font-size: 11px;
        }
        .interface-card {
            .interface-card-value {
                font-size: 8.197px;
                font-style: normal;
                font-weight: 500;
                line-height: 11.476px;

                &.zero-fees-section {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                }
            }
            .interface-card-title {
                margin-bottom: 5px;
                span {
                    width: 14px;
                    height: 14px;
                }
                font-size: 6.558px;
                font-style: normal;
                font-weight: 400;
                line-height: 9.836px;

                &.zero-fees-section {
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 14px;
                }
            }
        }
        .interfaces-dashboard-content__filters {
            margin-bottom: 24px;
        }
        .interfaces-dashboard-content__filter {
            font-size: 8px;
            line-height: 9px;
            font-weight: 500;
            padding: 6px;
            border-radius: 18px;
            span {
                width: 10px;
                height: 10px;
                font-size: 10px;
            }
        }
        .interfaces-dashboard-content__filter-scroll {
            width: 24px;
            height: 24px;
            font-size: 10px;
        }
        .interfaces-dashboard-content__chart-title {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            min-height: 19.5px;
            .icon-dashboard-tooltip {
                margin-left: 5px;
                font-size: 8px;
            }
        }
        .interfaces-dashboard-content__chart-tab {
            padding: 2px;
            border-radius: 9.5px;
            font-weight: 400;
            font-size: 7.06667px;
            line-height: 9px;
            span {
                padding: 2.3px 7px;
                &.active {
                    box-shadow: 0px 11.7778px 35.3333px rgba(30, 37, 52, 0.1);
                }
            }
        }
        .interfaces-dashboard-content__charts-card {
            padding: 4px 14px 4px 25px;
            .value {
                font-weight: 500;
                font-size: 8px;
                line-height: 12px;
                margin-bottom: 4px;
            }
            .lender {
                font-weight: 400;
                font-size: 7px;
                line-height: 12px;
            }
            span {
                left: 7px;
                top: 4px;
                width: 12px;
                height: 12px;
                border-width: 3.5px;
            }
        }
        .interfaces-dashboard-content__offers-head {
            .h3 {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 4px;
                span {
                    width: 12px;
                    height: 12px;
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
            p {
                font-size: 8px;
                line-height: 12px;
                .icon-dashboard-tooltip {
                    margin: 0 4px;
                }
                .href {
                    font-size: 8px;
                    line-height: 12px;
                }
            }
        }
        .interfaces-dashboard-content__offers-sort {
            font-size: 9px;
            line-height: 14px;
        }
        .interfaces-dashboard-sidebar__support {
            font-size: 8px;
            line-height: 10px;
        }
        .interfaces-dashboard-content__offers {
            margin-top: 24px;
            .offer-card-interface {
                .offer-card-interface-col {
                    .value {
                        font-size: 10px;
                        line-height: 14px;
                    }
                    .offer-card-interface-title {
                        font-size: 8px;
                        line-height: 12px;
                    }
                }
            }
        }
    }
    &-crest {
        .interfaces-dashboard-sidebar__logo {
            color: #3c4ea9;
        }
        .interfaces-dashboard-sidebar__step-complete .icon-check {
            background-color: #3c4ea9;
            border-color: #3c4ea9;
        }
        .interfaces-dashboard-sidebar__step-complete::before {
            background-color: #3c4ea9;
        }
        .interfaces-dashboard-sidebar__step-current .icon-check {
            border-color: #3c4ea9;
        }
        .interfaces-dashboard-content__head .h2 {
            span {
                color: #3c4ea9;
            }
        }
        .interface-card.interface-card__cosigner .interface-card-value,
        .interfaces-dashboard-content .interface-card .interface-card-title span,
        .interfaces-dashboard-sidebar__step {
            color: #3c4ea9;
        }
        .interface-card.interface-card__cosigner .interface-card-value span {
            background-color: #3c4ea9;
        }
    }
    &-main {
        padding: 12px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.24) 0%,
            rgba(var(--borrowerPrimaryColor), 0.6) 100%
        );
        border: 1px solid rgba(242, 244, 245, 0.5);
        border-right: transparent;
        backdrop-filter: blur(28px);
        // height: 551px;
        height: 434.437px;
        @media (max-width: 1270px) {
            // transform: scale(0.9, 0.9);
            // min-width: 450px;

            //margin-left: -13%;
            // margin-top: -5%;
            // margin-right: 14%;
        }
        @media (max-width: 990px) {
            right: 0px;
            width: 100%;
            // transform: scale(0.95, 0.95);
        }

        &.zero-fees-section {
            padding: 0px;
            background: transparent;
            border: none;
            backdrop-filter: none;
            border-radius: 20px;
        }
        .interfaces-dashboard-sidebar {
            background-color: transparent;
            &.zero-fees-section {
                background: #fff;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
        .interfaces-dashboard-content {
            border-radius: 20px;

            &.zero-fees-section {
                border-radius: 0px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
        .interfaces-dashboard-sidebar__logo {
            color: var(--whiteColor);
        }
        .interfaces-dashboard-sidebar__step-complete .icon-check {
            background-color: var(--whiteColor);
            border-color: var(--whiteColor);
            color: rgba(var(--borrowerPrimaryColor), 1);

            &.zero-fees-section {
                border-color: rgba(var(--borrowerPrimaryColor), 1);
                color: #fff;
            }
        }
        .interfaces-dashboard-sidebar__step-complete::before {
            background-color: var(--whiteColor);
        }
        .interfaces-dashboard-sidebar__step-current .icon-check {
            border-color: var(--whiteColor);
            opacity: 1;
            background-color: transparent;
        }
        .interfaces-dashboard-content__offers {
            margin-top: 14px;
        }
        .interfaces-dashboard-sidebar__menu {
            border-bottom-color: #ccd6dd;
            li {
                color: var(--whiteColor);
                &.zero-fees-section {
                    color: var(--mainColor);
                    font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                }
                span {
                    color: var(--whiteColor);
                    &.zero-fees-section {
                        color: var(--mainColor);
                        
                    }
                }
            }
        }
        .interfaces-dashboard-sidebar__step {
            color: rgba(var(--whiteColorRGB), 0.6);

            &.zero-fees-section {
                color: var(--mainColor);
            }

            &::before {
                background-color: rgba(var(--whiteColorRGB), 0.6);
            }
            &.zero-fees-section::before {
                background: var(--neutralColor100);
            }

            .icon-check {
                background-color: rgba(var(--whiteColorRGB), 0.6);
                border-color: transparent;

                &.zero-fees-section {
                    background: var(--neutralColor100);
                }
            }
        }
        .interfaces-dashboard-sidebar__step-current {
            .icon-check {
                border-color: var(--whiteColor);
                background-color: transparent;

                &.zero-fees-section {
                    border-color: rgba(var(--borrowerPrimaryColor), 0.3);
                    background-color: transparent;
                }
            }
        }
        .interfaces-dashboard-sidebar__step-current,
        .interfaces-dashboard-sidebar__step-complete {
            color: var(--whiteColor);

            &.zero-fees-section {
                color: var(--mainColor);
                background-color: transparent;
                &.interfaces-dashboard-sidebar__step-complete::before{
                    background: rgba(var(--borrowerPrimaryColor), 1);
                }
            }
        }
        .interfaces-dashboard-sidebar__step-complete {
            .icon-check {
                background-color: var(--whiteColor);
                border-color: var(--whiteColor);
                &.zero-fees-section {
                    background: rgba(var(--borrowerPrimaryColor), 1);
                }
            }
        }
        .interfaces-dashboard-sidebar__user {
        }
        .interfaces-dashboard-sidebar__user-ava {
            background-color: var(--whiteColor);
            color: var(--mainColor);
            &.zero-fees-section {
                color: #fff;
                background-color: var(--mainColor);
            }
        }
        .interfaces-dashboard-sidebar__user-name {
            color: var(--whiteColor);
            &.zero-fees-section {
                color: var(--mainColor);
            }
        }
        .interfaces-dashboard-sidebar__support {
            color: var(--whiteColor);
            &.zero-fees-section {
                color: var(--mainColor);
            }
            .icon-dashboard-chat {
                color: var(--whiteColor);
                &.zero-fees-section {
                    color: var(--mainColor);
                }
            }
        }
    }
    &-default {
        @media (max-width: 990px) {
            min-width: 930px;
            transform: scale(0.74, 0.74);
            margin-top: -8%;
            margin-bottom: -6%;
        }
        @media (max-width: 767px) {
            transform: scale(0.54, 0.54);
            margin-top: -23%;
            margin-bottom: -18%;
        }
        @media (max-width: 585px) {
            transform: scale(0.4, 0.4);
            margin-top: -220px;
            margin-bottom: -160px;
        }
    }
    &-slider {
        min-width: 565px;
        @media (max-width: 1270px) {
            transform: scale(0.8);
            margin-top: -7%;
            margin-left: -13%;
            margin-bottom: -4%;
        }
        @media (max-width: 990px) {
            transform: scale(0.57);
            margin-top: -19%;
            margin-left: -121px;
            margin-bottom: -14%;
        }
        @media (max-width: 767px) {
            transform: scale(0.57);
            margin-top: -80px;
            margin-left: -121px;
            margin-bottom: -45px;
        }
    }
}
.section-has-custom-bg .section-inner.graduate {
    .interfaces-col-slide {
        padding-right: 15px;
        @media (max-width: 1270px) {
            max-width: 50%;
        }
        @media (max-width: 990px) {
            max-width: 360px;
        }
        @media (max-width: 767px) {
            padding-right: 0;
            max-width: 100%;
            .interfaces-dashboard-wrapper-small {
                max-width: 320px;
            }
            .benefit-card--advanced {
                .benefit-card-icon {
                    max-width: 320px;
                    height: auto;
                }
            }
        }
        @media (max-width: 575px) {
            max-width: 100%;
        }
        //flex: 0 0 141px;
        //max-width: 141px;
    }
}
