.vertical-lines {
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  .container {
    position: relative;
    height: 100%;
  }
  .line {
    position: absolute;
    top: 0;
    height: 100%;
    border-left: 1px dashed rgba(var(--borrowerPrimaryColor), 1);
    opacity: .1;
    width: 0;
    &.line1 {
      left: 14.5px;
      border-left-style: solid;
    }
    &.line5 {
      right: 15px;
      border-left-style: solid;
    }
    &.line2 {
      left: calc(25% + 7px);
      @media (max-width: 990px) {
        display: none;
      }
    }
    &.line3 {
      left: calc(50% - .5px);
      @media (max-width: 767px) {
        display: none;
      }
    }
    &.line4 {
      right: calc(25% + 7px);
      @media (max-width: 990px) {
        display: none;
      }
    }
  }
  &.vertical-lines-light {
    .line {
      border-left-color: var(--whiteColor);
    }
  }
  &.lines-interfaces {
    .line {
      &.line1 {
        top: 216px;
        @media (max-width: 1200px) {
          top: 209px;
        }
        @media (max-width: 990px) {
          top: 207px;
        }
        @media (max-width: 990px) {
          top: 50px;
        }

      }
      &.line2 {
        top: 200px;
      }
      &.line3 {
        top: 200px;
      }
      &.line4 {
        top: 200px;
      }
      &.line5 {top: 153px}
      @media (max-width: 1200px) {
        top: 161px;
      }
      @media (max-width: 990px) {
        top: 188px;
      }
    }
  }
  &.lines-loan-onboard {
    .line {
      &.line1 {
        top: 54px;
        height: calc(100% - 44px);
        @media (max-width: 1200px) {
          top: 50px;
          height: calc(100% - 49px);
        }
        @media (max-width: 990px) {
          top: 34px;
          height: calc(100% - 57px);
        }
      }
      &.line2 {
        top: 38px;
        height: calc(100% - 45px);
      }
      &.line3 {
        top: 22px;
        height: calc(100% - 45px);
        @media (max-width: 990px) {
          top: 15px;
          height: calc(100% - 25px);
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      &.line4 {
        top: 8px;
        height: calc(100% - 46px);
      }
      &.line5 {
        top: -8px;
        height: calc(100% - 44px);
        @media (max-width: 1200px) {
          top: 0;
          height: calc(100% - 48px);
        }
        @media (max-width: 990px) {
          top: 21px;
          height: calc(100% - 53px);
        }
      }

    }
  }
}