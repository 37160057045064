@import "../../../../../../../assets/styles/screen-size-variables.scss";

.TipCardsContainer {
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.TipCard {
    display: flex;
    flex-direction: column;
    background: hsla(0, 0%, 100%, 0.2);
    border-radius: 16px;
    width: 100%;
}

.TipsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5.04px;
    background: white;
    border-radius: 7.556px; 
}

.TipCardHeader {
    display: flex;
    align-items: center;
    padding: 12px 24px 12px 12px;
    border: 2px solid rgba(255, 255, 255, 0.26);
    border-radius: 16px 16px 0px 0px;
    background: rgba(255, 255, 255, 0.2);
    gap: 7.55px;

    span {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--invertedColor);
    }
}

.TipCardContent {
    padding: 24px;
    span {
        color: var(--invertedColor);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}
