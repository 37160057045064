@import "../../../assets/styles/colors";
@import "../../../assets/styles/screen-size-variables.scss";

$navigationWidth: 239px;
$navigationPopupWidth: 347px;
$navigationPadding: 40px;

.menu-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($mainColor, 0.5);
    z-index: 12;
}
.left-navigation-popup {
    display: none;
    position: fixed;
    width: $navigationPopupWidth;
    padding: $navigationPadding;
    margin-left: $navigationWidth;
    height: 100vh;
    flex-direction: column;
    z-index: 14;
    background-color: $whiteColor;
    box-shadow: 0px 0px 0px 0px rgba(27, 42, 54, 0.1), 5px 0px 12px 0px rgba(27, 42, 54, 0.1),
        22px 0px 22px 0px rgba(27, 42, 54, 0.09), 49px 0px 30px 0px rgba(27, 42, 54, 0.05),
        88px 0px 35px 0px rgba(27, 42, 54, 0.01), 137px 0px 38px 0px rgba(27, 42, 54, 0);

    &.active {
        @media (max-width: $tablet-width) {
            margin-left: 304px;
        }

        @media (max-width: $desktop-width) and (min-width: $tablet-width) {
            display: flex;
        }
    }
}
.left-navigation-bar-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(#151b26, 0.5);
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms;
    &.active {
        @media (max-width: $desktop-width) {
            opacity: 1;
            pointer-events: auto;
        }
    }
}
.left-navigation-bar {
    overflow: auto;
    min-width: $navigationWidth;
    padding: $navigationPadding;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: $whiteColor;
    @media (max-width: $tablet-width) {
        position: fixed;
        max-width: 304px;
        min-height: var(--viewport-height);
        &-full-width {
            max-width: 100%;
        }
    }

    @media (max-width: $desktop-width) {
        height: 100vh;
        position: fixed;
        left: -340px;
        transition: left 0.5s ease;
        padding: 45px 20px 40px;
        z-index: 13;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        &.active {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 13;
        }
    }

    @media (max-width: $tablet-width) {
        position: fixed;
        top: 0;
        width: 100%;
        left: -100%;
        z-index: 999 !important;
        padding: 40px 16px;
        &.active {
            left: 0;
            z-index: 999;
        }
    }
    &_toggler_wrapper {
        display: none;

        @media (max-width: 1200px) {
            display: block;
        }
    }
}

.line {
    height: 0px;
    width: 100%;

    border-bottom: 0.5px solid #edf2f6;
}
