@import "../../../../assets/styles/colors";

.request-popup-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: flex-start;
    .request-popup-title-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: flex-start;
        .request-popup-title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: left;
            color: #151b26;
        }
        .request-popup-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #878f99;
        }
    }
    .request-card-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        white-space: nowrap;
        .request-card-name {
            width: 223px;
        }
        cursor: pointer;
    }

    .request-popup-item {
        height: 24px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;

        color: $mainColor;
        transition: all 400ms;

        &:hover {
            color: $greenColor;
        }
    }
}
