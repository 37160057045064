@import "../../../../assets/styles/screen-size-variables.scss";

$left-side-width-ultra-wide: 560px;
$left-side-width-desktop: calc(($left-side-width-ultra-wide / $ultra-wide-width) * $desktop-width);

.container {
    display: flex;
    flex-direction: column;
    position: relative;
 
}

.primaryContentContainer {
    display: flex;
    height: 100%;

    @media (max-width: $desktop-width) {
    position: relative;
    }
}

.leftSideContainer {
    display: flex;
    transition: all 400ms;
    // if less than 1024px, then the left side will be hidden unless the menu is open
    @media (max-width: $desktop-width) {
        display: none;
    }
}

.leftSideContainerMobile{
    display: none;
    @media (max-width: $desktop-width) {
        display: flex;
        position: absolute;
        // top: -95px;
        left: -9999px;
        z-index: 9999;
        width: 100%;
        height: 100%;
        
        &.open {
            left: 0px;

            .blurredScreen {
                width: 100%;
                opacity: 0.4;
                background: #151b26;
            }
        }

        &.marketplace{
            height: 100%;
        }
        
    }
}

.leftSideInner {
    display: flex;
    flex-direction: column;
    padding: 80px 18px 64px 18px;
    min-width: 275px;
    max-width: $left-side-width-ultra-wide;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../assets/images/backgrounds/borrower-bg.png");
    
    @media (max-width: $tablet-width) {
        padding: 40px;
        width: 512px;
    }
    
    @media (min-width: $desktop-width) {
        padding: 80px 64px 64px 64px;
        min-width: $left-side-width-desktop;
    }
    
    @media (min-width: $ultra-wide-width) {
        padding: 80px 64px 64px 64px;
        min-width: $left-side-width-ultra-wide;
    }

    @media (max-width: 512px){
        width: 304px;
        padding: 40px 16px;
    }

    &.paaas{
        background-image: none;
        background: rgba(var(--borrowerPrimaryColor), 1);
    }
    
    &.borrower {
        &.paaas{
            background-image: none;
            background: rgba(var(--borrowerPrimaryColor), 1);
        }
    }

    &.cosigner {
        background-image: url("../../../../assets/images/backgrounds/cosigner-bg.png");
        &.paaas{
            background-image: none;
            background: rgba(var(--cosignerPrimaryColor), 1);
        }
    }
 
}

.leftSideInnerTop {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    gap: 24px;

    @media(min-width: 1025px){
        display: none;
    }
}

.rightSideContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0px auto;
    min-height: 100vh;
}

.rightSideTop {
    background: #f2f4f5;
    padding: 64px 64px 10px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 46px;


    @media (max-width: $tablet-width) {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }

    @media (max-width: 560px){
        padding-right: 16px;
        padding-left: 16px;
    }

    @media (max-width: $mobile-width) {
        flex-direction: column;
        gap: 41px;
        margin-bottom: 31px;
        padding-top: 32px;
    }
}

.rightSideContent{
    padding: 0px 64px 64px 64px;
    display: flex;
    flex-direction: column;

    @media (max-width: 560px){
        padding-right: 16px;
        padding-left: 16px;
    }

    @media (max-width: $mobile-width) {
        min-height: 100vh;
    }
}

.dashboardButton {
    all: unset;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    svg {
        transition: transform 0.3s ease;
    }

    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.5;

        svg {
            transform: translateX(-5px);
        }
    }
}

.rightSideHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
}

.textTitle {
    all: unset;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: var(--mainColor);
    font-family: var(--titleFont);
}

.textSubtitle {
    all: unset;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--darkGreyColor);
}

.rightSideBody {
    // This is important so that the ToastPopup takes the width of this container
    position: relative;
}