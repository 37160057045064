@import "src/assets/styles/colors";

.radio-button {
    background: $whiteColor;
    padding: 16px 24px 16px 16px;
    margin-bottom: 16px;
    border-radius: 16px;
    opacity: 0.6;

    &.isActive {
        &-borrower {
            opacity: 1;

            border: 2px solid rgba(var(--borrowerPrimaryColor), 1);
        }

        &-cosigner {
            opacity: 1;

            border: 2px solid rgba(var(--cosignerPrimaryColor), 1);
        }
    }



    div.default-label {
        padding: 4px 8px;
        background: #edf2f6;
        border-radius: 12px;
        width: fit-content;
        height: fit-content;

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #6b717b;
        }
    }
    
    .radio-button-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .radio-button-input {
            position: absolute;
            opacity: 0;
            right: 0px;

            + label {
                position: relative;
                cursor: pointer;
                width: 100%;

                p {
                    color: #abb6bd;
                }
            }

            &:checked + label {
                p {
                    color: $mainColor;
                }
            }
        }

        div.rb-text-box {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-left: 16px;

            p.rb-title {
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;

                @media (max-width: 560px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            p.rb-subtitle{
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #878F99,

            }
        }
    }
}
