.page-wrapper {
    background: #fff;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

.home-container {
    position: relative;
}
