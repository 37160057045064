@import "../../../assets/styles/colors";

.onboard-home__head {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show-notification {
    display: flex;
    font-size: 20px;
    color: #c7c9cc;
    background: #fff;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    position: relative;
    z-index: 11;

    &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px #f2f4f5 solid;
        position: absolute;
        right: -2px;
        top: -2px;
        background: #ff2631;
        transition: all 400ms;
        opacity: 0;
    }
    &.show-notification-has-unread {
        &::before {
            opacity: 1;
        }
    }
    &.active {
        z-index: 15;
    }
}

.tablet-notification-wrapper {
    position: absolute;
    right: 40px;
    top: 30px;
    &.active {
        z-index: 15;
    }
    @media (max-width: 767px) {
        top: 45px;
        right: 20px;
    }
}

.tablet-notification-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(#1e2534, 0.5);
    &.active {
        z-index: 14;
    }
}

.tablet-notification {
    position: absolute;
    z-index: 11;
    right: 0;
    top: 100%;
    width: 335px;
    margin-top: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 16px;

    @media (max-width: 370px) {
        width: calc(100vw - 40px);
    }
    &.active {
        z-index: 16;
    }
    .notification-item {
        background: #f2f4f5;
    }
    .small-link {
        display: block;
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        font-weight: 500;
    }
}

.card-btn-group {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    .card-btn {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        border: none;
        background: rgba(#fff, 0.5);
        border-radius: 8px;
        color: #fff;
        transition: all 400ms;
        cursor: pointer;
        padding: 0;
        justify-content: center;
        span {
            pointer-events: none;
        }
        &:hover {
            color: #1e2534;
            background: #fff;
        }
        & + .card-btn {
            margin-left: 8px;
        }
        &.card-delete {
            &:hover {
                color: red;
            }
        }
    }
}

.onboard-home__notifications {
    display: none;
    width: 430px;
    padding: 40px;
    height: 100vh;
    min-height: -webkit-fill-available;
    overflow: auto;
    top: 0;
    right: 0;
    background-color: $whiteColor;

    @media (min-width: 1201px) {
        display: block;
    }

    @media (max-width: 1300px) {
        width: 370px;
    }

    &--mobile {
        display: none;
        @media (max-width: 1200px) {
            display: block;
        }
    }

    h2 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0 !important;
    }

    // @media (max-width: 1200px){
    //   //position: relative;
    //   //height: unset;
    //   //top: unset;
    //   //right: unset;
    //   //width: 100%;
    //   //background-color: transparent;
    //   //padding: 0 40px 0 280px;
    // }
    // @media (min-width: 767px) and (max-width: 1024px){
    //   display: flex;
    //   flex-wrap: wrap;
    //   padding: 0 40px 40px;
    // }
    // @media (max-width: 767px){
    //   padding: 0 20px 20px;

    // }
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    .notification-card {
        background-image: $orangeGradient;
        margin-bottom: 24px;
        border-radius: 16px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
        z-index: 1;
        @media (min-width: 767px) and (max-width: 1024px) {
            order: 2;
            width: calc(50% - 16px);
        }
        &:before {
            content: "";
            background-image: url("../../../assets/images/bird.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            z-index: -1;
        }
        .progress {
            width: 80px;
            height: 80px;
            background-image: url("../../../assets/images/orange-round.svg");
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-bottom: 16px;
            p {
                font-weight: bold;
                font-size: 32px;
                line-height: 32px;
                color: #fafafa;
                span {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    opacity: 0.8;
                    margin-left: 2px;
                }
            }
            svg {
                position: absolute;
                right: 0;
                height: 78px;
            }
        }
        h4 {
            font-weight: 600;
            margin-bottom: 8px;
            color: $whiteColor;
        }
        .little {
            opacity: 0.8;
            color: $whiteColor;
        }
    }
    .h2-app {
        margin-bottom: 16px;
    }
}

.notification-item {
    background: $lightColor;
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 20px 16px;
    display: flex;
    @media (min-width: 992px) and (max-width: 1200px) {
        background: $whiteColor;
    }
    &:last-child {
        margin-bottom: 0;
    }
    img {
        margin-right: 16px;
        width: 40px;
        height: 40px;
        object-fit: cover;
    }

    .item-image {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        max-width: 40px;
        border-radius: 12px;
        background: -o-linear-gradient(195.4deg, #8cce56 0, #57a051 100%);
        background: linear-gradient(254.6deg, #8cce56 0, #57a051 100%);
        color: #fff;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.reviewed {
            background: rgba(30, 37, 52, 0.1) !important;
            color: rgba(30, 37, 52, 0.1) !important;

            &::before {
                display: none;
            }
        }

        &::before {
            content: "";
            width: 6px;
            height: 6px;
            border: 2px solid #f2f4f5;
            background: #ff2631;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            position: absolute;
            right: -3px;
            top: -3px;
        }
    }

    .item-content {
        padding-left: 15px;
        width: 100%;
        &__top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 4px;
            h5 {
                padding-right: 10px;
            }
            .time {
                font-size: 12px;
                line-height: 16px;
                min-width: 85px;
                text-align: right;
            }
        }
    }
}

.notifications-mobile-block {
    display: none;
    @media (max-width: 991px) {
        &.active {
            display: block;
            position: fixed;
            z-index: 11;
            top: 145px;
            background: $whiteColor;
            box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
            border-radius: 16px;
            padding: 20px;
            width: calc(100% - 40px);
            left: 20px;
            .notification-item {
                background: $lightColor;
                border-radius: 12px;
                margin-bottom: 10px;
                padding: 15px;
                display: flex;
                &:last-child {
                    margin-bottom: 0;
                }
                img {
                    margin-right: 15px;
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                }
                .item-content {
                    &__top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 4px;
                        .time {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        &.active {
            top: 95px;
        }
    }
}

.card-wrapper {
    .h2-app {
        margin-bottom: 8px;
    }
    .little {
        color: #8f929a;
        margin-bottom: 24px;
    }
    .notification-card {
        margin-top: 24px;
    }
    & + .onboard-home__notifications-content {
        margin-top: 40px;
        @media (max-width: 1024px) {
            margin-top: 0;
        }
    }

    .pq-cart:first-of-type {
        margin-bottom: 16px;
    }
}

.borrower-mobile-notification {
    .card-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .little,
        .h2-app {
            flex: 0 0 100%;
            width: 100%;
        }
        .pq-cart {
            flex: 0 0 calc(50% - 8px);
            max-width: calc(50% - 8px);
            & + .pq-cart {
                @media (min-width: 768px) {
                    margin-top: 0;
                }
            }
            @media (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
                & + .pq-cart {
                    margin-top: 16px;
                }
            }
        }
    }
}

.notification-tip {
    background: $whiteColor;
    box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 20px 16px;
    position: relative;
    @media (min-width: 767px) and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 32px;
    }
    & + .onboard-home__head {
        margin-top: 40px;
    }
    .btn-primary {
        width: 100%;
    }
    .link {
        color: $greenColor;
        text-decoration: underline;
        &:hover {
            opacity: 0.5;
        }
    }
    &__top {
        display: flex;
        align-items: center;
        .icon {
            background-color: $orangeColor;
            min-width: 40px;
            height: 40px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
        }
        h5 {
            font-weight: 500;
            font-size: 14px;
            color: rgba(#1e2534, 0.5);
        }
        p {
            opacity: 0.5;
        }

        .tip-transform {
            background-color: $lightColor;
            min-width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 16px;
            transition: all 0.4s ease;
            cursor: pointer;

            @media (min-width: 767px) and (max-width: 1024px) {
                margin-left: auto;
            }
            @media (max-width: 767px) {
                margin-left: 6px;
            }
            span {
                font-size: 10px;
            }
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    &__content {
        margin-top: 20px;
        p {
            margin-bottom: 0;
            & + p {
                margin-top: 20px;
            }
        }
        h5 {
            font-weight: bold;
        }
        .h5 {
            font-size: 14px;
            color: #8f929a;
            margin-bottom: 12px;
            line-height: 20px;
        }
        ul {
            li {
                position: relative;
                padding-left: 20px;
                font-size: 14px;
                line-height: 20px;
                color: #8f929a;
                &::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: $greenColor;
                    position: absolute;
                    left: 0;
                    top: 6px;
                }
                & + li {
                    margin-top: 12px;
                }
            }
        }
    }
}

.mobile-notification {
    background: $whiteColor;
    border-radius: 12px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.active {
        &:before {
            content: "";
            background-color: #ff2631;
            border: 2px solid #f2f4f5;
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: -2px;
            right: -2px;
        }
    }
    &.opened {
        z-index: 11;
        span {
            color: $darkGreenColor;
        }
    }
    span {
        font-size: 20px;
        color: #c7c9cc;
    }
}
.cosigner-tooltip-module {
    box-shadow: 0px 24px 64px 0px rgba(30, 37, 52, 0.1) !important;
    padding: 11px 12px 14px 12px !important;
    border-radius: 10px !important;
    width: 320px !important;
    z-index: 999 !important;
    &.show {
        opacity: 1 !important;
    }
    .cosigner-tooltip-title {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        padding-bottom: 8px;
        padding-left: 2px;
        padding-right: 2px;
        border-bottom: 1px rgba($blackColor, 0.1) solid;
    }
    .cosigner-tooltip-content {
        padding-top: 8px;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 10px;
        line-height: 16px;
        .fico-row {
            margin-top: 8px;
            .fico-item {
                flex: 0 0 33.333%;
                max-width: 33.333%;
            }
            .fico-tip {
                padding: 12px 12px 12px 25px;
                border-radius: 10px;
                &::before {
                    left: 9px;
                    top: 15px;
                }
                .name {
                    font-size: 10px;
                    line-height: 16px;
                }
                .value {
                    font-size: 10px;
                    line-height: 16px;
                    white-space: nowrap;
                }
            }
        }
    }
}
.cosigner-tooltip {
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translate(-20px, -50%);
    width: 320px;
    background-color: #fff;
    border-radius: 10px;
    padding: 11px 12px 14px 12px;
    z-index: 20;
    transition: all 400ms;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 24px 64px 0px rgba(30, 37, 52, 0.1);
    &::before {
        content: "";
        position: absolute;
        right: -7px;
        background: url(../../../assets/images/tooltip-arrow.svg) center center no-repeat;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
        top: 50%;
    }
    &-title {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        padding-bottom: 8px;
        padding-left: 2px;
        padding-right: 2px;
        border-bottom: 1px rgba($blackColor, 0.1) solid;
    }
    &-content {
        padding-top: 8px;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 10px;
        line-height: 16px;
        .fico-row {
            margin-top: 8px;
            .fico-item {
                flex: 0 0 33.333%;
                max-width: 33.333%;
            }
            .fico-tip {
                padding: 12px 12px 12px 31px;
                border-radius: 10px;
                &::before {
                    left: 13px;
                    top: 15px;
                }
                .name {
                    font-size: 10px;
                    line-height: 16px;
                }
                .value {
                    font-size: 10px;
                    line-height: 16px;
                }
            }
        }
    }
}

.cosigner-hypothetical {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: fit-content;
    max-height: 276px;
    @media (max-width: 767px) {
        gap: 16px;
        flex-direction: column-reverse;
        max-height: unset;
        align-items: center;
        position: relative;
        border-radius: 0;
        max-width: 100%;
        padding: 6px 6px 56px 6px;
    }
}

.cosigner__card-item {
    display: grid;
    grid-gap: 16px 16px;
    grid-template-columns: 1fr 1fr;
    margin: 8px;
    flex-wrap: wrap;
    width: 372px;
    height: auto;
    @media (max-width: 767px) {
        display: flex;
        margin: 0 auto;
        //width: 100%;
        //max-width: 100%;
        grid-gap: 0 16px;
        justify-content: space-between;
        max-width: 100%;
        margin-top: 16px;
        min-width: unset;
        //width: 100%;
    }
    > div {
        width: 50%;
    }

    .card-item {
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 400ms;
        &:hover {
            transform: scale(1.05, 1.05);
        }

        &__paddings {
            width: 178px;
            height: 122px;
            position: relative;
            @media (max-width: 767px) {
                &:nth-child(3),
                &:nth-child(4) {
                    margin-top: 16px;
                }
            }
            @media (max-width: 405px) {
                max-width: calc(50% - 8px);
            }
            &:hover {
                .cosigner-tooltip {
                    opacity: 1;
                    transform: translate(0, -50%);
                }
            }
        }

        &__value {
            font-size: 12px;
            font-weight: 600;
            height: 92px;
            min-height: 92px;
        }

        &-gray {
            height: 120px;
            background-color: #f6f9fc;

            .card-item__title {
                background-color: #edf2f6;
                color: #2b323f;
            }

            &.apr-rating .card-item__value {
                flex: auto;
                div {
                    font-size: 20px;
                }
            }
        }

        &__title {
            height: 30px;
            min-height: 30px;
            font-size: 12px;
            font-weight: 400;
        }

        &-green,
        &-orange {
            height: 122px;
            .card-item__title {
                color: rgba(#fff, 0.7);
            }
            .card-item__value {
                flex: auto;
                div {
                    font-size: 20px;
                    font-weight: 600;
                    color: white;
                    span {
                        font-size: 14px;
                        margin-right: 3px;
                    }
                }
            }
        }

        &-green {
            background-color: rgba(var(--borrowerPrimaryColor), 1);
            .card-item__title {
                background-color: #edf2f6;
                color: #2b323f;
            }
        }
        &-orange {
            background-color: rgba(var(--cosignerAccentColor), 1);
            .card-item__title {
                background-color: #edf2f6;
                color: #2b323f;
            }
        }

        &__main {
            flex: auto;
            height: 92px;
            min-height: 92px;
        }

        .progress-line {
            margin-top: 10px;
            position: relative;
            height: 16px;
            width: 76px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../../../assets/images/rating_mask.svg");
        }

        .locator {
            position: absolute;
            bottom: 100%;
            padding-bottom: 2px;
            left: 60%;
            transform: translateX(-50%);
        }

        .green_line {
            background-color: #78bd54;
            height: 100%;
            width: 60%;
            mask-image: url("../../../assets/images/rating_mask.svg");
        }
    }

    .circle_range_title {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        margin-top: -36px;
    }

    .circle_range_card {
        font-size: 10px;
        background-color: #e7de14;
        border-radius: 4px;
        width: 64px;
        height: 18px;
        color: white;
        text-align: center;
        line-height: 18px;
        margin-left: auto;
        margin-right: auto;
    }
}

.rc-slider-rail {
    background-color: #f2f4f5 !important;
}

.rc-slider-handle {
    width: 20px !important;
    height: 20px !important;
    margin-top: -8px !important;
    border-width: 6px !important;
    //border-color: var(--);
    // border-color: #6fb553;
}

.rc-slider-track {
    // background-color: #6fb553!important;
    border-radius: 10px !important;
}

.slider-item {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f2f4f5;
    cursor: pointer;
    transition: background-color 300ms ease;
    margin-left: 15px;
    margin-right: 15px;

    &:hover {
        background-color: darken(#f2f4f5, 10%);
    }
}

.no_cosigner_card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 8px;
    @media (max-width: 767px) {
        gap: 0;
        margin: 0;
        height: auto;
        min-width: unset;
    }
    .hypothetical-cosigner-slider {
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: 767px) {
            margin-bottom: 16px;
            .rc-slider-handle {
                border-color: var(--greenColor);
            }
            .rc-slider-track {
                background-color: var(--greenColor);
            }
        }
    }
    .fico-tooltip {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #878f99;
    }
    .fico-score {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 24px;
        width: 397px;
        height: 40px;
        background: #f1f5f8;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #151b26;
        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 16px;
        }
    }
    .hypothetical-cosigner-title-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        @media (max-width: 767px) {
            margin-bottom: 16px;
        }
        .hypothetical-cosigner-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .hypothetical-cosigner-title-text {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #151b26;
            }
        }
    }

    .hypothetical-cosigner-subtitle {
        font-family: "Basier Circle";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #878f99;
    }

    max-height: 204px;
    height: 204px;

    min-width: 400px;

    h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
    }

    > p {
        margin-top: 10px;
        margin-bottom: 24px;
        color: #8f929a;
        font-size: 14px;
        line-height: 18px;
        padding: 0 10px;
        text-align: center;
    }

    &__inner {
        padding: 20px 5px 14px 5px;
        border: 1px solid rgba(#8f929a, 0.3);
        border-radius: 16px;

        p {
            padding: 0 15px;
            color: #8f929a;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            margin-top: 18px;
            text-align: center;
        }

        &-value {
            font-size: 20px;
            font-weight: 600;
            min-width: 76px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 0 20px;
            background-color: #f2f4f5;
            border-radius: 16px;
            margin-bottom: 12px;
        }
    }
}

.cosigner__card {
    box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
    border-radius: 16px;
    margin-bottom: 16px;
    // overflow: hidden;
    padding: 10px;
    width: 100%;
}

.cosigner__card-wrapper {
    width: 100%;
    padding: 30px 30px 10px 30px;
    &-Wizard {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 800px;
        left: 50px;
        gap: 15px;
    }
    &-Wizard-included {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 400px;
        left: 50px;
        gap: 15px;
    }

    .cosigner-info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &-initial {
            min-width: 48px;
            width: 48px;
            height: 48px;
            background-color: $mainColor;
            color: $whiteColor;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
        }
        &-name {
            flex: 0 0 auto;
            padding-left: 12px;
            .name {
                font-size: 14px;
                line-height: 20px;
                color: $mainColor;
                font-weight: 600;
            }
            .subtitle {
                color: #8f929a;
                font-size: 14px;
                line-height: 20px;
            }
        }
        .menu-dots {
            align-self: flex-start;
            margin-left: auto;
        }
    }
}

.cosigner-info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 767px) {
        max-width: 100%;
    }
    .add-cosigner-button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        text-align: center;

        color: #ffffff;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 8px;

        width: 397px;
        height: 40px;
        cursor: pointer;

        background: #6db353;
        border-radius: 32px;
        @media (max-width: 767px) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        &:hover {
            background: #8cce56;
        }
    }
}

.circle_range_bg {
    position: relative;
    z-index: 2;
    > svg {
        transform: scale(-1, 1);
    }

    .circle_range_main {
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: -1;
    }

    .circle_range_round {
        width: 94px;
        height: 94px;
        border: 4px solid transparent;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        span {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: white;
            border: 3px solid white;
            display: block;
            position: absolute;
            right: -2px;
            top: 50%;
            transform: translate(50%, -50%);
        }
    }
}

.cosigner-tip-content {
    //position: relative;
    .h2-app {
        margin-bottom: 14px;
    }
    p {
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        font-size: 14px;
        line-height: 18px;
    }
    .close {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 10px;
        background: #f2f4f5;
        color: #1e2534;
        cursor: pointer;
        transition: all 400ms;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 990px) {
            right: 10px;
            top: 10px;
        }
        &:hover {
            color: $greenColor;
        }
    }
}
.fico-row {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
    flex-wrap: wrap;
    margin-top: 25px;
    justify-content: center;
    .fico-item {
        flex: 0 0 150px;
        max-width: 150px;
        width: 150px;
        padding: 5px;
    }
}
.fico-tip {
    background: #f2f4f5;
    position: relative;
    padding: 16px 16px 16px 42px;
    border-radius: 16px;
    text-align: left;
    &::before {
        content: "";
        position: absolute;
        left: 22px;
        top: 24px;
        width: 4px;
        height: 4px;
        border: 2px solid transparent;
        background-color: #fff;
        border-radius: 3px;
    }
    .name {
        color: rgba($mainColor, 0.5);
        font-size: 14px;
        line-height: 24px;
    }
    .value {
        font-size: 14px;
        line-height: 24px;
        color: $mainColor;
    }
    &-poor {
        &::before {
            border-color: #f19c9c;
            box-shadow: 0px 0px 0px 2px rgba(237, 131, 131, 1);
        }
    }
    &-fair {
        &::before {
            border-color: #f5b579;
            box-shadow: 0px 0px 0px 2px rgba(242, 162, 88, 1);
        }
    }
    &-good {
        &::before {
            border-color: #efea69;
            box-shadow: 0px 0px 0px 2px rgba(231, 222, 20, 1);
        }
    }
    &-very_good {
        &::before {
            border-color: #d2e594;
            box-shadow: 0px 0px 0px 2px rgba(185, 214, 88, 1);
        }
    }
    &-exceptional {
        &::before {
            border-color: #a3d091;
            box-shadow: 0px 0px 0px 2px rgba(111, 181, 84, 1);
        }
    }
}

.header-cosigner-dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;
    @media (max-width: 767px) {

        & + .cosigner__card-item {
            margin-bottom: 16px;
        }
    }

    .header-cosigner-dropdown-identity-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .header-cosigner-dropdown-initials {
            display: flex;
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            justify-content: center;
            align-items: center;
            background: #151b26;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
        }
        .header-cosigner-dropdown-name-container {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            .header-cosigner-dropdown-name-top-row-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #151b26;
                white-space: nowrap;
            }
            .header-cosigner-dropdown-name-bottom-row {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #878f99;
                white-space: nowrap;
            }
        }
    }
    .header-cosigner-dropdown-button-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        @media (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 10px;
        }
        .header-cosigner-dropdown-button-1 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            gap: 8px;
            width: 206px;
            height: 40px;
            border: 2px solid #6db353;
            border-radius: 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            cursor: pointer;
            color: #6db353;
            @media (max-width: 767px) {
                width: 100%;
            }
        }
        .header-cosigner-dropdown-button-2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            gap: 8px;
            width: 206px;
            height: 40px;
            background: #6db353;
            border-radius: 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            @media (max-width: 767px) {
                width: 100%;
            }
            &:hover {
                background: #8cce56;
            }
        }

        .header-cosigner-dropdown-button-center-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            max-width: 412px;
            width: 100%;

            .header-cosigner-dropdown-button-center {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 24px;
                gap: 8px;
               
                width: 240px;
                height: 40px;
                background: #6db353;
                border-radius: 32px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #ffffff;
                cursor: pointer;
                @media (max-width: 767px) {
                    width: 100%;
                }
                &:hover {
                    background: #8cce56;
                }

                &.full {
                    width: 100%;
                    max-width: 397px;
                }
            }
            
        }   

        
    }
}
