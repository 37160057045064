.footerWrapper {
    padding: 68px 0 40px;
    margin-top: 80px;
}

.container{
    display: flex;
    max-width: 1360px;
    margin: 0 auto;
    padding: 0px 15px;

    @media (max-width: 375px){
        flex-direction: column;
        gap: 56px;
    }
}

.gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 40px;

    @media (max-width: 560px){
        grid-template-columns: repeat(2, 1fr);
    }

}

.box {
    padding-left: 15px;
    padding-right: 35px;
    width: 100%;
}