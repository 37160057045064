.ButtonGroup {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 560px) {
    .ManualAddressBottomSection {
        justify-content: center;
    }
}
    
