.menu-item {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    height: 80px;

    @media (max-width: 767px) {
        padding: 12px;
        height: 108px;
        span {

        }
    }

    .content-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            text-align: left;
        }

        @media (max-width: 767px) {
            justify-content: center;

            div {
                flex-direction: column;
                justify-content: center;
            }

            span {
                text-align: center;
                color: var(--primaryColor2);
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .icon-arrow-right {
        visibility: hidden;

        @media (max-width: 767px) {
            display: none;
        }
    }

    &:hover .content-container {
        .icon-arrow-right {
            visibility: visible;
            color: rgba(var(--borrowerAccentColor), 1);
        }
    }
}
