.pu-bottom-ext-wrapper{
    padding: 22px 24px 24px 24px;

    &.direct-started{
        background: #F6F9FC;
    }

    &.show-steps {
        .incl-user-mgmt-btn-mobile {
            display: none;
        }
        &.cs-dir-incl {
            margin-top: 24px;
        }
        @media (max-width: 990px) {
            .btn-group {
                display: none;
            }
            .incl-user-mgmt-btn-mobile {
                display: flex;
            }
        }
    }

    &.show-no-steps {
        gap: 24px;
        .incl-user-mgmt-btn-mobile {
            display: none;
        }
        
        @media(min-width: 768px) and (max-width: 990px) {
            row-gap: 8px;
        }
        @media (max-width: 990px) {
            .flex-container-1 {
                // flex-direction: column;
                align-items: flex-start;
                gap: 24px;
            }
            // .btn-group {
            //     flex: 0 0 100%;
            //     max-width: 100%;
            //     display: flex;
            //     justify-content: flex-start;
            // }
            .btn-group {
                display: none;
            }
            .incl-user-mgmt-btn-mobile {
                display: flex;
                margin-top: 16px;
            }
        }

        // @media (max-width: 767px) {
        //    div {
        //        &:not(.incl-user-box) {
        //            flex-direction: column-reverse;
        //            align-items: flex-start !important;
        //        }

        //    }
        // }
    }
}