.custom-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .custom-bg-center-border {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: calc(100% - 230px);
  }
  .custom-bg-top-border {
    height: 200px;
    top: 51px;
    width: calc(100% + 2px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-3deg);
    border-radius: 0 80px 0 0;
    @media (max-width: 1270px) {
      top: 31px;
    }
    @media (max-width: 990px) {
      border-radius: 0 40px 0 0;
      top: 21px;
    }
    &.straight{
      border-radius: 0;
      top: 0;
      transform: translateX(-50%) rotate(0deg);
    }
  }
  .custom-bg-bottom-border {
    height: 200px;
    bottom: 51px;
    width: calc(100% + 2px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-3deg);
    border-radius: 0 0 0 80px;
    @media (max-width: 1270px) {
      bottom: 31px;
    }
    @media (max-width: 990px) {
      border-radius: 0 0 0 40px;
      bottom: 21px;
    }
  }
  .custom-bg-bar {
    position: absolute;
    height: 40px;

    &-top {
      top: -40px;
      left: 0;
      border-radius: 0 40px 0 0;
    }
    &-bottom {
      bottom: -40px;
      right: 0;
      border-radius: 0 0 0 40px;
    }

    &-size-regular {
      // width: 306px;
      width: 49%;
    }

    &-size-wide {
      width: calc(50% - 16px);
    }
    @media (max-width:990px) {
      height: 20px;
      width: 45%;
      &-top {
          top: -20px;
        }
      &-bottom {
        bottom: -20px;
      }
    }
  }
  &.custom-bg-grey {
    .custom-bg-top-border,
    .custom-bg-center-border,
    .custom-bg-bottom-border {
      background: var(--neutralColor100);
    }
  }
  &.custom-bg-white {
    .custom-bg-top-border,
    .custom-bg-center-border,
    .custom-bg-bottom-border {
      background: var(--whiteColor);
    }
  }
  &.custom-bg-dark {
    .custom-bg-top-border,
    .custom-bg-center-border,
    .custom-bg-bottom-border {
      background: rgba(var(--borrowerPrimaryColor), 1);
    }
  }

  .vertical-lines {
    .line {
      &.line1 {
        top: 82px;
        height: calc(100% - 102px);
      }
      &.line2 {
        top: 67px;
        height: calc(100% - 104px);
      }
      &.line3 {
        top: 52px;
        height: calc(100% - 105px);
      }
      &.line4 {
        top: 37px;
        height: calc(100% - 105px);
      }
      &.line5 {
        top: 20px;
        height: calc(100% - 102px);
      }
      @media (max-width: 1270px) {
        &.line1 {
          top: 56px;
          height: calc(100% - 62px);
        }
        &.line2 {
          top: 46px;
          height: calc(100% - 66px);
        }
        &.line3 {
          top: 33px;
          height: calc(100% - 65px);
        }
        &.line4 {
          top: 17px;
          height: calc(100% - 63px);
        }
        &.line5 {
          top: 6px;
          height: calc(100% - 61px);
        }
      }
      @media (max-width: 990px) {
        &.line1 {
          top: 40px;
          height: calc(100% - 46px);
        }
        &.line3 {
          top: 23px;
          height: calc(100% - 47px);
        }
        &.line5 {
          top: 5px;
          height: calc(100% - 44px);
        }
      }
      @media (max-width: 767px) {
        &.line1 {
          top: 30px;
          height: calc(100% - 54px);
        }
        &.line5 {
          top: 24px;
          height: calc(100% - 53px);
        }
      }
    }
  }
}

@media (max-width: 585px) {
  .custom-bg .custom-bg-bar-bottom {
    bottom: -20px;
  }

  .custom-bg .custom-bg-bar {
    width: 50%;
    height: 20px;
  }
}