.wrapper {
    margin-left: 15px;
    margin-right: 15px;
}

.sectionText {
    color: rgba(var(--borrowerPrimaryColor), 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin-bottom: 21px;

}

.title {
    font-size: 48px;
    font-weight: 600;
    line-height: 56px; /* 116.667% */
    margin-bottom: 24px;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 32px;
}
