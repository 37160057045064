.offer-filter-row-container {
    .offer-filter-row-container-list {
        display: flex;
        overflow-x: auto;
        flex-direction: row;
        gap: 10px;
        grid-gap: 0px;

        .offer-filter-row-filter {
            margin-left: 5px;
            margin-right: 5px;

            &.first-item {
                margin-left: 0px;
            }
        }

        .offer-filter-row-empty-filter {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

.bubble-wrapper-filter {
    position: absolute;
    display: inline-block;
    width: fit-content;
    z-index: 905;
}

.filter-container {
    position: relative;

    .offer-filter-container {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        align-items: center;
        padding: 12px;
        height: 40px;
        gap: 8px;
        width: fit-content;
        border: 1px solid #abb6bd;
        border-radius: 32px;
        white-space: nowrap;
        pointer-events: auto;
        z-index: 950;
        color: #2b323f;

        &.clear-filters {
            background: #ccd6dd;
            border: 1px solid transparent;
            margin-right: 5px;
        }

        &:hover {
            background: white;
            border: 1px solid white;
            cursor: pointer;
        }

        &.selected {
            background: white;
            border: 1px solid white;
            &.borrower{
                color: rgba(var(--borrowerPrimaryColor), 1);
            }

            &.cosigner{
                color: rgba(var(--cosignerPrimaryColor), 1);
            }
        }

        &.bookmark {
            margin-right: 5px;
            margin-left: 5px;
        }

        &.first-item {
            margin-left: 0px;
        }

        .offer-filter-icon {
        }
        .offer-filter-name {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

        }
    }
}
.mobile-filter {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--neutralColor50);
    z-index: 999;
    transition: all 400ms;
    &.active {
        right: 0;
    }
    .mobile-filter-head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 40px 16px 16px 16px;
        background-color: var(--whiteColor);
    }
    .close-filter {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--primaryColor2);
        margin-right: 16px;
    }
    .mobile-filter-footer {
        display: flex;
        padding: 16px 16px 29px 16px;
        background-color: var(--whiteColor);
        &__row {
            display: flex;
            margin-left: -4px;
            margin-right: -4px;
        }
        &__col {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 4px;
            padding-right: 4px;
        }
        .filter-bnt {
            display: block;
            width: 100%;
            padding: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: var(--primaryColor2);
            border-radius: 32px;
            border: none;
            &.clear-filter {
                background-color: var(--neutralColor100);
            }
            &.apply-filter {
                background-color: var(--greenColor);
                color: var(--whiteColor);
            }
        }
    }
    .ScrollbarsCustom {
        margin-top: 0;
        .ScrollbarsCustom-Scroller {
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
            margin-right: 0 !important;
            padding-right: 0 !important;
        }
        .ScrollbarsCustom-Content {
            padding-right: 16px !important;
            display: block;
        }
        .ScrollbarsCustom-Wrapper {
            inset: 0 !important;
        }
        .ScrollbarsCustom-Track {
            right: 7px !important;
            width: 2px !important;
        }
    }
    .mobile-filter-content {
        width: 100%;
        padding: 20px 0 20px 16px;
    }
    .mobile-filter-block {
        & + .mobile-filter-block {
            padding-top: 16px;
            border-top: 1px solid #ccd6dd;
        }
    }
    .box {
        position: relative;
        width: 100%;
        left: unset;
        top: unset;
        padding: 0 0 16px 0;
        border-radius: 0;
        background-color: transparent;
    }
}
