.user-dash-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    max-width: 1121px;

    @media (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 16px;
    }
    .user-dash-header-name-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        .user-dash-header-title {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            color: #151b26;
        }
        .user-dash-header-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #9ea9b0;
        }
    }

    .user-dash-header-history {
        position: absolute;
        right: 404px;
        top: 62px;

        @media (max-width: 767px) {
            position: static;
            top: unset;
            right: unset;
        }
        @media (max-width: 1024px) {
            right: 0;
            top: 26px;
        }
    }

    .history-body-card {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px;
        gap: 8px;
        width: 100%;
        height: 44px;

        background: #f1f5f8;
        border-radius: 8px;
        cursor: pointer;
    }

    .history-body-expanded-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 8px;
        justify-content: space-between;

        .history-body-left {
            display: flex;
            gap: 8px;
            flex-direction: row;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #f6f9fc;
        }
        .history-body-right {
            display: flex;
            gap: 8px;
            flex-direction: row;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #f6f9fc;
        }
    }
    .history-expanded-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 100%;
        max-width: 316px;
        height: 32px;

        background: rgba(255, 255, 255, 0.2);
        border-radius: 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    .history-header-user {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #151b26;
    }

    .history-header-loan-amount {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #151b26;
    }
    
    .expand-button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 8px;
        border-radius: 32px;
        color: black;
        height: 16px;
        width: 16px;
        span {
            transition: all 400ms;
        }
        &.active {
            span {
                color: rgba(var(--borrowerPrimaryColor), 1);
                transform: rotate(-180deg);
            }
        }
        &:hover {
            transition: all 400ms;
            cursor: pointer;
        }
    }
}
