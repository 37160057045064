.ToggleSwitch{
    display: flex;
    align-items: center;
    gap: 16px;
}

.ToggleSwitch.UsedInForm{
    padding: 16px 0px;
}

/* Visually hides default HTML input checkbox */
.ToggleSwitch [type="checkbox"] {
    position: absolute;
    left: -9999px;
}

.ToggleSwitch label {
    display: flex;
    align-items: center;
}

/* Sets the fixed dimensions of the last span element */
.ToggleSwitchContent {
    position: relative;
    width: 48px;
    height: 32px;
    border-radius: 16px;
    background:  var(--secondary-grey);
    transition: all 0.3s;
}

/* Define the pseudo-element for the last span */
.ToggleSwitchContent::before {
    content: "";
    position: absolute;
}
/* Circle */
.ToggleSwitchContent::before {
    left: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    background: var(--primary-white);
    border-radius: 50%;
    transition: transform 0.3s;
}

/* Styles applied when check box is checked:
    1) Color change
    2) Position of ::before pseudo-element changes -moving with a slide transition from left to right
*/

.ToggleSwitch.borrower [type="checkbox"]:checked + label .ToggleSwitchContent {
    background: rgba(var(--borrowerAccentColor), 1);
}

.ToggleSwitch.cosigner [type="checkbox"]:checked + label .ToggleSwitchContent {
    background: rgba(var(--cosignerAccentColor), 1);
}

.ToggleSwitch [type="checkbox"]:checked + label .ToggleSwitchContent::before {
    transform: translateX(15px);
}

.ToggleSwitchText {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--neutral-400)
}

