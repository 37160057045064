@import "../../../../../assets/styles/colors";
@import "../../../../../assets/styles/screen-size-variables.scss";

.left-nav-start-request-button {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $desktop-width) {
        justify-content: flex-start;
    }
}

.left-navigation-menu-top-links {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: none;
    @media (max-width: $desktop-width) {
        display: block;
    }
    &.rate-page {
        @media (max-width: $tablet-width) {
            padding-top: 32px;
        }
    }
}

.left-navigation-menu-top-dropdown {
    cursor: pointer;
    height: 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $mainColor;
    transition: all 400ms;

    &:hover {
        color: $greenColor;
    }
}

.left-navigation-replaced-menu-back {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
    .back-arrow {
        transform: scale(-1, -1);
    }
    &.active {
        @media (max-width: $tablet-width) {
            display: flex;
        }
    }
}
