.tooltip-wrapper {
    width: 16px;
    height: 16px;
    display: inline-flex;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .tooltip-trigger {
        padding: 0;
        border: none;
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        //overflow: hidden;
        position: relative;
        cursor: pointer;

        & > span {
            width: 14px;
            height: 14px;
            content: "";
            position: absolute;
            bottom: calc(100% + 12px);

            background: url(../../../assets/images/tooltip-arrow.svg) center center no-repeat;
            background-size: 100% 100%;
            transform: translateY(-50%);
            left: 0;
            opacity: 0;
            transition: all 400ms;
        }

        &::before {
            content: "";
            width: 13px;
            height: 13px;
            background: #000;
            position: absolute;
            left: 50%;
            top: 50%;

            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background: url(../../../assets/images/tooltip-question.svg) center center no-repeat;
            background-size: 100% 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
        &:hover {
            & > span {
                opacity: 1;
                transform: translateY(50%);
            }
            & + .tooltip-content {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
                @media (max-width: 767px) {
                    transform: translate(-50%);
                }
            }
        }
    }
}
.tooltip-content {
    position: absolute;
    bottom: calc(100% + 12px);
    width: 278px;
    @media (max-width: 1200px) {
        top: calc(100% + 12px);
        bottom: auto;
    }
    left: -50px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 24px 64px 0px rgba(30, 37, 52, 0.1);
    transition: all 400ms;
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
    z-index: 904;

    @media (max-width: 767px) {
        transform: translate(-50%, -20px);
    }
}
.tooltip-data {
    padding: 8px 14px 14px 14px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    z-index: 904;

    p {
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        & + p {
            margin-top: 7px;
        }
    }
}
.tooltip-title {
    padding: 11px 12px 8px 12px;
    border-bottom: 1px rgba(#1e2534, 0.1) solid;
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
}
