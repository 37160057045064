@mixin background-config{
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 16px;
}


.request-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 32px 24px 0px 24px;
    max-width: 1121px;
    @media (max-width: 1300px) {
        padding: 32px 40px 40px 40px;
    }

    @media (max-width: 1024px) {
        margin-left: -40px;
        margin-right: -40px;
        width: auto;
        border-radius: 0;
    }

    @media (max-width: 767px) {
        margin-left: -16px ;
        margin-right: -16px;
        padding: 32px 16px 32px 16px;
    }
    @include background-config;
    &.borrower{
        background-image: url("../../../../assets/images/backgrounds/borrower-back-panel.png");
    }
    &.cosigner{
        background-image: url("../../../../assets/images/backgrounds/cosigner-back-panel.png");
    }

    &.borrower.paaas{
        background-image: none;
        background: rgba(var(--borrowerPrimaryColor), 1);
    }

    &.cosigner.paaas{
        background-image: none;
        background: rgba(var(--cosignerPrimaryColor), 1);
    }
}