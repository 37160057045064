@mixin text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

@mixin completeText {
    @include text;
}

@mixin activeBottomText {
    font-weight: 500;
    line-height: 20px;
    opacity: 1;
}

@mixin latestIncompleteStepText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    opacity: 1;
}

.rm-step-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0.7;
    background: #fff;
    border-radius: 12px;
    position: relative;

    &.complete-step {
        opacity: 0.90;
    }

   
    &.complete-step:hover {
        opacity: 1;
        cursor: pointer;
        .top-section{
            .step {
                opacity: 1;
            }
            .icon {
                opacity: 1;
            }
        }

        .bottom-content {
            p {
                opacity: 1;
            }
        }
    }

    &.active {
        opacity: 1;
        position: relative;
    }

    &.latest-incomplete-step {
        opacity: 1;
        cursor: pointer;
    }

    .step-status {
        position: absolute;
        right: 9px;
        top: 9px;
    }

    .top-section {
        padding: 16px 16px 0 16px;

        .step {
            @include text;
            margin-bottom: 24px;
            opacity: 0.4;

            &.active {
                @include latestIncompleteStepText;
            }

            &.latest-incomplete-step {
                @include latestIncompleteStepText;
            }

            &.complete-step {
                @include completeText;
                &.borrower {
                    color: #6db353;

                    &.paaas {
                        color: rgba(var(--borrowerPrimaryColor), 1);
                    }
                }

                &.cosigner {
                    color: #dc7962;

                    &.paaas {
                        color: rgba(var(--cosignerPrimaryColor), 1);
                    }
                }
            }
        }

        .icon {
            display: flex;
            width: 40px;
            justify-content: flex-start;
            margin-bottom: 16px;
            opacity: 0.5;

            &.active {
                opacity: 1;
            }
        }
    }

    .bottom-content {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 0px 16px 16px 16px;

        p {
            @include text;
            opacity: 0.4;
        }

        &.latest-incomplete-step {
            padding-top: 16px;

            &.borrower {
                background: #185155;

                &.paaas {
                    background: rgba(var(--borrowerPrimaryColor), 1);
                    opacity: 0.7;
                }
            }

            &.cosigner {
                background: #80463b;

                &.paaas {
                    background: rgba(var(--cosignerPrimaryColor), 1);
                    opacity: 0.7;
                }
            }

            p {
                @include activeBottomText;
                color: #fff;
            }
        }

        &.complete-step {
            p {
                &.borrower {
                    color: rgba(var(--borrowerPrimaryColor), 1);
                }

                &.cosigner {
                    color: rgba(var(--cosignerPrimaryColor), 1);
                }
                @include completeText;
                opacity: 0.4;
            }
        }

        &.active {
            p {
                @include activeBottomText;
            }
        }
    }
}
