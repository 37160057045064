.rate-modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .rate-modal-text-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 492px;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
            max-width: 100%;
        }
        .rate-modal-title {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;

            display: flex;
            align-items: center;
            text-align: center;

            color: #1e2534;
        }
        .rate-modal-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            text-align: center;

            color: #878f99;
        }
        .rate-modal-button {
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 24px;
            gap: 8px;

            width: 95px;
            height: 56px;

            background: #6db353;
            border-radius: 40px;
            -webkit-transition: background 300ms linear;
            -ms-transition: background 300ms linear;
            transition: background 300ms linear;
            &:hover {
                background: #8cce56;
                cursor: pointer;
            }
        }
    }
}

.retake-quiz-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background: #f1f5f8;
    border-radius: 16px;

    @media (max-width: 750px) {
        gap: 16px;
    }

    .retake-radio-buttons {
        @media (min-width: 0px) {
            margin-top: 15px;
        }
        @media (min-width: 400px) {
            margin-top: 15px;
        }
        @media (min-width: 500px) {
            margin-top: 20px;
        }
        @media (min-width: 600px) {
            margin-top: 40px;
        }
    }
    .retake-title-container {
        @media (min-width: 0px) {
            margin-bottom: 15px;
        }
        @media (min-width: 400px) {
            margin-bottom: 15px;
        }
        @media (min-width: 500px) {
            margin-bottom: 20px;
        }
        @media (min-width: 600px) {
            margin-bottom: 40px;
        }

        z-index: 951;
        width: 100%;
        justify-content: flex-start;
        .retake-title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            @media (max-width: 1200px) {
                font-size: 14px;
                line-height: 20px;
            }
            @media (max-width: 600px) {
                font-size: 12px;
                line-height: 18px;
            }
        }
        .retake-subtitle {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            color: #ffffff;
            @media (max-width: 1200px) {
                font-size: 28px;
                line-height: 36px;
            }
            @media (max-width: 600px) {
                font-size: 24px;
                line-height: 32px;
            }
            @media (max-width: 400px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    .retake-button {
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        gap: 8px;
        width: 228px;
        height: 56px;
        background: #6db353;
        border-radius: 40px;
    }

    .retake-background {
        position: absolute;
        top: 0;
        z-index: 950;
        max-width: 100%;
        min-width: 100%;
    }
    .retake-background-white {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 949;
        max-width: 50%;
        min-width: 50%;
    }
}
