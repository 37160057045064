.step-form-sign-up {
    .step-form-block {
        .form-wrapper {
            @media (max-width: 768px) {
                gap: 24px;
                display: flex;
                flex-direction: column;
            }
            @media (min-width: 768px) {
                display: flex;
                gap: 16px;
            }

            .form-row {
                width: 100%;
            }
        }
    }

    .step-form-footer {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        @media (min-width: 768px) {
            display: flex;
            gap: 16px;
        }

        .button-group {
            width: 100%;

            &.text-center {
                .sso-button {
                    margin: 0;
                    width: 100%;
                    height: 56px;
                    border-radius: 40px;
                    border: 1px solid var(--neutralColor200);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}