@import "../../../../assets/styles/screen-size-variables.scss";

.left-navigation-bar-header {
    display: none;
    align-items: center;

    &.active {
        @media (max-width: $desktop-width) {
            display: flex;
        }
    }

    &.expanded {
        @media (min-width: $tablet-width) {
            display: flex;
        }
    }
}

.left-navigation-bar-close {
    display: none;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    color: rgba(var(--borrowerPrimaryColor), 1);
    font-size: 16px;
    margin-right: 24px;
    @media (max-width: $desktop-width) {
        display: flex;
    }
}

.sidebar-trigger {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: #f1f5f8;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    font-size: 16px;
    color: var(--primaryColor2);
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 1001;
    @media (max-width: $tablet-width) {
        display: flex;
    }
}

.left-navigation-bar-header-paaas-logo {
    display: flex;
    @media (min-width: $desktop-width) {
        display: none;
    }
}
