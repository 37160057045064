.ProgressBar {
    display: flex;
    align-items: center;
    width: 100%;
}

.ProgressBarCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ccd6dd;
    transition: all 0.3s ease;

    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        background: white;

        &.borrower {
            border: 4.6px solid rgba(var(--borrowerAccentColor), 1);
        }

        &.cosigner {
            border: 4.6px solid rgba(var(--cosignerAccentColor), 1);
        }
    }

    &.complete {
        &.borrower {
            background: rgba(var(--borrowerAccentColor), 1);
        }

        &.cosigner {
            background: rgba(var(--cosignerAccentColor), 1);
        }
    }
}

.ProgressBarLine {
    margin: 0px 4px;
    flex-grow: 1;
    height: 2px;
    background: #ccd6dd;
    transition: all 0.6s ease;

    &.complete {
        &.borrower {
            background: rgba(var(--borrowerAccentColor), 1);
        }
        &.cosigner {
            background: rgba(var(--cosignerAccentColor), 1);
        }
    }
}
