.FormContainer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 24px;
    background: var(--primary-white);
    text-align: left;

    
}
@media screen and (max-width: 768px) {
    .FormContainer{
        padding: 24px;
    }
}

.FormContainerHeader {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: unset;
    margin-bottom: 24px;   
}