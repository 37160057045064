@import "../../../assets/styles/colors";
@import "../../../assets/styles/screen-size-variables.scss";

.navigation-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;

    &.marketplace{
        height: 100vh;
    }

    &.has-header-bar {
        height: calc(100% - 72px);
        display: block;
    }
    @media (max-width: $desktop-width) {
        flex-direction: column;
        justify-content: start;
        margin-left: 0;
        // Add the height of the header bar which is fixed and 40px of padding;
        min-height: calc(var(--main-section-wrapper-height) + 97px + 40px);
        .main-section-wrapper {
            margin-left: 0;
        }
    }
    &.navigation-wrapper_profile {
        @media (max-width: $desktop-width) {
            margin-left: 0;
            .main-section-wrapper {
                margin-left: 0;
            }
        }
    }
    @media (max-width: $tablet-width) {
        display: block;
    }

}

.main-section-wrapper {
    width: calc(100vw - 239px);
    max-width: 1121px;
    padding: 40px;
    height: 100%;
    // This acts like a min/max height - if min-height is < height, then it will be height, otherwise it will be min-height
    height: var(--main-section-wrapper-height);
    min-height: var(--viewport-height);
    margin: 0 auto;

    &.paaas{
        &.dashboard-page{
            // 72px represent header
            min-height: calc(var(--viewport-height) - var(--paaas-footer-height) - 72px);
        }
        &.decision-page{
            height: 100%;
        }
    }


    &.has-header-bar {
        height: calc(100% - 72px);
    }

    &.main-section-wrapper__profile {
        height: auto;
        min-height: 100vh;
    }

    @media (max-width: 1024px) {
        padding: 96px 40px 40px 40px;
        width: 100%;
    }

    @media (max-width: 767px) {
        padding: 40px 16px 16px 16px;
    }
}

.onboard-home {
    background-color: $lightColor;
    min-height: 100vh;

    @media (max-width: 1024px) {
        &:not(.onboard-home-coming-soon-page) {
            padding-top: 97px;
        }
    }
    @media (max-width: 767px) {
        padding-right: 0;
        overflow: hidden;
        &:not(.onboard-home-coming-soon-page) {
            padding-top: 112px;
        }
        &.onboard-home-notification {
            padding-top: 50px;
            .tablet-notification-bg {
                display: none !important;
            }
        }
    }
    &__content {
        padding: 40px 470px 40px 280px;
        min-height: 100vh;
        @media (max-width: 1300px) {
            padding-right: 410px;
        }
        @media (max-width: 1200px) {
            padding-right: 40px;
        }
        @media (max-width: 1024px) {
            padding: 0 40px 40px;
        }
        @media (max-width: 767px) {
            padding: 0 20px 16px;
        }

        &.coming-soon-page {
            padding-right: 0;
            padding-bottom: 0;
            padding-top: 0;
            padding-left: 240px;
            @media (max-width: 1024px) {
                padding-left: 0;
            }
        }

        &--cards {
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;
            margin-bottom: 24px;
            @media (max-width: 991px) {
                margin-top: 20px;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 16px;
                background-color: $whiteColor;
                width: calc(100% / 3 - 32px / 3);
                margin-right: 16px;
                margin-bottom: 16px;
                padding: 20px;
                text-align: center;
                border: 2px solid transparent;
                transition: all 300ms ease;
                cursor: pointer;

                &:hover {
                    border: 2px solid #6fb554;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }

                @media (max-width: 1366px) {
                    width: calc(50% - 8px);
                    &:nth-child(3n) {
                        margin-right: 16px;
                    }
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
                @media (max-width: 991px) {
                    width: calc(50% - 5px / 2);
                    margin-right: 5px;
                    margin-bottom: 5px;
                    padding: 25px 20px 20px;
                    &:nth-child(3n) {
                        margin-right: 5px;
                    }
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }

                img {
                    max-height: 76px;
                    width: 100%;
                    object-fit: contain;
                    margin-bottom: 16px;
                }

                &.disabled {
                    mix-blend-mode: luminosity;
                    //pointer-events: none;
                }
            }

            &.cards-big {
                img {
                    max-height: 148px;
                }
            }
        }
    }

    .no-right-sidebar {
        padding-right: 40px;
    }

    &.onboard-load-offers {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
}
