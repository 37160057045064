.declined-lenders-container {
    padding: 24px;
    border-radius: 16px;
    background: white;
    border: 2px solid #f32b32;
    overflow-y: auto;
    @media (max-width: 767px) {
        padding: 16px;
    }
}

.declined-lender-section {
    display: flex;
    margin-top: 24px;
    overflow: hidden;
    gap: 12px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    position: relative;
    // scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        scroll-snap-align: center;
    }

    .declined-lender-box {
        display: flex;
        align-items: center;
        padding: 8px;
        gap: 8px;
        border-radius: 8px;
        background: #f1f5f8;
        white-space: nowrap;

        img {
            width: 24px;
            height: 24px;
            
            object-fit: cover;
        }

    }

    .carousel-button {
        position: absolute;
        cursor: pointer;
        top: 50%;
        z-index: 1;
        // transition: transform 1s ease-in-out;
        background: white;
        border-radius: 15px;
        border: none;
        padding: 0.5rem;

        &.left {
            
            left: 0;
            transform: translate(-100%, -50%);        
        }

        &.right {
            right: 0;
            transform: translate(100%, -50%);    
            
            &:hover {
                transform: translate(0%, -50%);
                                transition: transform 0.1s ease-in-out;
            }
        }
    
    
    }
    &:hover .carousel-button.left {
            transform: translate(0%, -50%);
            transition: transform 0.2s ease-in;
        }

    }
