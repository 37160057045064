.lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1s linear infinite;
  border-color: #fff #fff transparent transparent;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}