.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f1f5f8;
    border-radius: 16px;
    position: relative;
    width: 752px;
    padding-bottom: 24px;

    @media (min-width: 768px) and (max-width: 990px) {
        max-width: 580px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
    
}

.headerContainer{
    position: relative;
    width: 100%;
}

.background{
    position: absolute;
    top: -20px;
    z-index: 2;
    width: calc(100% + 3px);
    height: calc(100% + 15px);
    transform: rotate(-3deg);
    border-bottom-left-radius: 32px;

    &.borrower{
        background: rgba(var(--borrowerPrimaryColor), 1);
    }

    &.cosigner{
        background: rgba(var(--cosignerPrimaryColor), 1)
    }
}



.header {
    margin: 24px;
    position: relative;
    z-index: 2;
    width: 100%;
    color: #ffffff;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 40px;
}

.subtitle {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
}

.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    margin-bottom: 32px;
    padding: 0 24px;
    width: 100%;
}

.tyTxt {
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
    font-weight: 600;
    text-align: center;
    padding: 24px;
}