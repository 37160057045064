.sectionContainer{
    width: 560px;
    margin-bottom: 56px;

    @media(max-width: 768px){
        width: 100%;
    }
}


.content-section {
    position: relative;
    z-index: 3;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 40px;

    @media(max-width: 768px){
        padding: 0px 20px;
    }

    @media(max-width: 375px){
        padding: 0px 16px;
    }
}

.content-section-inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 40px;

    @media(max-width: 768px){
        padding: 0px 20px;
    }

    @media(max-width: 375px){
        padding: 0px 16px;
    }
}

.card-content {
    display: flex;

    @media(max-width: 1024px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }

    @media (max-width: 768px){
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}
