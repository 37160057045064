.sign-modal {
    &-wrapper {
        .forgot-password {
            margin-top: 42px;
        }
        .form-group {
            label {
                text-align: left;
            }
            //.error-text {
            //  position: absolute;
            //  bottom: -34px;
            //}
        }
        .privacy-text {
            text-align: left;
        }

        .button-group {
            margin-top: 32px;
            align-items: center;
            .btn-primary {
                margin-top: 0;
                margin-right: 0;
            }
        }

        &.overview-wrapper {
            .sub-text + .sub-text {
                margin-top: 32px;
            }

            .form-row-card {
                padding-top: 40px;
                padding-bottom: 40px;
                border: 1px solid #d2d3d6;
                border-radius: 16px;
                cursor: pointer;
                margin-bottom: 110px;
                width: 100%;

                img {
                    height: 44px;
                    width: auto;
                    margin-top: auto;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }
    }
    &-content {
        width: 100%;
        &-success {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    &-title {
        //margin-bottom: 32px;
        margin-bottom: 16px;
        .sub-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            color: #6fb554;
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .sub-text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 18px;

            &.lg {
                font-size: 16px;
                font-weight: 400;
                color: #8f929a;
                line-height: 24px;

                strong {
                    font-weight: 600;
                    color: #8f929a;
                }
            }
        }
        .h2 {
            margin-bottom: 0;
        }
        .link {
            padding: 0;
            font-size: 14px;
            line-height: 18px;
            color: #6fb554;
            display: inline;
            border: none;
            background: none;
            text-decoration: underline;
            cursor: pointer;
            transition: all 400ms;
            &:hover {
                color: #000;
                text-decoration: none;
            }
            &:focus {
                outline: none;
            }
        }
    }
}
.popup-wrapper.sign-modal {
    @media (max-height: 815px) {
        .popup {
            position: static;
            transform: translate(0, 0);
            margin: 50px auto;
        }
    }
    @media (max-width: 991px) {
        .popup {
            position: static;
            transform: translate(0, 0);
            margin: 50px auto;
        }
    }

    .password-verification li {
        font-size: 12px;
        line-height: 14px;
    }

    .popup-content {
        padding: 24px;
        padding-left: 264px;
        width: 100%;
        position: relative;
        text-align: left;

        @media (max-width: 991px) {
            padding-left: 24px;
        }

        @media (min-width: 992px) {
            &::before {
                content: "";
                width: 240px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url("../../../assets/images/signback.jpg");
                background-size: cover;
            }
        }

        a.big-link {
            font-weight: 600;
        }
        .big-link {
            padding: 0;
        }
    }

    &-form {
        .form-row {
            margin-right: -8px;
            margin-left: -8px;
        }
        .form-col {
            padding-left: 8px;
            padding-right: 8px;
        }
        .form-row + .form-row {
            margin-top: 24px;
        }
        //.form-group.error{
        //  margin-bottom: 20px;
        //}
        .button-group {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .sub-text-bottom {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        button {
            font-size: 12px;
            padding: 0;
            line-height: 18px;
            color: #6fb554;
            display: inline;
            border: none;
            background: none;
            text-decoration: underline;
            cursor: pointer;
            transition: all 400ms;
        }
    }

    .privacy-text {
        margin-top: 8px;
        font-size: 10px;
        line-height: 18px;
        color: #8f929a;
        div {
            display: inline;
        }
    }
}

.mWidth-226 {
    max-width: 226px !important;
}
