div.options-container{
    gap: 24px;
    background: #EFF7EA;
    border-radius: 12px;
    padding: 16px;
    margin: 0px 24px;

    @media (max-width: 990px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-top: 24px;

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}