@import "../../../../../../assets/styles/screen-size-variables.scss";

.paaas-app-footer{
    width: 100%;
    height: var(--paaas-footer-height);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #2C2C2D;
    padding: 40px 40px 30px 40px;
    gap: 30px;

    @media (max-width: $mobile-width) {
        padding: 40px 16px 16px 16px;
    }
}

.footer-content {
    &.left { text-align: left; }
    &.right { text-align: right; }
    &.center { text-align: center; }

    color: white;
    font-size: 14px;
    line-height: 20px;

    a {
        color: white;
        font-weight: 400;
    }

}

.paaas-footer-links-wrapper {
    display: flex;
    flex-wrap: wrap;

    div,p {
        color: #fff;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    span {
        // width: fit-content;
        font-weight: 600;
        letter-spacing: 0em;
    }

    button {
        color: white;
        text-decoration: underline;
        font-weight: 400;
        font-size: 14px;

        &:hover {
            opacity: 0.7;
        }
    }
}

.sparrow-footer-icon {
    display: flex;
    justify-content: flex-end;
}
