.FormContent {
 display: flex;
 flex-direction: column;
  gap: 16px;
}

.FormRowContent {
  display: flex;
  gap: 24px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

}