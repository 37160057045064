@import "./colors";

@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFPro/SFProDisplay-Bold.eot");
    src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
        url("../../assets/fonts/SFPro/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/fonts/SFPro/SFProDisplay-Bold.woff2") format("woff2"),
        url("../../assets/fonts/SFPro/SFProDisplay-Bold.woff") format("woff"),
        url("../../assets/fonts/SFPro/SFProDisplay-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFPro/SFProDisplay-Semibold.eot");
    src: local("SF Pro Display Semibold"), local("SFProDisplay-Semibold"),
        url("../../assets/fonts/SFPro/SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/fonts/SFPro/SFProDisplay-Semibold.woff2") format("woff2"),
        url("../../assets/fonts/SFPro/SFProDisplay-Semibold.woff") format("woff"),
        url("../../assets/fonts/SFPro/SFProDisplay-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "SF Pro Display";
    src: url("../../assets/fonts/SFPro/SFProDisplay-Regular.eot");
    src: local("SF Pro Display Regular"), local("SFProDisplay-Regular"),
        url("../../assets/fonts/SFPro/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/fonts/SFPro/SFProDisplay-Regular.woff2") format("woff2"),
        url("../../assets/fonts/SFPro/SFProDisplay-Regular.woff") format("woff"),
        url("../../assets/fonts/SFPro/SFProDisplay-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.intercom-launcher {
    // opacity: 0!important;
    // pointer-events: none;
    // z-index: -2 !important;
}
.intercom-launcher.intercom-launcher-active {
    opacity: 1;
    pointer-events: auto;
}
.blog-wrapper-with-menu {
    position: relative;
    .nested-navigation-wrapper {
        margin-top: 0;
        position: absolute;
        top: 134px;
        width: 100%;
        @media (max-width: 1300px) {
            top: 120px;
        }
    }
}

// Hubspot cookies block
div#hs-eu-cookie-confirmation {
    background: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(6px) !important;
    border: none !important;
    box-shadow: var(--mainBoxShadow) !important;
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
    background: transparent !important;
    color: white !important;
    font-size: 14px !important;
}

div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    color: inherit !important;
    margin: 0 !important;
}

div#hs-eu-cookie-confirmation
    div#hs-eu-cookie-confirmation-inner
    div#hs-eu-cookie-confirmation-button-group
    a {
    border-radius: 32px !important;
    font-size: 16px !important;
    padding: 14px 25px !important;
    transition: all 0.3s;

    &#hs-eu-confirmation-button {
        background: var(--greenColor) !important;
        border: none !important;

        @media (min-width: 768px) {
            &:hover {
                background: #7bc360 !important;
                box-shadow: var(--mainBoxShadow) !important;
            }
        }
    }

    &#hs-eu-decline-button {
        color: white;
        border-color: var(--greenColor) !important;

        @media (min-width: 768px) {
            &:hover {
                background-color: rgba(109, 179, 83, 0.3) !important;
            }
        }
    }
}

[data-nextjs-scroll-focus-boundary] {
    display: contents;
}

.transform-fix {
    transform: unset !important;
}

@import "fonts";
@import "colors";
@import "../fonts/sparrow-icon/style.css";
@import "degree-blocks";
@import "ssn";
@import "select";

*:focus {
    outline: none;
}
* {
    box-sizing: border-box;
}
.no-scroll {
    overflow: hidden;
}
a {
    text-decoration: none;
    color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
img {
    display: block;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
span,
p {
    white-space: pre-line;
}
button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-family: inherit;
    margin: 0;
}
input[type="text"],
input[type="email"],
input[type="submit"],
textarea {
    -webkit-appearance: none;
    outline: none;
    font-family: var(--mainFont);
    margin: 0;
}
* {
    box-sizing: border-box;
}
.hide {
    display: none;
}

.semi-bold {
    font-weight: 500;
}

/*Main blocks */
body {
    margin: 0;
    padding: 0;
    font-family: var(--mainFont), sans-serif;
    background-color: #f2f4f5;
    color: $mainColor;
    font-size: 16px;
    line-height: 24px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;
    }
    // Here is style for scrollbar
    /**
    scrollbar-width: thin;
    scrollbar-color: var(--greenColor);
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 100px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background-color: var(--greenColor);
    }

    */
}
.wrapper {
    //display: flex;
    //flex-direction: column;
    //min-height: 100vh;
    //overflow-x: hidden;
}
.content {
    flex: 1 0 auto;
}
.container {
    max-width: 1220px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    @media (max-width: 1270px) {
        max-width: calc(100% - 100px);
    }
    @media (max-width: 990px) {
        max-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (max-width: 767px) {
        //max-width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
}
.overflow-hidden {
    overflow: hidden !important;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
}
.col {
    padding-left: 15px;
    padding-right: 15px;
}
.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}
.col-8 {
  flex: 0 0 66.6666%;
  max-width: 66.6666%;
}
.col-3 {
    flex: 0 0 25%;
    width: 25%;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 990px) {
        padding-right: 35px;
    }
    @media (max-width: 990px) {
        flex: 0 0 50%;
        width: 50%;
    }
}
.col-4 {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    padding-left: 15px;
    padding-right: 15px;
}

.col-6 {
    flex: 0 0 50%;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: 990px) {
        &:not(&.not-padding) {
            padding-right: 30px;
        }
    }
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.pl-20px {
    padding-left: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.text-green {
    color: $greenColor;
}
.text-dark-green {
    color: $darkGreenColor;
}
.text-red {
    color: $redColor;
}
.text-dark-red {
    color: $darkRedColor;
}
.text-yellow {
    color: $yellowColor;
}
.text-orange {
    color: $orangeColor;
}
.text-blue {
    color: $blueColor;
}
.text-grey {
    color: $greyColor;
}
.back-green {
    background-color: $darkGreenColor;
    color: $whiteColor;
}
.back-grey {
    background-color: #e8e9eb;
    color: $mainColor;
}

.text-right {
    text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.h1,
.h2,
h1,
h2 {
    font-weight: 600;
    color: #1e2534;
}

.h1,
h1 {
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 24px;
}

.h2,
h2 {
    font-size: 40px;
    line-height: 48px;

    @media (max-width: 990px) {
        font-size: 32px;
        line-height: 40px;
    }

    &.h2-app {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
        &.offers-title {
            span {
                text-transform: lowercase;
            }
        }
        @media (max-width: 991px) {
            font-size: 28px;
            line-height: 32px;
        }
    }
}

.h3,
h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.h4,
h4 {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    font-weight: 600;
}

.muted {
    color: #8e9399;
}

a {
    text-decoration: none;
    transition: all 0.4s;
}

a.little,
li.little,
p.little {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.layout-row {
    display: flex;
    .chart-title {
        display: flex;
        align-items: center;
        .tooltip-wrapper {
            margin-left: 8px;
        }
    }
    
}

.space-between {
    justify-content: space-between;
}

.layout-column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}

.head-info {
    width: 100%;
    @media (max-width: 990px) {
        .h2-app {
            margin-bottom: 0;
        }
    }
    &__title {
        position: relative;
        padding-right: 42px;
    }
    &__dots {
        position: absolute;
        right: 0;
        top: 0;
    }
    .menu-dots {
        background-color: transparent;
    }
    .menu-dots--opened {
        left: auto;
        right: 0;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.h3 + .muted {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    color: #8f929a;
    margin-bottom: 24px;
}

.connect-card {
    background: $whiteColor;
    box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    margin-bottom: 40px;
    @media (min-width: 767px) and (max-width: 1024px) {
        order: 3;
        width: calc(50% - 16px);
        margin-right: 32px;
        justify-content: space-between;
        &:last-child {
            margin-right: 0;
            justify-content: flex-start;
        }
    }
    img {
        max-height: 160px;
        margin-bottom: 16px;
        margin-top: 8px;
    }
    h4 {
        font-weight: 600;
        margin: 16px 0 8px;
    }
    .btn-primary {
        margin-top: 24px;
        @media (max-width: 767px) {
            margin-top: 16px;
        }
    }
    .close {
        position: absolute;
        top: 18px;
        right: 18px;
        cursor: pointer;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.password-verification {
    list-style: none;
    padding: 0;
    margin: 32px 0 0 0;

    @media (max-width: 767px) {
        &-wrapper.form-col-6.form-col {
            margin-top: 0 !important;
            + div .password-verification {
                margin-top: 8px;
            }
        }
    }

    li {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #8f929a;
        display: flex;
        align-items: center;
        & + li {
            margin-top: 8px;
        }
        span {
            width: 16px;
            height: 16px;
            transition: opacity 400ms;
            opacity: 0.5;
            background: url(../../assets/images/check.svg) center center no-repeat;
            background-size: 100% auto;
            margin-right: 8px;
        }
        &.valid {
            span {
                opacity: 1;
            }
        }
    }
}

.black-500 {
    color: $mainColor;
    font-weight: 500;
}

.page-slider-wrapper {
    &.normalScroll {
        //.fullpage-wrapper {
        //  height: auto !important;
        //  transform: none !important;
        //  .fp-section {
        //    height: auto !important;
        //    position: relative;
        //  }
        //}
    }
}
//body {
//  &.fp-responsive {
//    overflow: visible !important;
//    height: initial !important;
//    .fp-scroller {
//      height: auto !important;
//      transform: translate(0,0) !important;
//    }
//    .iScrollVerticalScrollbar {
//      display: none!important;
//    }
//  }
//}

.address-section {
    background-color: #edf2f6;
    border-radius: 12px;
    padding: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    @media (max-width: 767px) {
        span {
            display: block;
        }
    }

    h4 {
        font-weight: 400;
        line-height: 24px;
    }
}

.code-input-email {
    display: flex;
    max-width: 100%;
    margin-right: 24px;
    @media (max-width: 767px) {
        margin-right: 0;
    }

    .input {
        width: 16%;
        height: 68px;
        + div {
            margin-left: 16px;
            @media (max-width: 767px) {
                margin-left: 10px;
            }
        }
        input {
            margin: 0;
            height: 100%;
            width: 100%;
            outline: none;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #1e2534;
            font-weight: 600;
        }
    }
}

iframe {
    z-index: -1!important;
}

.sign-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: #f2f4f5;

    .sign-left-side {
        height: 100vh;
        min-height: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        // background: url(../../../assets/images/sign-in/back.jpg) center center no-repeat;
        background-size: cover;
        flex: 0 0 100%;
        max-width: 560px;
        position: sticky;
        top: 0;
        left: 0;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        &.has-nest {
            display: block;
            .sign-left-side-inner {
                padding: 200px 64px 100px 64px;
                position: relative;
                @media (min-width: 990px) {
                    &.has-header-bar {
                        padding-top: 64px;
                    }
                }
                @media (max-width: 1200px) {
                    @media (min-width: 991px) {
                        padding-left: 40px;
                        padding-right: 40px;
                        .logo {
                            left: 40px;
                        }
                    }
                }
                @media (max-width: 990px) {
                    padding: 104px 72px 41px 71px;
                }
                @media (max-width: 767px) {
                    padding: 150px 20px 41px 20px;
                }
                @media (max-width: 560px) {
                    padding: 30px 18px;
                }
            }
        }
        &-inner {
            padding: 64px;
        }
        .h1 {
            color: $whiteColor;
            @media (max-width: 1200px) {
                font-size: 40px;
                line-height: 50px;
            }
        }
        &.sign-left-illustration {
            background: url(../images/sign-in/left-bg.jpg) center center no-repeat;
            background-size: cover;
            @media (max-width: 990px) {
                background-image: url(../images/sign-in/back-tablet.png);
                height: 400px;
            }
            .sign-left-side-inner {
                padding: 0;
            }
            .sign-illustration {
                width: 100%;
                margin-top: -90px;
                img {
                    width: 100%;
                    height: auto;

                    @media (max-width: 990px) {
                        width: auto;
                        max-height: 400px;
                    }
                }
            }
        }
        &.sign-left-side-approve {
            background-image: url("../images/back-bubles.jpg");
        }
        @media (max-width: 1200px) {
            max-width: 400px;
        }
        @media (max-width: 990px) {
            max-width: 100%;
            position: relative;
            height: auto;
            min-height: 0;
            background-image: url(../images/sign-in/back-tablet.png);
            .h1 {
                max-width: 370px;
                text-align: center;
            }
            &-inner {
                padding: 104px 72px 41px 71px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
        @media (max-width: 600px) {
            display: none;
        }
        &.sign-wrapper-customize {
            height: auto;
            min-height: calc(100vh - 272px);
            .logo {
                img {
                    max-height: 70px;
                }
            }
            .powered {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 16px;
                color: $whiteColor;
                margin-top: 24px;
                img {
                    max-height: 16px;
                    width: auto;
                    margin-left: 16px;
                }
            }
            .sign-left-side.has-nest {
                .sign-left-side-inner {
                    padding-top: 100px;
                }
            }
        }
    }
    .borrower-background {
        background: url(../images/backgrounds/borrower-bg.png) center center no-repeat;
        background-size: cover;
    }
    .cosigner-background {
        background: url(../images/backgrounds/cosigner-bg.png) center center no-repeat;
        background-size: cover;
    }

    .sign-right-side {
        flex: 0 0 100%;
        max-width: calc(100% - 560px);
        padding: 64px;
        @media (max-width: 1300px) {
            padding: 64px 30px;
        }
        @media (max-width: 1200px) {
            max-width: calc(100% - 400px);
        }
        @media (max-width: 990px) {
            max-width: 100%;
            padding: 45px 80px 80px 80px;
        }
        @media (max-width: 767px) {
            padding: 50px 30px 40px 30px;
            min-height: 100vh;
            min-height: -webkit-fill-available;
        }
    }

    .logo {
        position: absolute;
        left: 64px;
        top: 64px;
        @media (max-width: 990px) {
            left: 40px;
        }
        @media (max-width: 560px) {
            left: 19px;
            top: 45px;
        }
    }
}

.mobile-logo {
    display: none;
    padding: 50px 20px 0 20px;
    position: absolute;
    border-bottom-left-radius: 0;
    top: 0;
    @media (max-width: 600px) {
        display: block;
    }
}

.steps-form {
    @media (max-width: 600px) {
        //height: 100%;
    }
    &-header {
        display: flex;
        align-items: center;
        padding-bottom: 56px;

        @media (max-width: 990px) {
            padding-bottom: 44px;
        }
        @media (max-width: 767px) {
            display: flex;
            flex-wrap: wrap;
        }
        @media (max-width: 600px) {
            flex-wrap: wrap;
        }
        .back-home {
            display: flex;
            align-items: center;
            margin-right: 64px;
            color: $mainColor;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            span {
                margin-right: 20px;
                font-size: 22px;
                transition: all 400ms;
            }
            &:hover {
                span {
                    transform: translateX(-10px);
                }
            }
        }
        .steps {
            flex: 1 0 auto;
            @media (max-width: 767px) {
                flex: 0 0 100%;
                margin-top: 26px;
            }
        }
    }
}

.step-form {
    &:not(.show) {
        display: none;
    }
    &-header {
        margin-bottom: 32px;
        @media (max-width: 600px) {
            margin-bottom: 24px;
        }
        .h2 {
            margin-bottom: 8px;
            @media (max-width: 600px) {
                font-size: 28px;
                line-height: 36px;
            }
        }
        div {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #8f929a;
        }
    }

    &-underbody {
        margin-top: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #8f929a;
    }

    &-footer {
        @media (max-width: 600px) {
            display: block;
            .link-btn div {
                margin-bottom: 40px;
            }
        }
    }

    &-inner {
        .wrapper-anim {
            position: relative;

            .fade-enter {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                transform: scale(1.05);
            }

            .fade-enter-active {
                opacity: 1;
                transform: scale(1);
                transition: opacity 200ms ease-in, transform 200ms ease-in;
            }

            .fade-exit {
                opacity: 1;
                transform: scale(1);
            }

            .fade-exit-active {
                opacity: 0;
                transform: scale(0.95);
                transition: opacity 200ms ease-out, transform 200ms ease-out;
            }
        }
    }
    &-body {
        margin-bottom: 32px;

        &.degree-mod .degree-link {
            color: rgba(143, 146, 154, 1);
            font-weight: 500;
            font-size: 14px;
            text-decoration: underline;
            margin-top: 24px;
            cursor: pointer;
            transition: all 300ms ease;

            &:hover {
                color: #6fb554;
            }
        }

        &.new-loan {
            .radio-row {
                display: flex;
                flex-wrap: wrap;
                margin-left: -8px;
                margin-right: -8px;

                .radio-col {
                    flex: 0 0 25%;
                    max-width: 25%;
                    padding-left: 8px;
                    padding-right: 8px;
                    @media (max-width: 767px) {
                        flex: 0 0 50%;
                        max-width: 50%;
                        &:nth-child(3),
                        &:nth-child(4) {
                            margin-top: 16px;
                        }
                    }
                }
                .radio-image-block {
                    height: 100%;
                    input {
                        opacity: 0;
                        position: absolute;
                        z-index: -1;

                        &:checked ~ label {
                            border: 2px solid #6fb554;
                        }
                    }
                    label {
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 16px;
                        padding: 24px;
                        cursor: pointer;
                        border: 2px solid #fff;
                        transition: all 0.4s;

                        &:hover {
                            border: 2px solid #6fb554;
                        }
                    }

                    .radio-image {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -webkit-justify-content: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        img {
                            display: block;
                            height: 69px;
                            width: auto;
                            max-width: 100%;
                        }
                    }
                    .radio-text {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: #1e2534;
                        display: block;
                        text-align: center;
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    &-block {
        background-color: $whiteColor;
        border-radius: 16px;
        padding: 40px;
        @media (max-width: 600px) {
            padding: 24px;
        }
        &--consider {
            padding: 24px 0;
        }
        .h3 {
            margin-bottom: 24px;
        }
        & + .step-form-block {
            margin-top: 32px;
        }
        &-revers__additional,
        &-additional {
            display: none;
        }
        &--add-degree {
        }
        &.more-about-school-adjust {
            padding: 30px 40px 40px;

            h3 {
                margin-bottom: 8px;
            }
            .text-grey {
                font-size: 14px;
                margin-bottom: 24px;
            }
        }

        .review-title {
            position: relative;
            padding-right: 40px;

            .menu-dots,
            .edit-step {
                position: absolute;
                top: -4px;
                right: 0;
            }
        }

        & + .total-assets {
            margin-bottom: 0;
            margin-top: 24px;
            display: flex;
            align-items: center;
            .form-col {
                flex: 0 0 auto;
                max-width: none;
                padding-left: 5px;
                padding-right: 0;
                .form-group {
                    margin-bottom: 0;
                }
                input {
                    border: none !important;
                    padding: 0;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: $greenColor;
                    background: transparent;
                }
            }
        }

        .amount-range {
            margin-top: 24px;
            padding-bottom: 28px;
            position: relative;
            .val {
                font-size: 16px;
                line-height: 20px;
                position: absolute;
                bottom: 0;
                color: rgba(#000f2b, 0.3);
                &.min-val {
                    left: 0;
                }
                &.max-val {
                    right: 0;
                }
            }
        }
    }
    &-transparent {
        background: transparent;
        padding: 0;
        & + .step-form-block {
            margin-top: 16px;
        }
    }
    .button-group {
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column-reverse;
        }
        .verification-btn,
        [class*="btn-"] {
            @media (max-width: 600px) {
                width: 100%;
                margin-bottom: 10px;
            }
            & + .verification-btn,
            & + [class*="btn-"] {
                margin-left: 15px;
                @media (max-width: 600px) {
                    margin-left: 0;
                }
            }
        }
        &-privacy {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 990px) {
                flex-wrap: wrap-reverse;
            }
            .btn-primary {
                @media (max-width: 990px) {
                    width: 100%;
                }
            }
            .privacy-text {
                max-width: calc(100% - 250px);
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: $mainColor;
                @media (max-width: 990px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-top: 24px;
                }
                a {
                    color: $greenColor;
                    text-decoration: underline;
                    transition: all 400ms;
                    &:hover {
                        color: $mainColor;
                    }
                }
            }
        }
    }
}

.form-row + .form-row,
.single-offer {
    margin-top: 32px;
}

.forgot-password {
    text-align: right;
    margin-top: 8px;
}

.steps-form-header .steps {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .step {
        flex: 1 0 auto;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        position: relative;
        height: 14px;

        &:last-child {
            max-width: 14px;
            margin-right: 0;
            &::before,
            &::after {
                display: none;
            }
        }
    }

    .step span,
    .step:before {
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        background-color: #d8d8d8;
        left: 0;
    }

    .step:before {
        content: "";
        top: 50%;
        transform: translateY(-50%);
    }

    .step:after {
        content: "";
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        background-color: transparent;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        max-width: 0;
    }

    // .step.active span {
    //     background-color: #6fb554;
    // }

    .step.active.borrower span {
        background-color: $darkGreenColor;
    }
    .step.active.cosigner span {
        background-color: $cosignerPrimaryColor;
    }

    .step.active:not(.current) span,
    .step.allow-pointer span {
        cursor: pointer;
        display: inline-block;
        z-index: 2;
    }

    // .step.active:not(.current)::after {
    //     max-width: 100%;
    //     background-color: #6fb554;
    // }
    .step.active.borrower:not(.current)::after {
        max-width: 100%;
        background-color: $darkGreenColor;
    }
    .step.active.cosigner:not(.current)::after {
        max-width: 100%;
        background-color: $cosignerPrimaryColor;
    }

    .step span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 15px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        display: block;
    }

    .step.current.borrower span {
        width: 8px;
        height: 8px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 0 5px $darkGreenColor;
        box-shadow: 0 0 0 5px $darkGreenColor;
    }

    .step.current.cosigner span {
        width: 8px;
        height: 8px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 0 5px $cosignerPrimaryColor;
        box-shadow: 0 0 0 5px $cosignerPrimaryColor;
    }

    .step:after,
    .step:before {
        position: absolute;
        width: 100%;
        height: 2px;
    }
}

.text-with-link {
    margin-top: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #8f929a;

    .link-btn > div {
        transition: color 200ms ease;
    }
    .link-btn,
    .link-btn > div {
        display: inline;
        font-weight: 400;
        cursor: pointer;
    }
}



