.GrayQuestionmarkIcon path {
    transition: all 0.3s ease;
}

.GrayQuestionmarkIcon:hover {
    cursor: pointer;
}

.GrayQuestionmarkIcon:hover path {
    fill: rgba(var(--borrowerAccentColor), 1);
}