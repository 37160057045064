:root {
    // Fonts ---------------------------------- Start //
    --titleFont: "Basier Circle", sans-serif;
    --mainFont: "Basier Circle", sans-serif;
    --headsFont: "Basier Circle", sans-serif;
    --buttonFont: "Basier Circle", serif;
    // Fonts ---------------------------------- End //

    // Colors --------------------------------- Start //
    --invertedColor: white; // new
    --invertedColorRGB: 0, 0, 0; // new
    --borrowerAccentColor: 109, 179, 83; // new
    --accentColorRGB: 255, 65, 65; // new
    --accentColorHover: 45, 143, 67; // new
    --mainColor: #1e2534;
    --mainColorRGB: 30, 37, 52;
    --borrowerPrimaryColor: 109, 179, 83;
    --primaryColorRGB: 45, 143, 67;
    --primaryHoverColor: #32a74c;
    --primaryHoverColorRGB: 50, 167, 76;
    --secondaryColor: #32a74c;
    --secondaryColorRGB: 50, 167, 76;
    --secondaryHoverColor: #7ef899;
    --secondaryHoverColorRGB: 126, 248, 153;
    --secondaryColor2: #d3e1d0;
    // Colors --------------------------------- End //

    // Buttons -------------------------------- Start //
    // Fonts
    --borrowerButtonPrimaryFont: "Basier Circle", sans-serif;
    --borrowerButtonSecondaryFont: "Basier Circle", sans-serif;
    --borrowerLinkFont: "Basier Circle", sans-serif;

    // Colors
    --borrowerButtonPrimaryColor: #fff; // new
    --borrowerButtonPrimaryBorderColor: #2d8f43; // new
    --borrowerButtonPrimaryBackground: #2d8f43; // new
    --buttonPrimaryHoverColor: #2d8f43; // new

    --borrowerButtonSecondaryColor: #2d8f43; // new
    --borrowerButtonSecondaryBorderColor: #2d8f43; // new
    --borrowerButtonSecondaryBackgroundColor: transparent; // new
    --buttonSecondaryHoverColor: #2d8f43; // new
    --buttonSecondaryHoverColorText: #2d8f43; // new

    --borrowerLinkColor: #2d8f43; // new

    // Other
    --borrowerButtonPrimaryBorderRadius: 16px; // new
    --borrowerButtonPrimaryTextTransform: inherit; // new

    --borrowerButtonSecondaryBorderRadius: 16px; // new
    --borrowerButtonSecondaryTextTransform: inherit; // new

    --borrowerLinkTextTransform: inherit; // new
    --borrowerLinkTextDecoration: underline; // new
    // Buttons -------------------------------- End //

    // Common ---------------------------------------- Start //
    --logoSize: 60px; // new
    --topMenuAlign: center; //flex-end | center | flex-start // new
    --blackColor: #000;
    --blackColorRGB: 0, 0, 0;
    --whiteColor: #fff;
    --whiteColorRGB: 255, 255, 255;
    --errorColor: #ff4141;
    --darkRedColor: #db2f2f;
    --lightGreyPrimaryColor: #f5f5f5;
    --lightGreyColor: #d8d8d8;
    --darkGreyColor: #8f929a;
    --blueColor: #2e7cc3;
    --yellowColor: #ffb444;
    --orangeColor: #f19048;
}
// Common ---------------------------------------- End //

$redGradient: linear-gradient(254.6deg, #ff4141 0%, #db2f2f 100%);
$orangeGradient: linear-gradient(254.6deg, #f4a645 0%, #f06e2d 100%);
