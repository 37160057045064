.request-history-wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 100%;
    
    @media (max-width: 1024px) {
        position: relative;
        z-index: 3;
    }

    @media (max-width: 767px) {
        align-items: unset;
        gap: 8px;
    }

    @media (max-width: 767px) {
        margin-top: 34px;
    }
}

.request-history-container {
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    filter: drop-shadow(0px 20px 60px rgba(30, 37, 52, 0.1));
    flex-direction: column;
    width: 364px;
    top: 25px;
    position: absolute;
    z-index: 1;

    @media(max-width: 767px) {
        position: static;
        width: auto;
    }
}
