@import "../../../assets/styles/colors";

.notification_status{
  position: fixed;
  max-width: 600px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100000;
  img{
    cursor: pointer;
  }

  &.signUp {
    margin-top: -115px !important;
  }

  &.green{
    color: $greenColor;
  }
  &.error{
    color: $redColor;
  }

  &--auth {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 570px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    z-index: 100000;
    margin-top: -55px;

    a {
      display: block;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 767px) {
        max-width: 100%;
      }

    &.success {
      background: #DCF8D2;
      color: #6DB353;
    }

    &.error {
      background: #FFE4E5;
      color: #ED1C24;
    }
  }
}
