.react-international-phone-input-container{
    width: 100%;
    align-items: center;
}
.react-international-phone-country-selector{
    height: 20px;
}

.react-international-phone-country-selector-button{
    border: none;
    height: fit-content;
    display: flex;
    align-items: center;
}

.react-international-phone-input-overwrite{
    border: none;
    border-bottom: none;
    font-size: 16px;
    padding-left: 8px;
}

.react-international-phone-country-selector-button__button-content{
    height: 20px;
}