.offer-filter-dropdown-select-container {
    background-color: white;
    color: black;
    transition: all 400ms;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-weight: 500;
    line-height: 32px;
    z-index: 1;

    pointer-events: auto;
    z-index: 999;
    min-width: 270px;

    white-space: nowrap;
    gap: 8px;
    @media (max-width: 767px) {
        z-index: unset;
        overflow: visible;
    }
    .offer-filter-dropdown-select-title-container {
        padding-bottom: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #151b26;
        white-space: nowrap;
        .offer-filter-dropdown-select-title {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            padding-right: 10px;
        }
    }

    .offer-filter-dropdown-select-footer-container {
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        .offer-filter-dropdown-select-footer-text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #ccd6dd;
            justify-content: flex-end;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 4px;
            padding-bottom: 4px;
            cursor: pointer;

            &.active {
                color: #9ea9b0;
                &:hover {
                    color: #2b323f;
                }
            }

            &:hover {
                color: #9ea9b0;
            }
        }
    }
}

.offer-filter-checkbox {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
}

.checkbox-label-scrollable {
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2b323f;
    margin-top: 0 !important;

    &.selected {
        &.borrower{
            color: rgba(var(--borrowerPrimaryColor), 1);
        }

        &.cosigner{
            color: rgba(var(--cosignerPrimaryColor), 1);
        }
    }
}

.legend_select_icon {
    cursor: pointer;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: 1px solid #abb6bd;
    border-radius: 100%;
    -webkit-transition: border 500ms ease-out;
    -moz-transition: border 500ms ease-out;
    -o-transition: border 500ms ease-out;
    transition: border 500ms ease-out;

    &.selected {
        border: 7px solid;
    }
}

.legend_select_icon_multi {
    cursor: pointer;
    border: 1px solid #abb6bd;
    border-radius: 4px;

    width: 24px;
    height: 24px;
}

.dropdown-checkbox {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.dropdown-tooltip-container {
    width: 246px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #151b26;
    max-height: 300px;
    text-align: left;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    gap: 4px;
    white-space: normal;
    .dropdown-tooltip-text {
        font-style: normal;
        word-wrap: break-word;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: #151b26;
    }
    .dropdown-tooltip-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #151b26;
    }
    @media (max-width: 767px) {

    }
}
.filter-tooltip {
    @media (max-width: 767px) {
        position: relative;
        z-index: 9;
        .bubble-wrapper-tooltip {
            padding: 16px;
            border-radius: 16px;
            background-color: var(--whiteColor);
            z-index: 9999;
            left: -30px;
            top: auto;
            bottom: calc(100% + 20px);
            margin-top: 0;
            &::before {
                content: '';
                position: absolute;
                left: 25px;
                bottom: -9px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 0 10px;
                border-color: #ffffff transparent transparent transparent;
            }
            .box {
                position: static;
            }
        }
    }
}

.dropdown-tooltip-icon {
    width: 13px;
    height: 13px;
    cursor: pointer;
    z-index: 901;
}

.mobile-filter {
    .offer-filter-dropdown-select-container {
        background-color: transparent;

        .offer-filter-dropdown-select-title {
            display: flex;
            align-items: center;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            min-height: 28px;
            padding-right: 70px;
            position: relative;
        }
        .offer-filter-dropdown-select-footer-text {
            position: absolute;
            right: 0;
            top: 0;
            padding: 4px 16px;
            border-radius: 16px;
            background-color: var(--neutralColor100);
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #525966;
            transition: all 400ms;
            opacity: 0;
            pointer-events: none;
            &.active {
                opacity: 1;
                pointer-events: auto;
            }
        }
        .filter-img {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}