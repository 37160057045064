.PencilIcon path {
    transition: all 0.3s ease;
}

.PencilIcon:hover {
    cursor: pointer;
}

.PencilIcon.borrower:hover path {
    fill: rgba(var(--borrowerPrimaryColor), 1);
}

.PencilIcon.cosigner:hover path {
    fill: rgba(var(--cosignerPrimaryColor), 1);
}