.radioBtnsContainer {
    display: flex;
    margin-bottom: 24px;
}

.txt{
 font-size: 14px;
  line-height: 18px;
  color: var(--neutralColor500);
  margin-bottom: 16px;
  margin-top: 24px;   
}

.radioContainer {
    position: relative;
    padding: 8px 30px 8px 32px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
}

.radioInput {
    width: 24px;
    height: 24px;
    border: 1px solid var(--neutralColor300);
    background: var(--whiteColor) center center no-repeat;
    position: absolute;
    top: 8px;
    left: 0;
    transition: all .4s;
    border-radius: 50%;

    &.active {
        border: 1px solid rgba(var(--borrowerPrimaryColor),1);
        background: rgba(var(--borrowerPrimaryColor),1) center center no-repeat;
        background-image: url("../../../../../../../assets/images/static/check-white.svg");
    }
}

.radioInputLabel{
    cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}