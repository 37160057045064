p.cs-pu-category {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #6b717b;
}

p.cs-pu-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #151b26;
}
