/*
Colors
 */
$mainColor: #1e2534;
$greenColor: #78bd54;
$greenSecondColor: #59AF6C;
$darkGreenColor: #6FB553;
$greenGradient: linear-gradient(254.6deg, #8CCE56 0%, #57A051 100%);
$cosignerPrimaryColor: #DC7962;
$bgColor: #E5E5E5;
$mutedColor: #8E9399;
$greyColor: #8F929A;
$iconColor: #D8D8D8;
$greySecondColor: #5D5F62;
$greyThirdColor: #C7C8CC;
$whiteColor: #fff;
$blackColor: #000;
$lightColor: #F2F4F5;
$yellowColor: #FFB444;
$blueColor: #6177E8;
$blueColor01: #4A7FE8;
$orangeColor: #F19048;
$redColor: #FF4141;
$darkRedColor: #DB2F2F;
$redGradient: linear-gradient(254.6deg, #FF4141 0%, #DB2F2F 100%);
$orangeGradient: linear-gradient(254.6deg, #F4A645 0%, #F06E2D 100%);

$neutral50: #F6F9FC; 
$neutral300: #ABB6BD;
$neutral400: #9EA9B0;
$neutral500: #8E9399;

.clr-neutral-50 {
    color: $neutral50;
}

.clr-neutral-300 {
    color: $neutral300;
}

.clr-neutral-400 {
    color: $neutral400;
}

.clr-neutral-500 {
    color: $neutral500;
}

.clr-white {
    color: $whiteColor;
}