.for-you-filter-block {
    .trigger-filter {
        display: none;
    }
    @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .trigger-filter {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19.6px;
            padding: 0;
            border: none;
            color: var(--primaryColor2);
            position: relative;
            &.active {
                &.borrower{
                    span {
                        color: rgba(var(--borrowerPrimaryColor), 1);
                    }
                    &::before {
                        background-color: rgba(var(--borrowerPrimaryColor), 1);
                    }
                }

                &.cosigner{
                    span {
                        color: rgba(var(--cosignerPrimaryColor), 1);
                    }
                    &::before {
                        background-color: rgba(var(--cosignerPrimaryColor), 1);
                    }
                }

                &::before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border: 2px var(--whiteColor) solid;
                    position: absolute;
                    right: -3px;
                    top: -3px;
                }
            }
        }
    }
}

.for-you-sort-button-container {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    @media (max-width: 1380px) {
        justify-content: flex-start;
    }
    @media (max-width: 767px) {
        padding-right: 32px;
        position: relative;
    }

    span {
        color: rgba(var(--borrowerPrimaryColor), 1);

        transition: all 400ms;
    }
    &.active {
        span {
            transform: rotate(-180deg);
        }
    }

    .for-you-sort-by-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #878f99;
    }
    .for-you-sort-by-option {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        text-align: center;
        color: rgba(var(--borrowerPrimaryColor), 1);
        padding-right: 3px;
    }
}
