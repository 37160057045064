@import "../../../assets/styles/variables";
@import "../../../assets/styles/screen-size-variables.scss";
@import "../LeftNavigationBar/style.scss";

.logo {
    img {
        height: 55px;
        width: auto;

        position: relative;
        @media (max-width: 1024px) {
            left: 0;
        }
    }

    &.landingFullSizePreview{
        img {
            width: auto;
            height: auto;
            max-height: 40px;
            max-width: 130px;
        }
    }
    @media (max-width: 767px) {
        img {
            height: 30px;
            width: auto;
            left: auto;
        }
    }
}
