.form-group.form-group--focused .input-select .input-select__control {
    border-color: #1e2534 !important;
}
.input-select {
    .input-select__control {
        padding-top: 8px;
        cursor: pointer;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid rgba(30, 37, 52, 0.2);
        height: 44px;
        box-shadow: none;

        &:hover {
            border-color: #1e2534;
        }

        .input-select__placeholder {
            color: rgba(30, 37, 52, 0.25);
        }

        & > div {
            &:first-of-type {
                //margin-top: 8px;
                margin-bottom: 16px;
                padding: 0 !important;
                position: relative;
                padding-right: 20px;

                &.input-select__value-container--has-value > div {
                    color: #1e2534 !important;
                }

                &::after {
                    content: "\e932";
                    position: absolute;
                    font-size: 14px;
                    font-family: icomoon !important;
                    top: -2px;
                    right: 0;
                    transition: all 200ms ease;
                }

                & > div {
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0 !important;
                }
                //
                //&:before {
                //  content: "\e916";
                //  position: absolute;
                //  font-size: 14px;
                //  font-family: icomoon!important;
                //  top: 0;
                //  left: 0;
                //}
            }
            &:last-of-type {
                display: none;
            }
        }
    }

    &__control--menu-is-open {
        // background-color: red;
        .input-select__value-container {
            &::after {
                transform: scale(1, -1);
            }
        }
    }

    &__menu {
        margin: 0 !important;
        border-radius: 0 0 16px 16px !important;
        border: none !important;
        overflow: hidden;
        box-shadow: 0 24px 64px rgba(30, 37, 52, 0.1) !important;
        padding: 0px;
        
        &-list {
            padding: 0 0 10px !important;

            > div {
                background-color: #fff;
                padding: 16px;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;

                &.input-select__option--is-selected,
                &.input-select__option--is-focused {
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid #edf2f6;
                }
            }
        }
    }
}

.steps-form {
    .input-select__menu {
        margin-bottom: 36px !important;
    }
}
