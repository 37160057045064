@import "../../../assets/styles/colors";

@mixin baseIcon {
  width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    border: none;
    background: rgba(#fff, .5);
    border-radius: 8px;
    color: #151B26;
    transition: all 400ms;
    cursor: pointer;
    padding: 0;
    justify-content: center;
    span {
      pointer-events: none;
    }
}

.card-btn {
    @include baseIcon;
    &:hover {
      color: #1E2534;
      background: #fff;
    }
    & + .card-btn {
      margin-left: 8px;

    }
    &.card-delete {
      &:hover {
        color: red;
      }
    }
  }

  .edit-icon-review {
    @include baseIcon;

    &.borrower:hover {
      color: $darkGreenColor;
    }

    &.cosigner:hover {
      color: $cosignerPrimaryColor;
    }
  }