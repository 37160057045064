.close-modal {
    font-size: 16px;
    color: var(--primaryColor2);
    cursor: pointer;
    transition: all 400ms;
    &:hover {
        color: var(--greenColor);
    }
}

.popup-wrapper {
    .close-modal {
        right: 24px;
        top: 24px;
        position: absolute;
        padding: 0;
        font-size: 16px;
        color: var(--primaryColor2);
        cursor: pointer;
        transition: all 400ms;
        &:hover {
            color: var(--greenColor);
        }

        @media (max-width: 767px) {
            width: 24px;
            height: 24px;
            font-size: 16px;
            z-index: 10;

            &.right {
                right: 16px;
            }

            &.left {
                left: 16px;
            }
        }
    }
    &.popup-text {
        .popup {
            width: calc(100% - 160px);
            max-width: 888px;
            @media (max-width: 767px) {
                width: 100%;
                max-width: calc(100% - 80px);
            }
            @media (max-width: 575px) {
                max-width: calc(100% - 40px);
            }
            .popup-content {
                text-align: left;
                width: 100%;
                padding: 64px 64px 40px 64px;
                position: relative;
                @media (max-width: 767px) {
                    padding: 40px 40px 30px 40px;
                }
                @media (max-width: 575px) {
                    padding: 40px 20px;
                }
            }
            .h3 {
                margin-bottom: 24px;
            }
            h4 {
                margin-bottom: 8px;
                &.text-underline {
                    text-decoration: underline;
                }
            }
            p {
                margin-bottom: 24px;
                a {
                    color: #78bd54;
                    text-decoration: underline;
                    &:hover {
                        color: #ddd;
                    }
                }
            }
            .h4 {
                margin-bottom: 8px;
            }
        }
        .scroll-content {
            height: calc(100vh - 350px);
        }
        .ScrollbarsCustom-Scroller {
            padding-right: 35px !important;
        }
    }
    &.modal-blur {
        background: transparent;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            background: rgba(#1e2534, 0.5);
            backdrop-filter: blur(15px);
            opacity: 1;
            pointer-events: none;
        }
    }
    &.review-modal {
        .popup-content {
            padding: 40px;
            width: 100%;
        }
    }

    .popup-content {
        position: relative;

        .close-modal {
            position: absolute;
            right: 24px;
            top: 24px;
            background: transparent;
            border: none;
            font-size: 16px;
            padding: 0;
            color: #8f929a;
            cursor: pointer;
            transition: all 400ms;
            &:hover {
                color: #1e2534;
            }
        }

        div {
            @media (max-width: 767px) {
                &:not(.profile-modal-content, .profile-modal-content div) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                }
            }
        }
    }
    &.popup-wrapper-mobile-view {
        @media (max-width: 767px) {
            .popup {
                position: fixed;
                bottom: 0;
                left: 0;
                top: auto;
                max-width: 100%;
                transform: translate(0, 0);
                border-radius: 16px 16px 0 0;
                width: 100%;
                max-height: calc(var(--viewport-height) * 0.9);
                overflow-y: auto;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }

                .popup-content {
                    padding: 56px 16px 38px 16px;
                }
            }
        }
    }
    &.modal-sort {
        .popup {
            width: 100%;
        }
        .popup-content-no-padding {
            width: 100%;
            padding: 24px 16px 37px 16px;
        }
        .bubble-wrapper-sort {
            width: 100%;
            .box {
                position: static;
                background-color: transparent;
                box-shadow: none;
                filter: none;
                padding: 0;
                .offer-filter-dropdown-select-footer-container {
                    display: none;
                }
            }
        }
        .offer-filter-dropdown-select-footer-text {
            display: none;
        }
        .offer-filter-dropdown-select-container
            .offer-filter-dropdown-select-title-container
            .offer-filter-dropdown-select-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
        }
        .offer-filter-dropdown-select-container .offer-filter-dropdown-select-title-container {
            padding-bottom: 16px;
        }
        .btn-green {
            width: 100%;
            margin-top: 24px;
            .icon-check {
                font-size: 10px;
                margin-left: 8px;
            }
        }
    }
    &.md {
        .popup {
            width: 100%;
            max-width: 652px;
        }
        .close-modal {
            right: 32px;
            top: 32px;
            width: 24px;
            height: 24px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            z-index: 2;
            @media (max-width: 767px) {
                right: 16px;
                top: 24px;
            }
        }
    }
}
.modal-content {
    &.popup-disclosure {
        height: 100%;

        .popup-disclosure-container {
            height: 100%;
            .popup-disclosure-content-container {
                text-align: left;
                max-height: 70vh;
                height: 90%;
                overflow: scroll;
            }
            .title {
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                @media (max-width: 767px) {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
            p {
                text-align: left;
                margin-bottom: 0;
                & + h4,
                & + p {
                    margin-top: 20px;
                }
            }
        }
    }
    img {
        margin-bottom: 24px;
    }
    .h3 {
        margin-bottom: 24px;

        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    p {
        font-size: 14px;
        line-height: 18px;
        color: #8f929a;
    }
    .button-group {
        display: flex;
        margin-top: 40px;
        @media (max-width: 767px) {
            margin-top: 60px;
            flex-direction: column-reverse;
            gap: 16px;
        }
        [class*="btn"] {
            @media (max-width: 767px) {
                width: 100%;
            }
            & + [class*="btn"] {
                margin-left: 16px;
                @media (max-width: 767px) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }
}
