@import "./colors";
h1,
.h1 {
    font-weight: 600;
    font-size: 64px;
    line-height: 72px;
    color: $mainColor;
    margin-bottom: 24px;
    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 48px;
    }
}
h2,
.h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: $mainColor;
    @media (max-width: 990px) {
        font-size: 32px;
        line-height: 40px;
    }
    &.h2-app {
        font-size: 24px;
        line-height: 32px;
        //@media (max-width: 991px){
        //  font-size: 2px;
        //  line-height: 32px;
        //}
    }
}
h3,
.h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $blackColor;
}
h4,
.h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $blackColor;
}
h5,
.h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $blackColor;
}
a {
    transition: all 400ms;
}
.muted {
    color: $mutedColor;
    a {
        color: $greenColor;
        text-decoration: underline;
        &:hover {
            opacity: 0.7;
        }
    }
}

p,
a,
li {
    color: $blackColor;
    &.subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
    }
    &.medium {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
    &.medium-app {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    &.small {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
    }
    &.little {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }
    &.little {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }
}
.text-right {
    text-align: right;
}

$font-sizes: (
    small: 12px,
    medium: 14px,
    large: 16px,
    md-large: 20px,
    xlarge: 32px,
    xxlarge: 48px,
);

$font-weights: (
    light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
);

$line-heights: (
    sm: 16px,
    md: 20px,
    lg: 24px,
    md-lg: 28px,
    xlg: 32px,
    xxlg: 48px,
);

@mixin fontSize($fontSize) {
    font-size: $fontSize;
}

@mixin fontWeight($fontWeight) {
    font-weight: $fontWeight;
}

@mixin lineHeight($lineHeight) {
    line-height: $lineHeight;
}

//styleName: Paragraph/Body M (14)/Medium;
// font-size: 14px;
// font-weight: 500;
// line-height: 20px;
.p-sm-12-regular {
    @include lineHeight(map-get($line-heights, sm));
    @include fontSize(map-get($font-sizes, small));
    @include fontWeight(map-get($font-weights, regular));
}

.p-md-14-medium {
    @include lineHeight(map-get($line-heights, sm));
    @include fontSize(map-get($font-sizes, medium));
    @include fontWeight(map-get($font-weights, medium));
}

.p-md-14-regular {
    @include lineHeight(map-get($line-heights, sm));
    @include fontSize(map-get($font-sizes, medium));
    @include fontWeight(map-get($font-weights, regular));
}

.p-lg-16-semi-bold {
    @include lineHeight(map-get($line-heights, lg));
    @include fontSize(map-get($font-sizes, large));
    @include fontWeight(map-get($font-weights, semi-bold));
}

.p-md-lg-20-semi-bold {
    @include lineHeight(map-get($line-heights, md-large));
    @include fontSize(map-get($font-sizes, md-large));
    @include fontWeight(map-get($font-weights, semi-bold));
}
