.vertLinesContainer {
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 990;
    left: 0;
    top: 0;
}

.vertLinesInnerContainer {
    max-width: 1440px;
    padding: 0px 40px;
    margin: 0 auto;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;

    @media(max-width: 768px){
        padding: 0px 20px;
    }

    @media(max-width: 375px){
        padding: 0px 16px;
    }
}
.vertLine {
    height: 100%;
    border-left: 1px dashed #151a25;
    opacity: 0.1;
}
