.toastContainer {
    position: absolute;
    bottom: -10%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    padding: 12px 20px;
    width: 100%;
    border-radius: 8px;
    background-color: #EFF7EA;
}

.toastContainer.err {
    background-color: var(--error-50);
    color: var(--error-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.toastContent {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 375px) {
    .toastContent {
        flex-direction: column;

        text-align: center;
        padding: 3px;
    }
}
