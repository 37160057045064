.SliderBounds{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--neutral-300); 
}

.Slider {
    margin-top: 20px;
    margin-bottom: 8px;
}