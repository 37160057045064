.box {
    background-color: white;
    color: black;
    position: absolute;
    border-radius: 16px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    filter: drop-shadow(0px 20px 40px rgba(30, 37, 52, 0.1));
    z-index: 905;
    padding: 16px;
}

.box.arrow-top:after {
    content: " ";
    position: absolute;
    left: 15px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
}

.box.arrow-right:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid white;
    border-bottom: 15px solid transparent;
}

.box.arrow-bottom:after {
    content: " ";
    position: absolute;
    left: 15px;
    bottom: -15px;
    border-top: 15px solid white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
}

.box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid white;
    border-left: none;
    border-bottom: 15px solid transparent;
}
