.no-offers-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    .no-offers-text-1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #151b26;
        padding-bottom: 8px;
    }
    .no-offers-text-2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #151b26;
        padding-bottom: 24px;
    }
    .no-offers-button {
        display: flex;
        flex-direction: row;

        padding: 12px 24px;
        gap: 8px;
        width: 144px;
        height: 40px;
        background: #6db353;
        border-radius: 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        text-align: center;

        color: #ffffff;
        margin-bottom: 32px;
        cursor: pointer;
        &:hover {
            background: var(--borrowerButtonPrimaryBackground);
        }
    }
}
.for-you-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 24px;
    @media (max-width: 767px) {
        margin-top: 0;
    }

    .for-you-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 6.5px;
        @media (max-width: 1200px) {
            gap: 4px;
        }
        .for-you-title-container {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            .for-you-title-text {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                color: #151b26;
            }
            .for-you-title-display-text {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #151b26;
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
        .for-you-sort-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: 8px;

            @media (max-width: 1380px) {
                flex-direction: column;
                gap: 16px;
            }
            .for-you-quiz-text-container {
                display: flex;
                text-align: left;
                align-items: center;
                flex-direction: row;
                justify-content: flex-start;
                gap: 10px;
                @media (max-width: 1200px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                @media (max-width: 767px) {
                    gap: 8px;
                }

                .for-you-quiz-description-container {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #878f99;
                    justify-content: flex-start;
                    text-align: left;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    @media (max-width: 767px) {
                        font-size: 12px;
                        line-height: 14px;
                        position: relative;
                        padding-right: 22px;
                        .for-you-quiz-description-popup {
                            position: absolute;
                            right: 0;
                            top: 1px;
                            .bubble-wrapper-tooltip {
                                right: -10px;
                                left: auto;
                                top: auto;
                                bottom: calc(100% + 20px);
                                &::before {
                                    content: "";
                                    position: absolute;
                                    bottom: -9px;
                                    right: 5px;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 10px 10px 0 10px;
                                    border-color: #ffffff transparent transparent transparent;
                                    z-index: 1;
                                }
                                .box {
                                    position: static;
                                    border-radius: 16px 16px 8px 16px;
                                }
                            }
                        }
                    }

                    .for-you-quiz-description-popup {
                    }
                }
                .for-you-quiz-change {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: #151b26;
                    cursor: pointer;
                }
            }
        }
    }
}

.offer-table-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .for-you-rates-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media (max-width: 767px) {
            gap: 12px;
        }
    }
    .for-you-header-container {
    }
    .filters-container {
    }

    .other-rates-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 24px;

        gap: 8px;

        .other-rates-title-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            gap: 5px;
            .other-rates-title {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                color: #1e2534;
            }
            .other-rates-subtitle {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #1e2534;
            }
        }
    }

    .other-rates-container {
        display: flex;
        flex-direction: column;

        .other-rates-row {
            margin-top: 8px;
            margin-bottom: 8px;

            &.first-row {
                margin-top: 0px;
            }

            &.last-row {
                margin-bottom: 0px;
            }
        }

        .other-rates-empty-row {
            margin-top: 0px;
            margin-bottom: 0px
        }
    }
}

.rate-toggle {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    color: rgba(var(--borrowerPrimaryColor), 1);
    align-items: center;
    gap: 10px;
    span {
        transition: all 400ms;
    }
    &.active {
        span {
            transform: rotate(-180deg);
        }
    }
}

.offer-page-popup {
    width: 246px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #151b26;

    height: auto;
    max-height: 300px;
    overflow-y: auto;
    text-align: left;

    display: flex;
    flex-direction: column;
    gap: 4px;
    .offer-page-popup-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #151b26;
    }
}
