.FieldLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  text-align: left;
  color: var(--neutral-600);
  position: relative;
}

.InputFieldContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
  border-bottom: 1px solid var(--neutral-400);
  padding-bottom: 16px;
  margin: 8px 0px 4px 0px;
}

.InputFieldContainer:focus-within {
  border-bottom: 1px solid var(--neutral-900);
}

.ErrorMessage {
  color: var(--error-main);
  font-size: 14px;
  font-weight: 400;
}
