@import "src/assets/styles/colors";

.round-checkbox {
    height: 16px;
    width: 16px;
    border: 1px solid #abb6bd;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 560px) {
        right: 0px;
        top: 4px;
        position: absolute;
    }

    &.isActive {
        &-borrower {
            background-color: rgba(var(--borrowerPrimaryColor), 1);
            border: 1px solid rgba(var(--borrowerPrimaryColor), 1);
        }

        &-cosigner {
            background-color: rgba(var(--cosignerPrimaryColor), 1);
            border: 1px solid rgba(var(--cosignerPrimaryColor), 1);
        }
    }

    .round-checkbox-dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: white;
    }
}
