.rc-first-row {
    display: flex;
    justify-content: space-between;
}

.rc-second-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    @media (max-width: 560px) {
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
    }
}

.rc-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #151b26;
    border-radius: 12px;
}

.rc-field-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

@mixin secondary-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #abb6bd;
}
.rc-field-value {
    @include secondary-text;
    font-weight: 400;
}

.rc-form-information {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .rc-row {
        display: flex;
        justify-content: space-between;
        padding-top: 17px;
        border-top: 1px solid rgba(21, 27, 37, 0.1);

        .rc-left {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #151b26;
        }
        .rc-right {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            text-align: right;

            color: #abb6bd;
        }
    }
}

.ReviewCardsFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.ReviewCardsButton{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    
    &:hover{
        cursor: pointer;
        opacity: 0.5;
    }
}