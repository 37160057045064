
.faq-collapse {
  padding: 32px;
  background: #fff;
  border-radius: 12px;
  width: 100%;

  & + .faq-collapse {
    border-top: 1px var(--neutralColor100) solid;
  }
  .faq-toggle {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    transition: all 400ms;
    width: 100%;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 20px;
    }

    @media (max-width: 560px) {
      font-size: 16px;
    }
    
    .icon-arrow-bottom {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      transition: all 400ms;
    }
    
    &.active {
      
      .icon-arrow-bottom {
        transform: scaleY(-1);
      }
    }
  }
  .faq-collapse-block {
    margin-top: 16px;
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--neutralColor600);
      & + p {
        margin-top: 16px;
      }
    }
  }
}
