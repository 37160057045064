div.awl-learn {
    display: flex;
    align-items: center;
    gap: 8px;

}

div.awl-learn:hover {
    cursor: pointer;
    span {
        transition: all 0.3s ease-in-out;
        transform: translateX(3px);
    }
}

div.awl-lender-group-data{
    display: flex;
    gap: 45px;

    @media (max-width: 767px) {
        gap: 22px;
    }
}

div.awl-lender-data{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

div.awl-lender {
    display: flex;
    gap: 8px;
}

div.awl-lender-status{
    display: flex;
    align-items: center;
    gap: 8px;
}

.rm-apply-with-lender-popup{
    margin: 24px;
}
