.already-explored {
    margin: 24px;

    p {
        margin-bottom: 16px;
    }

    @media(max-width: 760px){
        margin: 0px 24px;
    }
}