@import "colors";

.react-international-phone-input-container{
  position: relative;
  font-family: 'Basier Circle', sans-serif;
  font-size: 16px;
  .react-international-phone-input{
    padding-left: 80px!important;
    height: 46px!important;
    border-width: 1px!important;
    border-style: solid !important;
    border-color: var(--neutralColor200) !important;

    &:hover {
        border-color: currentColor !important;
    }
    &:active {
        border-color: currentColor !important;
    }
  }
  .react-international-phone-country-selector{
    pointer-events: none;
    border: none !important;
    //border-bottom: 1px solid rgba(30,37,52,.2);
    border-bottom: 1px solid white !important;
    background-color: transparent !important;
    flex: 0 0 96px;
    width: 75px;
    right: auto;
    left: 2px;
    border-radius: 0 !important;
    position: absolute;
    bottom: 0;
    padding: 0;
    @media (max-width: 767px) {
        transform: translateY(-50%); /* Adjust for half of the containing element's height */   
    }
    @media (min-height: 801px) {
        transform: translateY(-50%); /* Adjust for half of the containing element's height */ 
    }
  }
  .react-international-phone-country-selector-button {
    padding-left: 0!important;

    width: calc(100% - 18px);
    margin-top: 1px;
    border-bottom: 1px solid rgba(30,37,52,.2);

    outline: none;
    position: relative;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px !important;
    display: block;

  }
  .react-international-phone-country-selector-button .react-international-phone-country-selector-button__button-content {
    margin-top: -8px;
    margin-left: 13px;
    position: absolute;
    height: 16px;

  }

  .react-international-phone-flag-emoji.react-international-phone-country-selector-button__flag-emoji {
    margin-left: 0px;
    margin-right: 0px;
  }

  .react-international-phone-country-selector-button .react-international-phone-country-selector-button__dropdown-arrow{
    margin-left: 10px;
    border: none;
    margin-top: 0px!important;
    font-size: 14px !important;

    display: none;

    &::before{
      content: "\e932"!important;
      position: absolute;
      font-family: icomoon!important;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      -webkit-transition: all .4s ease;
      -o-transition: all .4s ease;
      transition: all .4s ease;
    }
  }
}

.form-group.form-group-phone {
  display: flex;
  flex-direction: column;
  position: relative;

  .error-text{
    top: 100%;
    right: 0;
    position: absolute;
  }

  .labels-wrapper{
    display: flex;

    label.label-phone-country {
      flex: 0 0 75px;
      max-width: 75px;
    }

    label.label-phone {
      flex: 0 0 calc(100% - 80px);
      max-width: calc(100% - 80px);
    }
  }
}

