// TODO: this file has been merged with the PAAAS variables, it will be important to clean this file and rename variables more appropriately
//as the components are refactored. 


:root {
  --fontBasier: 'Basier Circle', sans-serif;
  --invertedColor: #fff;
  --whiteColor: #fff;
  --mainColor: #1E2534;
  --whiteColorRGB: 255,255,255;
  --borrowerPrimaryColor: #151A25;
  --primaryColor2: #151B26;
  --primaryColorRGB: 21, 26, 37;
  --greenColor: #6DB353;
  --greenColor01: #8CCE56; 
  --greenColorRGB: 109,179, 83;
  --secondaryGreen: #185155;
  --greenSecondaryColor: #22AC83;
  --greenSecondary01Color: #4EAC6A;
  --greenSecondaryColorRGB: 34,172,83;
  --mainBoxShadow: 0px 24px 40px 0px rgba(21,26,37,0.1);
  --bigBoxShadow: 0px 80px 120px 0px rgba(21,26,37,0.1);
  --greyColor: #9EA9B0;
  --darkGreyColor: #6B717B;
  --orangeColor: #F19049;
  --peachColor: #DC7962;
  --peachColorSecondary: #EEBCB0;
  --peachColorBG: #F8E4E0;
  --peachBlackColor: #A0584A;
  --neutralColor100: #EDF2F6;
  --neutralColor200: #CCD6DD;
  --neutralColor300: #ABB6BD;
  --neutralColor400: #9EA9B0;
  --neutralColor600: #6B717B;
  --neutralColor500: #878F99;
  --neutralColor50: #F6F9FC;
  --secondaryBlue: #6177E8;
  --errorMainColor: #F32B32;
  --errorMainColorRGB: 243, 43, 50;
  --viewport-height: 100%;
  --main-section-wrapper-height: 100%;
  --paaas-footer-height: 100%;
  --gradientGreen: linear-gradient(254.6deg, #8CCE56 0%, #57A051 100%);
  --primaryColor: #151a25;

  // Colors --------------------------------- Start //
  --invertedColor: white; // new
  --invertedColorRGB: 0, 0, 0; // new
  --borrowerAccentColor: 109, 179, 83; // new
  --accentColorRGB: 255, 65, 65; // new
  --accentColorHover: #2D8F43; // new
  --mainColor: #1E2534; 
  --mainColorRGB: 30, 37, 52;
  --borrowerPrimaryColor: 109, 179, 83;
  --cosignerPrimaryColor: 220, 121, 98;
  --cosignerAccentColor: 220, 121, 98;
  --primaryColorRGB: 45, 143, 67;
  --primaryHoverColor: #32a74c;
  --primaryHoverColorRGB: 50, 167, 76;
  --secondaryColor: #32A74C;
  --secondaryColorRGB: 50, 167, 76;
  --secondaryHoverColor: #7ef899;
  --secondaryHoverColorRGB: 126, 248, 153;
  --secondaryColor2: #D3E1D0;

  --mainBorrowerColor: #6FB554;
  --mainCosignerColor: #DC7962;
  --accentBorrowerColor: #6FB554;
  --accentCosignerColor: #DC7962;

  // Colors --------------------------------- End //

  // Buttons -------------------------------- Start //
  
  --borrowerSliderColor:rgba(111, 181, 84, 1); // new
  --cosignerSliderColor: rgba(220, 121, 98, 1); // new

  --borrowerRailColor: rgba(111, 181, 84, 0.3); // new
  --cosignerRailColor: rgba(220, 121, 98, 0.3); // new
  // Buttons -------------------------------- End //
  --borrowerButtonPrimaryFont: "Basier Circle", sans-serif;
  --borrowerButtonPrimaryColor: #fff;
  --borrowerButtonPrimaryBorderColor: transparent;
  --borrowerButtonPrimaryBackground: #6DB353;
  --borrowerButtonPrimaryBorderRadius: 32px;
  --borrowerButtonPrimaryTextTransform: none;
  
  --borrowerButtonSecondaryFont: "Basier Circle", sans-serif;
  --borrowerButtonSecondaryColor: #6DB353;
  --borrowerButtonSecondaryBorderColor: #6DB353;
  --borrowerButtonSecondaryBackgroundColor: transparent;
  --borrowerButtonSecondaryBorderRadius: 32px;
  --borrowerButtonSecondaryTextTransform: none;
  
  --borrowerLinkFont: "Basier Circle", sans-serif;
  --borrowerLinkColor: #6DB353;
  --borrowerLinkTextTransform: none;
  --borrowerLinkTextDecoration: underline;
  
  --cosignerButtonPrimaryFont: "Basier Circle", sans-serif;
  --cosignerButtonPrimaryColor: #fff;
  --cosignerButtonPrimaryBorderColor: transparent;
  --cosignerButtonPrimaryBackground: #DC7962;
  --cosignerButtonPrimaryBorderRadius: 32px;
  --cosignerButtonPrimaryTextTransform: none;
  
  --cosignerButtonSecondaryFont: "Basier Circle", sans-serif;
  --cosignerButtonSecondaryColor: #DC7962;
  --cosignerButtonSecondaryBorderColor: #DC7962;
  --cosignerButtonSecondaryBackgroundColor: transparent;
  --cosignerButtonSecondaryBorderRadius: 32px;
  --cosignerButtonSecondaryTextTransform: none;
  
  --cosignerLinkFont: "Basier Circle", sans-serif;
  --cosignerLinkColor: #DC7962;
  --cosignerLinkTextTransform: none;
  --cosignerLinkTextDecoration: underline;
}
//22AC83

