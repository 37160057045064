.bgContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.bgTop {
    height: 200px;
    top: 51px;
    width: calc(100% + 2px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-3deg);
    border-radius: 0 80px 0 0;
    background: #edf2f6;
}

.bgMiddle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: calc(100% - 230px);
    background: #edf2f6;
}

.bgBottom {
    height: 200px;
    bottom: 51px;
    width: calc(100% + 2px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-3deg);
    border-radius: 0 0 0 80px;
    background: #edf2f6;
}
