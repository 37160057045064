@import "../../../assets/styles/colors.scss";

.input-tooltip-wrapper {
    width: 16px;
    height: 8px;
    left: 5px;
    top: -5px;
    display: inline-flex;
    position: relative;
    //z-index: 1;
    img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    .input-tooltip-trigger {
        padding: 0;
        border: none;
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        background: #fff;
        cursor: pointer;

        &::before {
            content: "";
            width: 13px;
            height: 13px;
            background: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: #fff;
            background-size: 100% 100%;
            background: url(../../../assets/images/tooltip-question-black.svg) center center
                no-repeat;

            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }
        &:hover {
            & + .input-tooltip-content {
                opacity: 1;
                transform: translateY(0);
                pointer-events: auto;
            }
        }
    }
}

.input-tooltip-content {
    position: absolute;
    bottom: calc(100% + 12px);
    width: 278px;
    left: -50px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 24px 64px 0px rgba(30, 37, 52, 0.1);

    transition: all 400ms;
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;

    &::before {
        width: 18px;
        height: 9px;
        background: #fff;
        content: "";
        position: absolute;
        bottom: -7px;
        background: url(../../../assets/images/tooltip-arrow.svg) center center no-repeat;
        background-size: 100% 100%;
        left: 51px;
    }
}

.input-tooltip-data {
    padding: 16px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: $mainColor;
        & + p {
            margin-top: 7px;
        }
    }
}
