.btn {
  padding: 10px 24px;
  border: none;
  border-radius: 28px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: var(--fontBasier);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  &.disabled {
    pointer-events: none;
    opacity: .4;
  }
  .icon-arrow-right {
    margin-left: 8px;
    font-size: 12px;
    transition: all 400ms;
    display: block;
  }
  .icon-search {
    margin-right: 11px;
    font-size: 18px;
  }
  &:hover {
    .icon-arrow-right {
      transform: translateX(5px);
    }
  }
  &:focus {
    outline: none;
  }
  &-style-text {
    background: none !important;
    padding: 10px 0;
  }
  &.btn-strong-size {
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &.disable {
    pointer-events: none;
  }
}
.btn-white {
  transition: background-color 400ms;
  background: rgba(255, 255, 255, 0.2);
  color: var(--whiteColor);
  &:hover {
    background: rgba(255,255,255,0.4);
  }
}
.btn-green {
  transition: background-color 400ms;
  background: var(--greenColor);
  color: var(--whiteColor);
  padding: 16px 25px;
  &.btn-disable {
    pointer-events: none;
    background-color: var(--neutralColor400);
    color: var(--neutralColor200);
  }
  &:hover {
      background: #8CCE56;
    }
}
.btn-red {
  border-color: #F32B32;
  background-color: #F32B32;
  color: var(--whiteColor);
  transition: all 400ms;
  &:hover {
    background-color: rgba(#F32B32, .8);
  }
}

.btn-peach {
  transition: background-color 400ms;
  background: var(--peachColor);
  color: var(--whiteColor);
  padding: 16px 25px;

  &:hover {
      background: var(--peachColorSecondary);
    }
}

.btn-blue {
  transition: background-color 400ms;
  background: var(--secondaryBlue);
  color: var(--whiteColor);
  padding: 16px 25px;

  &:hover {
    background: #3C4EA9;
  }
}

.btn-peach {
    transition: background-color 400ms;
    background: var(--peachColor);
    color: var(--whiteColor);
    padding: 16px 25px;
  
    &:hover {
        background: var(--peachColorSecondary);
      }
  }

.btn-black {
  border: 1px solid rgba(var(--borrowerPrimaryColor), 1);
  background: rgba(var(--borrowerPrimaryColor), 1);
  color: var(--whiteColor);
  padding: 10px 24px;
}

.btn-bordered {
  background-color: var(--whiteColor);
  color: rgba(var(--borrowerPrimaryColor), 1);
  border: 1px solid var(--neutralColor200);
}
.btn-border-green {
  border: 1px var(--greenColor) solid;
  color: var(--greenColor);
  background: transparent;
  padding: 10px 24px;
  &:hover {
    background: var(--greenColor);
    color: var(--whiteColor);
  }
}