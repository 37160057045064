.container {
    background: #FFFFFF;
    border-radius: 16px;
    margin-top: 48px;
    margin-bottom: 32px;
    padding: 46px 57px 46px 32px; 

    @media (max-width: 990px) {
        padding: 24px;
    }
}

.innerContainer {
    display: flex;
    justify-content: space-between;

    @media (max-width: 990px) {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .box {
        display: flex;
        justify-content: space-between;
        gap: 24px;

        @media (max-width: 990px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 0px;
            p {
                font-size: 14px;
            }
        }
    }
}