@import "../../../../assets/styles/colors";

.link-btn{
  display: inline;
  &.small-link{
    color: $greenColor;
    &:hover {
      color: $mainColor;
    }
  }

  &.big-link{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    text-decoration-line: underline;
    color: #6FB554;

    &:hover {
      color: $mainColor;
    }
  }

  &.gray{
    color: #8F929A;
    font-weight: 500;
    line-height: 18px;
    transition: all .4s;
    cursor: pointer;
  }
}
