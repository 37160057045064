:root {
  --primary-dark-blue: #151b26;
  --neutral-50: #f6f9fc;
  --neutral-80: #f1f5f8;
  --neutral-100: #edf2f6;
  --neutral-200: #ccd6dd;
  --neutral-300: #abb6bd;
  --neutral-400: #9ea9b0;
  --neutral-500: #878f99;
  --neutral-600: #6b717b;
  --neutral-700: #525966;
  --neutral-800: #3a414f;
  --neutral-900: #2b323f;
  --neutralColor80: #F1F5F8;
}
