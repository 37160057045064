.charts {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-top: 20px;
    width: 100%;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
    .rate-chart-column {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        width: 50%;
        @media (max-width: 1200px) {
            max-width: calc(50% - 8px);
            //width: 100%;
        }
        @media (max-width: 1100px) {
            max-width: 100%;
            width: 100%;
        }
        @media (max-width: 767px) {
            position: relative;
            z-index: 4;
        }
    }
    @media (max-width: 767px) {
        flex-direction: column;
        .layout-row {
            flex-direction: unset;
            flex-wrap: wrap;
            //margin-bottom: 12px;
            height: auto;
        }
        .chart-title {
            flex: 0 0 100%;
            max-width: 100%;
            & + .bar-chart-select-container {
                margin-top: 8px;
            }
        }
    }

    position: relative;
    .select-custom {
        margin-top: 0;
    }
    .bar-chart-wrapper {
        @media (max-width: 767px) {
            margin-top: 12px;
        }
    }
}

.line-chart {
    justify-content: space-between;
    &-wrapper {
        background-color: white;
        padding: 15px;
        border-radius: 16px;
        border: 1px solid #f2f4f5;
        margin-top: 16px;
    }
}

.bottom-border-header {
    border-bottom: solid 1.5px#ccd6dd;
    @media (max-width: 767px) {
        border: none;
    }
}

.bottom-border-chart {
    @media (min-width: 768px) {
        border-bottom: solid 1.5px#ccd6dd;
        padding-bottom: 16px;
    }
}

.bar-chart {
    justify-content: space-between;
    height: 30px;

    &-wrapper {
        background-color: white;
        padding: 15px;
        border-radius: 16px;
        border: 1px solid #f2f4f5;
        margin-top: 16px;
    }
    .chart-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #151b26;
    }
}

.custom_tooltip_bar {
    background-color: white;
    width: 100px;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: all 200ms ease;
    box-shadow: 0px 20px 40px rgba(30, 37, 52, 0.1);

    div {
        &:first-of-type {
            font-size: 12px;
            font-weight: 600;
        }
        &:last-of-type {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}

.custom_tooltip_line {
    background-color: white;
    width: 236px;
    padding: 12px 12px 20px 12px !important;
    border-radius: 10px;
    position: absolute;
    transform: translate(-50%, calc(-100% - 25px));
    opacity: 0;
    pointer-events: none;
    transition: all 200ms ease;
    box-shadow: 0px 20px 40px rgba(30, 37, 52, 0.1);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    z-index: 904;

    .remaining-time-value {
        white-space: nowrap;
        text-align: end;
    }

    h5 {
        font-weight: 600;
        font-size: 10px;
    }

    .l-row {
        padding-bottom: 8px;
        &:not(:first-of-type) {
            padding-top: 8px;
            border-top: 1px solid rgba(#1e2534, 0.1);
        }
        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 4px;
            div:last-of-type {
                padding-left: 10px;
            }
        }
    }
}

.legend_item {
    padding: 0 4px;
    width: 160px;
    flex: 0 0 160px;
    max-width: 160px;

    &-inner {
        border-radius: 16px;
        border: 1px solid rgba(#1e2534, 0.1);
        padding: 16px 14px;
        display: flex;
    }

    &__color {
        width: 16px;
        height: 16px;
        min-width: 16px;
        border: 5px solid transparent;
        border-radius: 100%;
    }

    &__info {
        padding-left: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        &-bottom {
            color: #1e2534;
            opacity: 0.6;
        }
    }

    &_wrapper {
        margin-left: -4px;
        margin-right: -4px;
        margin-top: 12px;
        margin-bottom: 40px;
    }
}
.scroll-legend {
    display: flex;
    padding-bottom: 14px;
}
.legend_item_wrapper {
    .ScrollbarsCustom {
        margin-top: 0 !important;
    }
}

.selected-rate-cards-container {
    transition: all 0.2s ease-in;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;
    overflow-x: auto;
    @media (max-width: 767px) {
        margin-top: 0;
    }
    .ScrollbarsCustom {
        margin-top: 0;
    }
    &__content {
        display: flex;
        gap: 16px;
        @media (max-width: 767px) {
            gap: 8px;
        }
    }
    .selected-rate-card-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 12px;
        padding-top: 8px;
        padding-right: 12px;
        padding-bottom: 8px;

        gap: 8px;
        width: fit-content;
        min-height: 52px;
        border: 1px solid #abb6bd;
        border-radius: 12px;
        &:hover {
            background-color: white;
            border: 1px solid transparent;
            cursor: pointer;
        }

        .selected-rate-card-left {
        }
        .selected-rate-card-middle {
            display: flex;
            flex-direction: column;
            .selected-rate-card-title {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #2b323f;
                white-space: nowrap;
            }
            .selected-rate-card-lender {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #878f99;
                white-space: nowrap;
            }
        }
        .selected-rate-card-right {
            padding-left: 10px;
        }
    }
}

.bar-chart-select-container {
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
        z-index: 9;
    }
    &:hover {
        cursor: pointer;
    }

    .absolute-bar-select {
        width: 223px;
        position: absolute;
        margin-bottom: 0px;
        top: 0;
        height: 100%;
        .select-highlight {
            padding: 4px 12px;

            box-shadow: 0px 20px 60px rgba(30, 37, 52, 0.1);
            background: #edf2f6;
            height: 26px;
            z-index: 10;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            border-radius: 16px;
            &.left {
                width: 80px;
                animation-name: moveLeft;
                -webkit-animation-name: moveLeft;
                -moz-animation-name: moveLeft;
                -ms-animation-name: moveLeft;
                animation-duration: 0.2s;
                -webkit-animation-duration: 0.2s;
                -moz-animation-duration: 0.2s;
                -ms-animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-delay: 0s;
                animation-fill-mode: forwards;
            }

            &.right {
                width: 143px;
                animation-name: moveRight;
                -webkit-animation-name: moveRight;
                -moz-animation-name: moveRight;
                -ms-animation-name: moveRight;
                animation-duration: 0.2s;
                -webkit-animation-duration: 0.2s;
                -moz-animation-duration: 0.2s;
                -ms-animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-delay: 0s;
                animation-fill-mode: forwards;
            }
            @keyframes moveLeft {
                0% {
                    transform: translate(80px, 0);
                }

                100% {
                    transform: translate(0px, 0px);
                }
            }

            @keyframes moveRight {
                0% {
                    transform: translate(0px, 0px);
                }
                100% {
                    transform: translate(80px, 0);
                }
            }
        }
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;

    width: 231px;
    height: 32px;
    background: white;
    border-radius: 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #151b26;

    .bar-chart-select-option {
        z-index: 11;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px 12px;
        gap: 10px;

        height: 24px;
        background: transparent;
        white-space: nowrap;

        border-radius: 12px;
    }
}

.mobile-collapse-charts {
    @media (min-width: 768px) {
        display: none;
    }
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    background-color: #f1f5f8;
    width: 100%;
    height: auto;
    //padding-top: 106px;
    z-index: 99;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--neutralColor200);
    transition: all 400ms;
    pointer-events: none;
    @media (max-width: 767px) {
        max-height: calc(var(--viewport-height) - 100px);
        overflow-y: auto;
        .selected-rate-cards-container {
            margin-top: 16px;
        }
    }
    &.active {
        opacity: 1;
        top: 104px;
        pointer-events: auto;
    }
    .charts__mobile-view {
        flex-direction: column-reverse;
        gap: 0;
        padding: 0 16px;
        .rate-chart-column {
            margin-top: 16px;
            @media (max-width: 767px) {
                z-index: 99999;
                & + .rate-chart-column {
                    z-index: 4;
                }
            }
        }
        .bar-chart {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            .chart-title {
                & + .bar-chart-select-container {
                    margin-top: 8px;
                }
            }
        }
    }
    .selected-rate-cards-container {
        padding-left: 16px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
