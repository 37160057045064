@import "../../../../assets/styles/colors";

.form-row {
    display: flex;
    gap: 24px;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    .clear-btn {
        cursor: pointer;
    }
    .form-address-cancel {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #151b26;
        cursor: pointer;
    }
    .form-address-update {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 8px;
        width: 151px;
        height: 40px;

        border-radius: 32px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        &.cosigner {
            background: #dc7962;
        }
        &.borrower {
            background: #6db353;
        }
    }
}

.form-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
}

.form-col-6 {
    width: 50%;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.form-col {
}

.form-group {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    margin-bottom: 20px;

    &.has-prefix {
        .prefix {
            position: absolute;
            left: 0;
            bottom: 15px;
        }

        input {
            transition: all 0s ease !important;
        }
        input.has-value {
            padding-left: 20px !important;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="password"] {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-family: inherit;
        border: none;
        border-bottom: 1px solid rgba(30, 37, 52, 0.2);
        background-color: transparent;
        padding: 8px 16px 16px 0;
        width: 100%;
        transition: all 0.3s ease;
        border-radius: 0;
        &:focus,
        &:hover {
            border-bottom: 1px solid $mainColor;
            & + label {
                color: $mainColor;
            }
        }
        &::placeholder {
            color: rgba(30, 37, 52, 0.25);
        }
    }

    label:not(.error) {
        font-size: 14px;
        line-height: 18px;
        color: rgba(30, 37, 52, 0.5);
        display: block;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    &--navigation {
        position: absolute;
        right: 0;
        top: 28px;
        display: flex;
        align-items: center;
    }

    &.error input {
        border-bottom: 1px solid var(--errorMainColor) !important;
    }

    .error-text,
    label.error:not(input) {
        color: var(--errorMainColor) !important;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        margin-top: 8px;
        order: -1;
        max-width: fit-content;
        align-self: flex-end;
    }

    .input-success {
        width: 24px;
        height: 24px;
    }

    .recommend-label {
        background-color: $darkGreenColor;
        font-size: 10px;
        line-height: 12px;
        color: white;
        padding: 2px 8px;
        border-radius: 6px;
    }
}

.form-row .form-group {
    margin-bottom: 0;
}

.form-group--navigation .toggle-password {
    background-image: url(../../../../assets/images/icons/noeye.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 16px;
    height: 16px;
    opacity: 0.5;

    &.active {
        background-image: url(../../../../assets/images/view-active.svg);
    }
}
