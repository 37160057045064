.pq-step-card-container {
    width: 100%;

    .status-bar:not(.mobile-only) {
        width: 100%;
        height: 4px;
        margin-bottom: 16px;
        background: #CCD6DD;
        border-radius: 100px;
        

        &.borrower{
            background: rgba(var(--borrowerPrimaryColor), 1);
        }

        &.cosigner{
            background: rgba(var(--cosignerPrimaryColor), 1);
        }

        @media (max-width: 990px) {
            &.borrower, &.cosigner {
                background: none;
            }
        }
    }

    .step-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #abb6bd;
        margin-bottom: 4px;
    }

    .step-data {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &.incomplete{
            color: #878F99;
        }

        &.latest-incomplete {
            color: #F19048;
        }
    }
}
