@import "../../../../assets/styles/colors";

.oval-button {
    display: flex;
    border-radius: 32px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    background: $darkGreenColor;
    color: $whiteColor;
    float: right;
    white-space: nowrap;
    font-weight: 500;
    cursor: pointer;
    a {
        color: inherit;
    }

    &:hover {
        opacity: 0.9;

        .icon-arrow-right {
            transition: all 800ms;
            transform: translateX(5px);
        }
    }

    &.paaas.primary, &.paaas.borrower.primary {
        font-family: var(--borrowerButtonPrimaryFont);
        background: var(--borrowerButtonPrimaryBackground);
        color: var(--borrowerButtonPrimaryColor);
        border: 1px solid var(--borrowerButtonPrimaryBorderColor);
        border-radius: var(--borrowerButtonPrimaryBorderRadius);
        text-transform: var(--borrowerButtonPrimaryTextTransform);
        &:hover {
            opacity: 0.5;
        }
    }
    &.paaas.borrower.secondary{
        font-family: var(--borrowerButtonSecondaryFont);
        background: var(--borrowerButtonSecondaryBackground);
        color: var(--borrowerButtonSecondaryColor);
        border: 1px solid var(--borrowerButtonSecondaryBorderColor);
        border-radius: var(--borrowerButtonSecondaryBorderRadius);
        text-transform: var(--borrowerButtonSecondaryTextTransform);
        &:hover {
            opacity: 0.5;
        }
    }
    &.paaas.cosigner.primary{
        font-family: var(--cosignerButtonPrimaryFont);
        background: var(--cosignerButtonPrimaryBackground);
        color: var(--cosignerButtonPrimaryColor);
        border: 1px solid rgba(var(--cosignerPrimaryColor), 1);
        border-radius: var(--cosignerButtonPrimaryBorderRadius);
        text-transform: var(--cosignerButtonPrimaryTextTransform);
        &:hover {
            opacity: 0.5;
        }
    }
    &.paaas.cosigner.secondary{
        font-family: var(--cosignerButtonSecondaryFont);
        background: var(--cosignerButtonSecondaryBackground);
        color: var(--cosignerButtonSecondaryColor);
        border: 1px solid var(--cosignerButtonSecondaryBorderColor);
        border-radius: var(--cosignerButtonSecondaryBorderRadius);
        text-transform: var(--cosignerButtonSecondaryTextTransform);
        &:hover {
            opacity: 0.5;
        }
    }
}

.btn-group-wrapper{
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    &.center-buttons {
        justify-content: center;
    }

    @media (max-width: 560px) {
        flex-direction: column;
        align-items: center;
    }

}
