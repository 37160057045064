.header-label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 765px) {
        flex-direction: column;
        align-items: flex-start;
    }
    @media (max-width: 767px) {
        .menu-dots {
            padding: 10px 0 10px 6px;
        }
        .back-dashboard-header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
                height: 19px;
            }
        }
    }
    .header-label-container-title {
        align-items: center;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: fit-content;
        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .header-label-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;

        .header-label-title {
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;

            // display: flex;
            // flex-direction: row;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            color: #151b26;
            padding-left: 18px;

            @media (max-width: 765px) {
                padding-left: 0px;
            }

            .header-label-number-of-rates {
                &.borrower {
                    color: rgba(var(--borrowerPrimaryColor), 1);
                }
                &.cosigner {
                    color: rgba(var(--cosignerPrimaryColor), 1);
                }
            }
            .header-label-button {
            }
        }
        .header-label-subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            align-items: center;
            color: #878f99;
        }
        .header-label-number-of-rates {
            color: rgba(var(--borrowerPrimaryColor), 1);
        }
        .header-label-button {
        }
        .header-collapse-chart-section {
            cursor: pointer;
            @media (max-width: 767px) {
                display: none;
            }
            span {
                transition: all 400ms;
            }
            &.active {
                color: #6db353;

                .span {
                    transform: rotate(-180deg);
                }
            }

            width: fit-content;
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .collapse-chart-container {
                display: flex;
                flex-direction: row;
                width: fit-content;
                align-items: center;
                gap: 10px;
                .collapse-chart-text {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #151b26;
                    &.active {
                        color: #6db353;
                    }
                    width: fit-content;
                }
                .collapse-chart-number {
                    display: flex;

                    width: 24px;
                    height: 24px;

                    background: #f19048;
                    color: #ffefc6;

                    border-radius: 50%;

                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;

                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .header-label-title-subtitle-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        align-content: center;

        .header-label-subtitle {
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            // color: #878f99;
            padding-top: 8px;
            -webkit-text-fill-color: #878f99;
            -moz-text-fill-color: red;
            -ms-text-fill-color: #878f99;
            -o-text-fill-color: #878f99;
            padding-left: 54px;
            @media (max-width: 765px) {
                padding-left: 0px;
            }

            .apply-subtitle {
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                font-style: bold;
                font-weight: 500;
            }
        }
    }
}
.header-body-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: auto;
    margin-top: 24px;
    margin-bottom: 16px;
    @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: 50% 1fr;
        grid-template-rows: auto auto;
        grid-gap: 10px;
        grid-auto-flow: column;
        margin-top: 16px;
        flex-direction: unset;
    }
    .header-body-card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        @media (max-width: 767px) {
            max-width: calc(50% - 8px);
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(3) {
                order: 2;
            }
            &:nth-child(4) {
                order: 4;
            }
        }
        .header-body-card-container {
            background: white;
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-left: 19px;
            padding-right: 19px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-radius: 12px;
            height: 100%;
            @media (max-width: 767px) {
                height: 100%;
                padding: 12px;
            }
            .header-body-card-title-container {
                display: flex;
                flex-direction: row;
                gap: 10px;
                @media (max-width: 767px) {
                    gap: 8px;
                }
                .header-body-card-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: #878f99;
                }
            }
            .header-body-card-input-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .header-body-card-input {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #151b26;
                    @media (max-width: 767px) {
                        white-space: nowrap;
                        width: auto;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .header-body-card-input-dropdown {
                    cursor: pointer;
                    transition: all 400ms;

                    &.active {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }
}

.header-container {
    display: flex;
    flex-direction: column;
}

.header-input-container {
    display: flex;
    flex-direction: column;
}
.back-dashboard-header {
    font-size: 22px;
    display: inline-flex;
    align-items: center;
    color: #1e2534;
    height: 18px;
    @media (max-width: 990px) {
        padding-top: 0;
        height: auto;
    }
    @media (max-width: 575px) {
        font-size: 20px;
        padding-bottom: 0;
    }
    @media (max-width: 767px) {
        padding-left: 0;
        padding-bottom: 0;
    }
}
.header-dropdown-popup {
    border-radius: 16px;
    transition: all 400ms;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 8px;
    padding: 10px;
    z-index: 1;

    background: white;
    pointer-events: auto;
    width: 200px;
    z-index: 999;

    &.school {
        z-index: 999;
    }

    &.cosigner-wizard {
        z-index: 999;
    }

    &.cosigner-pending {
        width: fit-content;
        z-index: 999;
    }

    input {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        padding: 16px;
        background: #f2f4f5;
        border-radius: 16px;
        border: none;
        transition: all 400ms;
        width: 100%;
        text-align: center;
    }

    .header-dropdown-input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 16px;
        width: 100%;
        gap: 16px;

        .header-dropdown-input-title-container {
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            .header-dropdown-input-title {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;

                display: flex;
                align-items: flex-start;
                color: #151b26;
            }
        }

        .cosigner-invited-title-container {
            display: flex;
            width: 100%;
            gap: 10px;
            padding: 10px;

            flex-direction: row;
            justify-content: flex-start;
            .cosigner-invited-title-icon {
                background: #151b26;
                border-radius: 8px;
                color: white;
                align-items: center;
                justify-content: center;
                display: flex;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                width: 48px;
                height: 48px;
            }
            .between-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 10px;
                width: 100%;

                .cosigner-invited-name-container {
                    display: flex;
                    flex-direction: column;
                    .cosigner-invited-name {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #151b26;
                    }
                    .cosigner-invited-email {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #abb6bd;
                    }
                }
                .cosigner-invited-dots-icon {
                }
            }
        }
        .cosigner-invited-button-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
            .cosigner-invited-resend-button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 24px;
                gap: 8px;

                border: 2px solid #6db353;
                border-radius: 32px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                text-align: center;

                color: #6db353;
            }
            .cosigner-invited-signup-button {
                display: flex;
                flex-direction: row;
                padding: 12px 24px;
                gap: 8px;
                background: #6db353;
                border-radius: 32px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                align-items: center;
                text-align: center;

                color: #ffffff;
            }
        }
    }
}

.bubble-wrapper {
    position: absolute;
    display: inline-block;
    width: fit-content;
    z-index: 911;
    margin-top: 80px;
    right: 0;

    &__enable-mobile-view {
        @media (max-width: 767px) {
            position: fixed;
            top: 0 !important;
            left: 0 !important;
            width: 100%;
            height: 100%;
            background-color: rgba(#151b26, 0.4);
            margin-top: 0;
            .box {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                top: auto !important;
                margin-top: 0;
                display: block;
                border-radius: 16px 16px 0 0;
                padding: 0;
            }
            .header-dropdown-input-container {
                padding: 18px 10px 21px 10px;
            }
        }
    }
    .mobile-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 6px 18px 6px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }
}

.header-borrower-dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .header-borrower-dropdown-identity-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .header-borrower-dropdown-initials {
            display: flex;
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            justify-content: center;
            align-items: center;
            background: #151b26;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
        }
        .header-borrower-dropdown-name-container {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            .header-borrower-dropdown-name-top-row-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #151b26;
                white-space: nowrap;
            }
            .header-borrower-dropdown-name-bottom-row {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #878f99;
                white-space: nowrap;
            }
        }
    }
    .header-borrower-dropdown-university-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 8px;
        gap: 16px;
        width: 428px;
        max-width: 428px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 56px;
        @media (max-width: 767px) {
            max-width: 100%;
        }
        .header-borrower-dropdown-university-text {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #151b26;
            display: flex;

            background: #f1f5f8;
            border-radius: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .header-borrower-dropdown-education-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .header-borrower-dropdown-degree-type-container {
            @media (max-width: 767px) {
                max-width: calc(50% - 8px);
            }
            .header-borrower-dropdown-degree-type {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #f6f9fc;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 24px;
                gap: 24px;
                width: 206px;
                height: 128px;
                background: rgba(var(--cosignerPrimaryColor), 1);
                border-radius: 8px 8px 0px 0px;
                @media (max-width: 767px) {
                    max-width: 100%;
                }
            }
        }
        .header-borrower-dropdown-major-container {
            @media (max-width: 767px) {
                max-width: calc(50% - 8px);
            }
            .header-borrower-dropdown-major {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #f6f9fc;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 24px;
                gap: 24px;
                width: 206px;
                height: 128px;
                background: #a0584a;
                border-radius: 8px 8px 0px 0px;
                background: #a0584a;
                &.paaas {
                    background: rgba(var(--cosignerAccentColor), 1);
                }
            }
            @media (max-width: 767px) {
                max-width: 100%;
            }
        }
    }
    .header-borrower-dropdown-label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #2b323f;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 8px;
        gap: 10px;
        width: 206px;
        height: 32px;
        background: #edf2f6;
        border-radius: 0px 0px 8px 8px;
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
}
