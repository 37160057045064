.wrapper--auth-flow {
    .footer-sparrow-copyrights {
        padding: 0 0 38px 0;
    }
}
.auth-flow-container {
    position: relative;
    padding-bottom: 30px;
    padding-top: 10px;
    min-height: calc(var(--viewport-height) - 154px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 767px) {
        padding-top: 24px;
        padding-bottom: 32px;
    }
    .step-form-overhead {
        padding: 16px 0;
        line-height: 24px;
        display: flex;
        gap: 16px;
        color: var(--primaryColor);
        align-items: center;
        cursor: pointer;
        transition: gap 0.3s ease;
        font-weight: 500;
        margin-bottom: 16px;
        text-decoration: none;

        &:hover {
            gap: 20px;
        }

        .icon-arrow-right {
            display: block;
            transform: rotateY(180deg);
        }
    }

    .step-form-header {
        font-size: 14px;
        line-height: 18px;
        color: var(--neutralColor400);
        margin-bottom: 40px;

        h2.h2 {
            font-size: 32px;
            line-height: 40px;
            color: var(--primaryColor);
            margin-bottom: 8px;

            @media (max-width: 767px) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #878f99;
        }

        .link-btn.small-link,
        .link-btn.big-link {
            font-weight: 400;
            margin-left: 2px;
            display: inline-block;
            color: rgba(var(--borrowerPrimaryColor), 1);
            a {
                transition: none;
            }
        }
    }

    .step-form-body {
        margin-bottom: 40px;

        .step-form-block {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: relative;

            .form-row {
                margin: 0;

                .form-col {
                    padding: 0;
                    margin-bottom: 0;
                }

                .form-group {
                    position: relative;

                    &.error input {
                        border-top: 1px solid var(--errorMainColor) !important;
                        border-left: 1px solid var(--errorMainColor) !important;
                        border-right: 1px solid var(--errorMainColor) !important;
                        border-bottom: 1px solid var(--errorMainColor) !important;
                    }

                    label {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 8px;
                        color: var(--primaryColor);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    input {
                        border: 1px solid var(--neutralColor200);
                        border-radius: 8px;
                        padding: 16px 40px 16px 16px;
                        &::placeholder {
                            color: var(--neutralColor200);
                            font-size: 16px;
                            font-weight: 400;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            border-color: var(--neutralColor600);
                        }

                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus,
                        &:-webkit-autofill:active {
                            box-shadow: 0 0 0 30px white inset !important;
                            -webkit-box-shadow: 0 0 0 30px white inset !important;
                        }
                    }

                    &--navigation {
                        top: 45px;
                        right: 16px;
                    }

                    .error-text {
                        max-width: unset;
                    }
                }
            }
        }
    }

    .step-form-footer {
        .sso-button {
            display: flex;
            width: 100%;
            gap: 8px;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: var(--primaryColor);
            padding: 16px;
            margin-top: 16px;
            border-radius: 12px;
            transition: all 0.3s ease;

            @media (min-width: 768px) {
                &:hover {
                    opacity: 0.8;
                    background-color: var(--neutralColor50);
                }
            }
        }
    }
    .animated-ico {
        width: 40px;
        height: 40px;
    }

    .step-form-back-link {
        position: absolute;
        text-align: center;
        top: 103%;
        width: 100%;
        left: 0;

        .btn.btn-style-text {
            color: rgba(var(--borrowerPrimaryColor), 1);
            transition: color 400ms;

            &:hover {
                color: rgba(var(--borrowerPrimaryColor), 1);
            }
        }
    }

    .forgot-password.text-right {
        margin: 0;
    }

    .btn-primary {
        display: block;
        font-weight: 400;
        line-height: 24px;
        font-size: 16px;
        cursor: pointer;
        padding: 16px 44px;
        border-radius: 32px;
        color: var(--whiteColor);
        background: rgba(var(--borrowerPrimaryColor), 1);
        transition: box-shadow 0.3s ease;
        text-align: center;
        text-decoration: none;

        &.disabled {
            color: var(--neutralColor200);
            background: var(--neutralColor400);
        }
    }

    .account-type-container {
        border: 1px solid var(--neutralColor200);
        padding: 16px;
        border-radius: 8px;
        display: flex;
        gap: 16px;
        align-items: center;
        cursor: pointer;

        &:not(:first-child) {
            margin-top: 16px;
        }

        img {
            flex: 0 0 40px;
        }
        &.active,
        &:hover {
            border: 1px solid rgba(var(--borrowerPrimaryColor), 1);
            outline: 1px solid rgba(var(--borrowerPrimaryColor), 1);
        }
    }

    .account-type--inner {
        display: flex;
        flex-direction: column;

        h5 {
            color: var(--primaryColor);
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        p {
            color: var(--neutralColor400);
            font-size: 16px;
            line-height: 24px;
        }
    }
    .react-international-phone-input-container {
        .react-international-phone-input {
            padding-left: 42px !important;
            height: 54px !important;
        }

        .react-international-phone-country-selector {
            border: none !important;
            flex: 0 0 32px;
            width: 32px;
        }

        .react-international-phone-country-selector-button {
            margin: 0;
            border: none;

            .react-international-phone-country-selector-button__button-content {
                margin-top: -6px;
            }
        }
    }

    .confirm-checkbox {
        align-items: flex-start;
    }

    .privacy-text {
        flex: 1;
        font-size: 14px;
        line-height: 18px;
        color: var(--neutralColor600);

        .link-btn.small-link {
            margin: 2px;
            font-weight: 400;
            color: rgba(var(--borrowerPrimaryColor), 1);
            text-decoration: underline;

            &:hover {
                color: rgba(var(--borrowerPrimaryColor), 1);
            }
        }
    }

    .confirm-btn-home {
        margin-top: 14px;
        padding-bottom: 0;
        color: rgba(var(--borrowerPrimaryColor), 1);
    }

    .code-input-email .input input {
        border-color: var(--neutralColor200);
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .signup-congrat {
        .animated-ico {
            margin-bottom: 40px;
        }

        h3 {
            margin-bottom: 8px;
        }

        p {
            color: var(--neutralColor600);
            font-size: 14px;
            margin-bottom: 40px;
        }
    }
}

.wrapper-anim {
    position: relative;

    .fade-enter {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transform: scale(1.05);
    }

    .fade-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity 200ms ease-in, transform 200ms ease-in;
    }

    .fade-exit {
        opacity: 1;
        transform: scale(1);
    }

    .fade-exit-active {
        opacity: 0;
        transform: scale(0.95);
        transition: opacity 200ms ease-out, transform 200ms ease-out;
    }
}

.auth-flow-container {
    flex: 1;

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //padding-top: 40px;
    }

    .sign-up {
        &.borrower {
            @media (min-width: 767px) {
                //margin-top: -63px;
                max-width: 728px;
            }
        }
    }

    .wrap-block {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .complete-signup {
        @media (min-width: 767px) {
            margin-top: -63px;
        }
    }

    .sign-up_borrower {
        min-height: 338px;
        box-shadow: 0px 80px 120px rgba(0, 0, 0, 0.1);
        background: #ffffff;
        border-radius: 16px;
        padding: 48px;
        width: 100%;
        position: relative;
        transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        @media (max-height: 900px) {
            @media (min-width: 768px) {
                padding: 24px;
                .step-form-header {
                    margin-bottom: 16px;
                }
                .step-form-overhead {
                    margin-bottom: 0;
                }
                .step-form-body {
                    margin-bottom: 24px;
                }
            }
        }
        @media (max-height: 800px) {
            @media (min-width: 768px) {
                .step-form-body {
                    .step-form-block {
                        gap: 16px;
                        .form-row .form-group {
                            .form-group--navigation {
                                top: 37px;
                                right: 8px;
                            }
                            input {
                                padding: 8px 30px 8px 16px;
                            }
                            .react-international-phone-input-container
                                .react-international-phone-input {
                                height: 38px !important;
                            }
                        }
                    }
                }
                .sso-button,
                .btn-primary {
                    height: 40px !important;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        @media (min-width: 767px) {
            //margin-top: -63px;
            max-width: 728px;
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 40px 28px;
        }
    }

    &--inner {
        max-width: 570px;
        min-height: 338px;
        box-shadow: 0px 80px 120px rgba(0, 0, 0, 0.1);
        background: #ffffff;
        border-radius: 16px;
        padding: 48px;
        width: 100%;
        position: relative;
        transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        @media (max-height: 800px) {
            @media (min-width: 768px) {
                padding: 24px;
                .step-form-body,
                .step-form-header {
                    margin-bottom: 24px;
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            padding: 40px 28px;
        }

        &-sso {
            padding: 0;
            .loader-parent {
                min-height: 338px;
                width: 100%;
                overflow: hidden;
                border-radius: 16px;
            }
        }
    }
}
