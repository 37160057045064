.mobile-only {
    display: none;
}

div.rm-steps-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 40px;
    position: relative;
}

.rm-popup-content-container {
    border-radius: 16px;
    overflow: hidden;
    background: rgb(255, 255, 255);
    box-shadow: rgba(21, 26, 37, 0.1) 0px 24px 40px;
    .Pre-qualify {
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    .Compare.And.Select {
        box-shadow: none !important;
    }
}

.pqStepCards {
    display: flex;
    padding: 24px;
    gap: 8px;

    &.dir-inclusion{
        padding: 0px;
        padding-top: 22px;
    }
}

.pqStepCards-footer {
    display: none;
}

.popupContentTopSection-wrapper {
    margin: 24px;
}

.compareAndSelectPopup-wrapper {
    display: flex; 
    gap: 8px;
    justify-content: space-between; 
    margin: 24px;
}


.rm-steps-container__small-device {
    margin-left: -40px;
    margin-right: -40px;
    margin-top: 40px;
    position: relative;
    @media (max-width: 767px) {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: 14px;
    }
    .rm-steps-container__small-device-step-content {
        position: relative;
        max-width: calc(100% - 80px);
        margin-left: 40px;
        .arrow {
            display: none;
            @media (max-width: 990px) {
                display: block;
                position: absolute;
                top: -17px;
                left: 50px;
            }
        }
    }
    .rm-steps-container__small-device-items {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .rm-step-container {
        min-width: 233px;
    }
    .ScrollbarsCustom-Track {
        display: none;
    }
    .ScrollbarsCustom-Scroller {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }
    .rm-popup-content-container {
        top: 0 !important;
    }
    .compareAndSelectPopup-wrapper {
        flex-wrap: wrap;
        gap: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: -24px;

        @media (max-width: 767px) {
            flex-wrap: nowrap;
            flex-direction: column;
            overflow-y: auto;
        }
        .compareAndSelectPopup-item {
            flex: 0 0 50%;
            max-width: 50%;
            width: 50%;
            margin-bottom: 24px;

            @media (max-width: 767px) {
                max-width: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-bottom: 16px;
            }

            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(3) {
                order: 5;
            }
            &:nth-child(4) {
                order: 2;
            }
            &:nth-child(5) {
                order: 4;
            }
            &:nth-child(6) {
                order: 6;
            }
        }
    }

    .rm-steps-container-step-1 {
        @media (max-width: 990px) {
            .arrow {
                left: 50%;
                margin-left: -5px;
            }
            .rm-popup-content-container {
                max-width: 585px;
                left: 50% !important;
                transform: translateX(-50%);
            }
        }
    }
    .rm-steps-container-step-2 {
        @media (max-width: 990px) {
            display: flex;
            justify-content: center;
            .arrow {
                left: 50%;
                margin-left: -5px;
            }
        }
        .rm-popup-content-container {
            @media (max-width: 990px) {
                max-width: 585px;
                left: 50% !important;
                transform: translateX(-50%);
            }
        }
    }
    .rm-steps-container-step-3,
    .rm-steps-container-step-4 {
        .rm-popup-content-container {
            @media (min-width: 768px) and (max-width: 990px) {
                max-width: 585px;
                left: unset !important;
                right: 0;
            }
        }
    }
    .rm-popup-content-container {
        border-radius: 16px;
        overflow: hidden;
        background-color: var(--whiteColor);
        box-shadow: rgba(21, 26, 37, 0.1) 0px 24px 40px !important;

        @media (max-width: 767px) {
            box-shadow: none !important;
            width: 100% !important;
        }
        .Pre-qualify, .CompareAndSelect {
            background-color: transparent !important;
            box-shadow: none !important;

            .popupContentTopSection-wrapper {
            
                @media (max-width: 990px) {
                    margin-bottom: 16px;
                }

                @media (max-width: 767px) {
                    align-items: flex-start !important;
                    padding-top: 8px;
                    p {
                        margin-top: 32px;
                    }
                    
                    @media (max-width: 560px){
                        margin-top: 0px;
                    }
                }
            }
        }
        &.rm-popup-content-container__disable-edit {
            .Pre-qualify {
                @media (max-width: 767px) {
                    .popupContentTopSection-wrapper {
                        padding-top: 0;
                        p {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .wrap-content {
            @media (max-width: 560px) {
                padding-top: 16px !important;
                padding-bottom: 34px !important;
            }
        }
        .popupContentTopSection-wrapper {
            @media (max-width: 990px) {
                flex-wrap: wrap;
                .btn-group {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-top: 16px;
                    text-align: left;
                    display: flex;
                    justify-content: flex-start;
                }
            }

            @media (max-width: 767px) {
                padding-bottom: 24px;
                padding-top: 8px;
                border-bottom: 1px solid var(--neutralColor100);
            }
        }
        &-Pre-qualify, &-CompareAndSelect {
            .pqStepCards {
                
                &.main-dir-inclusion-mobile {
                    @media (max-width: 560px){
                        padding-bottom: 0px;
                    }
                }
                
                &.dir-inclusion {
                    padding: 0px;
                    margin-top: 22px;
                }
            }


            @media (max-width: 767px) {
                max-width: 100% !important;
            }
            @media (max-width: 990px) {
                max-width: 470px;
                .Pre-qualify {
                    .popupContentTopSection-wrapper {
                        p {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 24px;
                            span {
                                display: block;
                            }
                        }
                    }
                    .pqStepCards {
                        padding-top: 16px;
                        flex-direction: column;
                        gap: 0 !important;

                        &-footer {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .oval-button {
                                position: relative;
                            }
                        }
                        & + .pqStepCards {
                            margin-top: 4px;
                        }
                        .pq-step-card-container {
                            position: relative;
                            padding-left: 30px;
                            padding-bottom: 24px;
                            &::before {
                                content: "";
                                width: 1px;
                                height: 16px;
                                background: var(--neutralColor100);
                                position: absolute;
                                bottom: 4px;
                                left: 10.5px;
                            }
                            &:last-child {
                                &::before {
                                    display: none;
                                }
                            }
                            &.complete {
                                &::before {
                                    background-color: rgba(var(--borrowerPrimaryColor), 1);
                                }
                            }
                            &.cosigner {
                                &.complete {
                                    &::before {
                                        background-color: rgba(var(--cosignerPrimaryColor), 1);
                                    }
                                }
                            }
                            .step-title {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                margin-bottom: 4px;
                            }
                            .step-data {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                            }
                            .status-bar {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 22px;
                                height: 24px;
                                position: absolute;
                                left: 0;
                                top: 8px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                border: 2px solid #ccd6dd;

                                .mobile-only {
                                    position: absolute;
                                    top: 2px;
                                    display: block;
                                }

                                &.complete {
                                    border: none;
                                }
                            }
                        }
                    }
                }
                .Compare.And.Select {
                    .popupContentTopSection-wrapper {
                        p {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 24px;
                            span {
                                display: block;
                            }
                        }
                    }
                    .pqStepCards {
                        padding-top: 16px;
                        flex-direction: column;
                        gap: 0 !important;

                        &-footer {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            .oval-button {
                                position: relative;
                            }
                        }
                        & + .pqStepCards {
                            margin-top: 4px;
                        }
                        .pq-step-card-container {
                            position: relative;
                            padding-left: 30px;
                            padding-bottom: 24px;
                            &::before {
                                content: "";
                                width: 1px;
                                height: 16px;
                                background: var(--neutralColor100);
                                position: absolute;
                                bottom: 4px;
                                left: 10.5px;
                            }
                            &:last-child {
                                &::before {
                                    display: none;
                                }
                            }
                            &.complete {
                                &::before {
                                    background-color: rgba(var(--borrowerPrimaryColor), 1);
                                }
                            }
                            &.cosigner {
                                &.complete {
                                    &::before {
                                        background-color: rgba(var(--cosignerPrimaryColor), 1);
                                    }
                                }
                            }
                            .step-title {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                margin-bottom: 4px;
                            }
                            .step-data {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 20px;
                            }
                            .status-bar {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 22px;
                                height: 24px;
                                position: absolute;
                                left: 0;
                                top: 8px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                border: 2px solid #ccd6dd;
    
                            }
                        }
                    }
                }
            }
        }
    }
}

