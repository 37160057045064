@import "../../../../assets/styles/colors";
@import "../../../../assets/styles/screen-size-variables.scss";

$anchorHeight: 24px;

.left-navigation-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;

    &.decisionPage {
        justify-content: flex-start;
    
        @media (min-width: 1024px) {
            position: fixed;
        }
    }

    &.paaasLandingPage{
        @media (min-width: 1024px) {
            display: none;
        }
    }

    &.has-header-bar {
        margin-top: 0px;
    }

    height: 100%;

    .left-navigation-menu-top {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        ul {
            padding: 0;
            border-bottom: 1px solid $lightColor;

            @media (max-width: $tablet-width) {
                padding-bottom: 0;
            }
            &:last-of-type {
                border-bottom: none;
                padding-top: 25px;
                @media (max-width: $tablet-width) {
                    padding-top: 0;
                }
            }

            li {
                margin: 34px 0px;
                position: relative;
                @media (max-width: $tablet-width) {
                    margin: 32px 0px;
                }
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }

                button,
                div,
                a {
                    height: $anchorHeight;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;

                    color: $mainColor;
                    display: flex;
                    align-items: center;
                    transition: none;
                    background: transparent;
                    padding: 0;
                    border: none;
                    transition: all 400ms;
                    gap: 12px;

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;
                        width: 20px;
                        transition: all 0.4s ease;
                    }
                    &:hover {
                        color: rgba(var(--borrowerPrimaryColor), 1);
                    }
                }

                &.active {
                    &:before {
                        background-color: rgba(var(--borrowerPrimaryColor), 1);
                        content: "";
                        height: 40px;
                        left: -40px;
                        position: absolute;
                        top: -(calc($anchorHeight / 2));
                        transition: height 0.4s ease-in;
                        width: 3px;
                    }
                    a {
                        span {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .left-navigation-menu-expanded {
            display: none;
            // @media (max-width: $tablet-width) {
            //     display: block;
            // }

            // if expanded, we want to display this only if less than tablet width
            // if not expanded, we do not want to show
            &.replaced {
                &.expanded {
                    @media (max-width: $tablet-width) {
                        display: block;
                    }
                }
            }

            // if expanded, we want to display this only if greater than tablet width
            &.not-replaced {
                display: block;
                &.expanded {
                    @media (max-width: $tablet-width) {
                        display: none;
                    }
                }
            }
        }
    }
    @media (min-width: $tablet-width) {
        .oval-button {
            span {
                display: none;
            }
        }
    }
    @media (max-width: $tablet-width) {
        margin-top: 0;
        padding-top: 48px;
        padding-left: 24px;
        max-height: calc(var(--viewport-height) - 120px);
        overflow-y: auto;

        .oval-button {
            span {
                font-size: 11px;
                margin-left: 10px;
            }
        }
    }

    .left-navigation-menu-top-container {
        display: flex;
        flex-direction: column;
    }

    .left-navigation-menu-bottom-container {
        display: flex;
        flex-direction: column;

        &.expanded {
            @media (max-width: $tablet-width) {
                display: none;
            }
        }
    }
}
