@import "../../../../assets/styles/colors";

.btn-primary{
  display: inline-block;
  font-weight: 600;
  line-height: 18px;
  font-size: 14px;
  cursor: pointer;
  padding: 16px 44px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  color: #fff;
  background-image: linear-gradient(254.6deg,rgb(var(--borrowerPrimaryColor)) 100%);
  //min-width: 210px;
  transition: all .3s ease;
  text-align: center;
  @media (max-width: 767px) {
    padding: 16px 16px;
  }

  &.borrower {
    background-image: none;
    background: $darkGreenColor;
    border-radius: 32px;
  }

  &.cosigner {
    background-image: none;
    background: $cosignerPrimaryColor;
    border-radius: 32px;
  }
  
  &:hover,
  &:focus {
    box-shadow: 0 10px 28px rgba(var(--borrowerPrimaryColor), 0.5);
  }

  &:active{
    background-color: rgba(var(--borrowerPrimaryColor), 1);
    box-shadow: none;
  }

  &.disabled{
    opacity: .5;
    box-shadow: none;
    pointer-events: none;
  }

  &.with-loading {
    & > span {
      display: block;
      height: 24px;
    }
  }
}

.btn-strong-size {
  width: 210px;
}

.btn-secondary{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 15px;
  border-radius: 12px;
  color: $mainColor;
  border: 1px solid rgba(30, 37, 52, 0.2);
  width: 210px;
  transition: all 0.4s ease;
  display: inline-block;
  text-align: center;
  cursor: pointer;

  &.borrower {
    color: $darkGreenColor;
    border: 2px solid $darkGreenColor;
    border-radius: 32px;
  }

  &.cosigner {
    color: $cosignerPrimaryColor;
    border: 2px solid $cosignerPrimaryColor;
    border-radius: 32px;
  }



  &:focus,
  &:hover{
    background: rgba(196, 196, 196, 0.2);
  }
  &:active{
    border: 1px solid rgba(var(--borrowerPrimaryColor), 1);
    background-color: transparent;
  }
  &.hidden {
    display: none;
  }
  &[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-fade-enter {
  opacity: 0;
}

.button-fade-exit {
  opacity: 1;
  transform: scale(1.3);
}

.button-fade-enter-active {
  opacity: 1;
}

.button-fade-exit-active {
  opacity: 0;
  transform: scale(1);
}

.button-fade-enter-active,
.button-fade-exit-active {
  transition: all 600ms;
}