.nonTipsContent {
    margin-top: 72px;
}

.leftSubText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    color: #fff;
}

.leftTitleText {
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    color: #fff;
}
