.section {
position: relative;
margin-bottom: 144px;
}

.homeContainer {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    padding: 0px 40px;
    
    @media(max-width: 768px){
        padding: 0px 20px;
    }

    @media(max-width: 375px){
        padding: 0px 16px;
    }
}

.topSection {
    display: flex;
    position: relative;
    z-index: 2;

    @media(min-width: 1024px){
        min-height: 600px; //Hero background height
    }

    @media(max-width: 1024px){
        // top of 67px + 62px of gap
        margin-bottom: calc(67px + 62px);
    }
}

.leftContent {
    position: relative;
    z-index: 3;
    width: 50vw;
    max-width: 560px;
    top: 79px;

    @media(max-width: 1024px){
        width: 100%;
    }

    @media(max-width: 768px){
        top: 67px;
    }
}

.benefitsContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    padding-left: 15px;

    span {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;

        font-weight: 400;
        line-height: 24px; /* 150% */
    }
}

.flex {
    display: flex;
    
    @media(max-width: 1024px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }

    @media (max-width: 560px){
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

.flexCol {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.illustrativeText {
    display: flex;
    flex-direction: column;
    color: var(--neutral-400, #9ea9b0);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    margin-bottom: 56px;
    align-items: flex-end;
    margin-right: 5px;

    @media(max-width: 1024px){
        position: absolute;
        right: 40px;
        // corner hero height + 85px gap;
        top: calc(440px + 85px);
    }

    @media(max-width: 868px){
        display: none;
    }
}

.heroCornerContainer{
    width: 100%;
    position: relative;
    @media(max-width: 868px){
        display: none;
    }
}

.heroCornerBackground{
  width: 50vw;
  max-width: 720px;
  height: 600px;
  position: absolute;
  top: -33px;
  right: 10px;
  border-radius: 0 0 0 40px;
  overflow: hidden;
  transform: rotate(-2deg);
  background-color: rgba(var(--borrowerPrimaryColor), 1);

    @media(max-width: 1024px){
        width: 384px;
        height: 440px;
        top: 0px;
        right: 0px;
        transform: none;
    }


}

.heroCornerStraight{
    width: 20px;
    height: 580px;  
    position: absolute;
    top: -33px;
    right: 0px;
    background-color: rgba(var(--borrowerPrimaryColor), 1);

    @media(max-width: 1024px){
        display: none;
    }

}

.tooltip:hover{
    cursor: pointer;
}