.back-dashboard {
    font-size: 22px;
    display: inline-flex;
    align-items: center;
    color: #1e2534;
    height: 18px;
    @media (max-width: 990px) {
        padding-top: 0;
        height: auto;
    }
    @media (max-width: 575px) {
        font-size: 20px;
        padding-bottom: 0px;
    }
    @media (max-width: 765px) {
        padding-left: 16px;
        padding-bottom: 0px;
    }
    .text {
        &.show {
            display: none;
            @media (max-width: 767px) {
                display: block;
            }
        }
    }
    .icon-arrow-back {
        & + .text {
            margin-left: 15px;
        }
    }
    &:hover {
        color: rgba(var(--borrowerPrimaryColor), 1);
    }
}
