@import "colors";

@mixin strokeColor($color) {
    path {
        stroke: $color;
    }
}

@mixin hoverRed {
    &:hover {
        cursor: pointer;
        path {
            transition: all 0.3s ease-in-out;
            stroke: red;
        }
    }
}

@mixin hoverUserType {
    &:hover {
        cursor: pointer;
        &.borrower {
            path {
                transition: all 0.3s ease-in-out;
                stroke: $darkGreenColor;
            }
        }
        &.cosigner {
            path {
                transition: all 0.3s ease-in-out;
                stroke: $cosignerPrimaryColor;
            }
        }
    }
}

div.trash-svg-icon {

    &.ti-svg-roadmap {
        padding: 8px 16px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include strokeColor(white);
        @include hoverRed;
    }

    &.ti-svg-roadmap-popup {
        @include strokeColor($neutral500);
        @include hoverRed;
        svg {
            width: 19.85px;
            height: 19.85px;
        }
    }

    &.ti-svg-loan-request-card {
        @include strokeColor(white);
        display: flex;

        svg {
            width: 16px;
            height: 14px;
        }
    }
}

div.edit-icon-svg {

    &.general {
        @include hoverUserType;
    }
}

div.expanded-search-svg-icon {
    &.borrower {
        svg {
            circle {
                fill: $darkGreenColor;
            }
        }
    }
    &.cosigner{
        svg {
            circle {
                fill: $cosignerPrimaryColor;
            }
        }
    }
}

svg:hover.delete {
    cursor: pointer;

    path {
        transition: all 0.3s ease-in-out;
        stroke: red;
    }
}

svg:hover.edit.borrower {
    cursor: pointer;

    path {
        transition: all 0.3s ease-in-out;
        stroke: #6db353;
    }
}

svg:hover.edit.cosigner {
    cursor: pointer;

    path {
        transition: all 0.3s ease-in-out;
        stroke: #dc7962;
    }
}

path.trash-svg-grey {
    stroke: $neutral500;
}

path.trash-svg-white {
    stroke: white;
}

div:hover.trash-svg {
    cursor: pointer;
    path {
        stroke: red;
        transition: all 0.3s ease-in-out;
    }
}

div:hover.edit-icon-svg {
    cursor: pointer;

    &.borrower {
        path {
            stroke: $darkGreenColor;
        }
    }

    &.cosigner {
        path {
            stroke: $cosignerPrimaryColor;
        }
    }
}


.question-mark-icon-svg {
    display: flex;
    align-items: center;

    &.active {
        svg {
            fill: white;

            path {
                fill: black;
            }
        }
    }
    
}