.ucc-first-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ucc-second-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    @media (max-width: 560px) {
        flex-direction: column;
    }

    .ucc-incl-user-box {
        display: flex;
        gap: 16px;
        width: 100%;
    }

}

.ucc-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #151b26;
    border-radius: 12px;
}

p.ucc-contact-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-style: normal;
    color: #151b26;
    overflow-wrap: anywhere;
}

@mixin secondary-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #abb6bd;
}
.ucc-contact-usertype {
    @include secondary-text;
    font-weight: 400;
}

p.ucc-contact-email {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #abb6bd;
    text-decoration: underline;
    overflow-wrap: anywhere;
}

.ucc-loan-information {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .ucc-row {
        display: flex;
        justify-content: space-between;
        padding-top: 17px;
        border-top: 1px solid rgba(21, 27, 37, 0.1);
    }
}

.status-label {
    align-items: center;
    border-radius: 12px;
    display: flex;
    gap: 4px;
    justify-content: center;
    min-width: fit-content;
    padding: 4px 8px;
    @media (max-width: 500px) {
        max-width: calc(100% - 10px);
        flex: 0 0 calc(100% - 10px);
        min-width: unset;
        justify-content: flex-start;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .status-circle {
        width: 8px;
        height: 8px;
        border-radius: 50px;
    }
    
    .status-text {
        margin: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        white-space: nowrap;
        @media (max-width: 500px) {
            max-width: calc(100% - 10px);
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
